import Assignments from './Assignments';
import Category from './Category';
import Clients from './Clients';
import Country from './Country';
import Document from './Document'
import ExternalLinks from './ExternalLinks';
import Filter from './Filter';
import Intelligence from './Intelligence';
import Keywords from './Keywords';
import Language from './Language';
import Loader from './Loader';
import Log from './Log';
import Media from './Media';
import Nationality from './Nationality';
import Navigation from './Navigation';
import Photo from './Photo';
import Project from './Project';
import Report from './Report';
import RobotLinks from './RobotLinks';
import Scan from './Scan';
import Search from './Search';
import Sites from './Sites';
import Sources from './Sources';
import Translate from './Translate';
import Users from './Users';
import Upload from './Upload';
import Video from './Video';
import Wiki from './Wiki';
import Location from './Location';

export default {
    Assignments,
    Category,
    Clients,
    Country,
    Document,
    ExternalLinks,
    Filter,
    Intelligence,
    Keywords,
    Language,
    Loader,
    Log,
    Media,
    Nationality,
    Navigation,
    Photo,
    Project,
    Report,
    RobotLinks,
    Scan,
    Search,
    Sites,
    Sources,
    Translate,
    Users,
    Upload,
    Video,
    Wiki,
    Location
}

export {
    Assignments,
    Category,
    Clients,
    Country,
    Document,
    ExternalLinks,
    Filter,
    Intelligence,
    Keywords,
    Language,
    Loader,
    Log,
    Media,
    Nationality,
    Navigation,
    Photo,
    Project,
    Report,
    RobotLinks,
    Scan,
    Search,
    Sites,
    Sources,
    Translate,
    Users,
    Upload,
    Video,
    Wiki,
    Location
}