import React from 'react';
import { mapStateToProps, connect } from 'containers/CountryContainer';
import { Country } from 'helpers/';
import Loader from 'components/Loader';
import { Nav } from 'office-ui-fabric-react/lib/Nav';

class CountryTree extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            countryList: [],
            loaded: false,
            selected: {}
        }

        Country.Load(this.props.dispatch);
        Country.Get(props.dispatch);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.countryList &&
            props.countryList.length &&
            state.countryList.length === 0
        ) {
            let countryList = props.countryList.map((c) => ({...c, display: true}));
            return {
                countryList: props.countryList ? countryList : [],
                loaded: true
            }
        } 

        return null;
    }
    
    render() {
        return this.state.loaded ? (<>
            {(
                <Nav
                  styles={{ root: { width: 300 } }}
                  groups={[
                    {
                        links: [
                            {
                                name: 'Country',
                                links: this.state.countryList.map((country) => ({
                                    name: country.name
                                }))
                            }
                        ]
                    }
                  ]}
                />
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(CountryTree);