import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    let result = {}
    
    if (typeof(state.navigation) !== 'undefined' && state.navigation.pageRefresh === true) {
        try {
            result.Search.clearQuery = true;
        } catch (e) {
            result.Search = {
                clearQuery: true
            }
        }
    }
    
    if (typeof(state.navigation) !== 'undefined') {
        result.navigation = state.navigation;
    }
    
    if (typeof(state.search) !== 'undefined') {
        result.Search = state.search.Search;
    }
    
    return result;
};

export { mapStateToProps, connect }