import React from 'react';
import Keywords from 'components/Keywords';
import Loader from 'components/Loader';
import API from 'actions/api';

import { mapStateToProps, connect } from 'containers/FilterFormContainer';

class TagsFilterForm extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            tags: [],
            loaded: false,
            selectedKey: false,
            Filter: '',
            selected: {}
        }
        
        this.getTags = this.getTags.bind(this);
        this.getTagList = this.getTagList.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }

    static getDerivedStateFromProps = (props, state) => {
        if (
            props.Filter !== state.Filter ||
            props.getFilterData
        ) return {
            Filter: props.Filter ? props.Filter : state.Filter,
            selected: props.getFilterData ? props.getFilterData('tags') : state.selected
        }
        
        return null;
    }

    getTags() {
        API.returnGet(`ResearchKeywords/tags/?output=json`, true).then((result) => {
            this.setState({
                tags: result.data.Data,
                loaded: true
            })
        });
    }
    
    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (!isChecked) delete selected[id];
        else selected[id] = title;
        this.setState({
            selected: selected
        })
        return this.props.onFilter ? this.props.onFilter('tags', selected) : null;
    }
    
    componentDidMount() {
        this.getTags();
    }

    getTagList() {
        if (this.state.Filter.length === 0) return this.state.tags;
        const r = this.state.tags.filter((t) => {
            const res = t.title.toLowerCase().indexOf(this.state.Filter.toLowerCase()) > -1;
            return res;
        });
        return r;
    }

    render() {
        if (this.state.loaded === false) return <Loader.Inline />
        else {
            return (
                <div style={{
                   display: 'flex',
                   flexDirection: 'row',
                   width: '100%',
                   height: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        overflowY: 'auto',
                        flexDirection: 'column'
                    }} className={'List-FullWidth'} >
                    {this.getTagList().map((tag) => (<Keywords.InlineTag key={tag.id} onCheckboxClick={this.onCheckboxClick} id={tag.id} data={tag} checkboxChecked={this.state.selected[tag.id] ? true : false} checkbox hideIcon />))}
                    </div>
                </div>
            )
        }
    }
    
}

export default connect(mapStateToProps)(TagsFilterForm);