import List from './CategoryList';
import Single from './CategorySingle';
import Inline from './CategoryInline';
import FilterForm from './CategoryFilterForm';
import Tree from './CategoryTree';

export default { 
    List, 
    Single,
    Inline,
    FilterForm,
    Tree
}