import { connect } from 'react-redux';

    
const mapStateToProps = (state, ownProps) => {
    if ( 
        state.r_links &&
        state.r_links.links
    ) return {
        link: state.r_links.links[ownProps.id]
    }
    
    return {}
};

export { mapStateToProps, connect }