
let Search = {

    doSearch: (text) => ({
        type: 'MAINSEARCH_DO',
        payload: {
            Search: {
                text: text
            }
        }
    }),
    
    clear: () => ({
        type: 'MAINSEARCH_CLEAR'
    })
}

export default Search;