import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }
    
    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error.toString(),
            errorInfo: errorInfo
        });
    }
    
    render() {
        if (this.state.hasError) {
            return (
                <MessageBar
                    messageBarType={MessageBarType.blocked}
                    truncated={true}
                    overflowButtonAriaLabel="See more"
                ></MessageBar>
            )
        } else {
            return this.props.children; 
        }
    }
}