import React from 'react'
import Loader from 'components/Loader';
import API from 'actions/api';
import AssignmentSources from './SingleAssignmentSources';
import { TextField, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import CKEditor from 'helpers/CKEditor';
import { withRouter } from 'react-router-dom';
import Navigation from 'actions/navigation';
import { connect } from 'react-redux';

class AssignmentEdit extends React.Component {

    constructor(props) {
        super(props);
        
        this.HTML = '';
        
        this.state = {
            loaded: false,
            data: {},
            title: '',
            sources: [],
            finished: false
        }
        
        this.loadAssignments = this.loadAssignment.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.saveSource = this.saveSource.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.saveAndSend = this.saveAndSend.bind(this);
        this.sendAssignment = this.sendAssignment.bind(this);
        this.updateAssignment = this.updateAssignment.bind(this);
    }

    loadAssignment() {
        API.returnGet(`Assignments/edit/${this.props.id}?output=json`, true).then((result) => {
            this.HTML = result.data.Data.info;
            this.setState({
                loaded: true,
                data: result.data.Data,
                title: result.data.Data.name,
                sources: result.data.Data.sources,
                assignment: result.data.Data
            });
            CKEditor.setSources(this.props.id, result.data.Data.sources);
            
            CKEditor.init(this.HTML, (html) => {
                this.HTML = html;
            });
        });
    }
    
    componentDidMount() {
        this.loadAssignment();
    }
    
    updateTitle(ev, value) {
        this.setState({
            title: value
        })
    }
    
    updateAssignment(key, value) {
        let assignment = this.state.assignment;
        if (key.indexOf('.') === -1) assignment[key] = value;
        else {
            key = key.split('.');
            if (!assignment[key[0]]) assignment[key[0]] = {};
            assignment[key[0]][key[1]] = value;
        }
        this.setState({
            assignment: assignment
        })
    }
    
    saveSource(source) {
        let newSources = [];
        let sourceFound = false;
        this.state.sources.forEach((o_source) => {
            if (o_source.id === source.id) {
                sourceFound = true;
                newSources.push(source);
            } else newSources.push(o_source);
        });
        if (!sourceFound) newSources.push(source);
        CKEditor.setSources(this.props.id, newSources);
        
        this.setState({
            sources: newSources
        })
    }

    saveAndClose() {
        this.props.dispatch(Navigation.showConfirmation({
            title: 'Save & Close',
            text: 'Save assignment and return to list?',
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                this.setState({
                    finished: false
                }, () => {
                    this.sendAssignment()
                })
            },
            functionCancel: () => { 
            }
        }));
    }
    
    saveAndSend() {
        this.props.dispatch(Navigation.showConfirmation({ 
            title: 'Save & Send',
            text: 'Save assignment and send it to next phase?',
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                
                this.setState({
                    finished: true
                }, () => {
                    this.sendAssignment()
                })
            },
            functionCancel: () => { 
            }
        }));
    }
    
    sendAssignment() { 
        let send = {
            assignment: { 
                ...this.state.assignment,
                info: this.HTML,
                name: this.state.title
            },
            title: this.state.title,
            finished: this.state.finished,
            sources: this.state.sources
        };
        
        API.returnPost('Assignments/SaveFromJson', send).then((data) => {
            this.props.history.push(`/Assignments`);
        })
    }
    
    render() {
        return this.state.loaded ? (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%',
                    padding: '10px',
                        boxSizing: 'border-box'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 0,
                        paddingBottom: '10px',
                        boxSizing: 'border-box'
                    }}>
                        <TextField defaultValue={this.state.title} styles={{root:{width: '100%'}}} onChange={this.updateTitle} />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        overflow: 'hidden'
                    }}>
                        <div id={'CKEditor'} style={{
                            width: '100%',
                            height: '100%'
                        }}></div>
                    </div>
                    <div style={{
                        display: 'flex',
                        height: 35,
                        flexShrink: 0,
                        flexGrow: 0,
                        flexBasis: 35,
                        flexDirection: 'row'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexBasis: '50%',
                            flexGrow: 1
                        }}>
                            <DefaultButton text="Save & Close" onClick={this.saveAndClose} />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexBasis: '50%',
                            flexGrow: 1,
                            flexFlow: 'row-reverse'
                        }}>
                            <PrimaryButton text="Save & Send" onClick={this.saveAndSend} />
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '50%'
                }}>
                    <AssignmentSources id={this.state.data.id} assignment={this.state.data} sources={this.state.sources} onSaveSource={this.saveSource} updateAssignment={this.updateAssignment} />
                </div>
            </div>
        ) : <Loader.Big />
    }
    
}

export default connect()(withRouter(AssignmentEdit));