import React from 'react';
import { registerIcons } from '@uifabric/styling';

const generateIcons = (style) => ({
    icons: {
        'icon-company': <i className={`${style} fa-building fixedIcon`} />,
        'icon-language': <i className={`${style} fa-language fixedIcon`} />,
        'icon-link': <i className={`${style} fa-link fixedIcon`} />,
        'icon-tags': <i className={`${style} fa-tags fixedIcon`} />,
        'icon-keywords': <i className={`${style} fa-tag fixedIcon`} />,
        'icon-person': <i className={`${style} fa-male fixedIcon`} />,
        'icon-country': <i className={`${style} fa-globe-europe fixedIcon`} />,
        'icon-city': <i className={`${style} fa-map-marker fixedIcon`} />,
        'icon-media': <i className={`${style} fa-newspaper fixedIcon`} />,
        'icon-organization': <i className={`${style} fa-sitemap fixedIcon`} />,
        'icon-generic': <i className={`${style} fa-tag fixedIcon`} />,
        'icon-other': <i className={`${style} fa-tag fixedIcon`} />,
        'icon-politicalparty': <i className={`${style} fa-users fixedIcon`} />,
        'icon-institution': <i className={`${style} fa-university fixedIcon`} />,
        'icon-event': <i className={`${style} fa-calendar-alt fixedIcon`} />,
        'icon-project': <i className={`${style} fa-construction fixedIcon`} />,
        'icon-calendar': <i className={`${style} fa-calendar fixedIcon`} />,
        'icon-': <i className={`${style} fa-circle fixedIcon`} />
    }
})

export default {
    
    Register: (style=`fal`) => {
        registerIcons(generateIcons(style))
    },
    
    Exists: (icon) => {
        const data = generateIcons();
        return typeof( data.icons[`icon-${icon.toLowerCase()}`] ) !== 'undefined'
    }
}