import React from 'react';

import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { Callout, DirectionalHint } from 'office-ui-fabric-react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { SingleSmall as UploadSingleSmall, Paste as UploadPaste } from 'components/Upload';
import { connect, mapStateToProps } from 'containers/UploadContainer';
import { Upload } from 'components';
import Navigation from 'actions/navigation';

class UploadNavigationButton extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            uploads: props.uploads ? props.uploads : {},
            progress: 0,
            _button: React.createRef(),
            callout: props.callout ? props.callout : false
        }
        
        this.buttonClick = this.buttonClick.bind(this);
        this.hideCallout = this.hideCallout.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        
        this.onPasteText = this.onPasteText.bind(this);
        this.onPasteLink = this.onPasteLink.bind(this);
        this.onPasteFiles = this.onPasteFiles.bind(this);
        
        this.showBigUploads = this.showBigUploads.bind(this);
        
        this.Color = this.Color.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => { 

        if ( props.uploads || props.callout || props.totalProgress ) return {
            uploads: typeof(props.uploads) !== 'undefined' ? props.uploads : state.uploads,
            callout: typeof(props.callout) !== 'undefined' ? props.callout : state.callout,
            progress: typeof(props.totalProgress) !== 'undefined' ? props.totalProgress : state.progress
        }
        
    }

    updateProgress(id, progress) {
        let totalProgress = 0;
        let uploads = this.state.uploads;
        const keys = Object.keys(uploads);
        const total = keys.length * 100;
        uploads[id].progress = progress;
        keys.forEach((key) => {
            totalProgress += uploads[key].progress 
        });
        this.setState({
            progress: (totalProgress / total) * 100,
            uploads: uploads
        })
    }
    
    hideCallout() {
        this.props.dispatch({
            type: 'UPLOAD_CALLOUT',
            payload: {
                show: false
            }
        })
    }
    
    buttonClick() {
        this.props.dispatch({
            type: 'UPLOAD_CALLOUT',
            payload: {
                show: !this.state.callout
            }
        })
    }
    
    onPasteText(text) {
        
    }
    
    onPasteLink(link) {
        
        this.props.dispatch({
            type: 'UPLOAD_PASTE_LINK',
            payload: {
                link: link
            }
        })
    }
    
    onPasteFiles(files) {
        
        this.props.dispatch({
            type: 'UPLOAD_PASTE_FILES',
            payload: {
                files: files
            }
        })
    }
    
    Color() {
        if ( Object.keys(this.state.uploads).length === 0 ) return 'Transparent';
        else if ( this.state.progress < 100 ) return 'Tomato';
        else return 'Green';
    }
    
    showBigUploads() {
        this.hideCallout();
        this.props.dispatch(Navigation.showPagePopup({
            title: 'Uploads',
            children: <Upload.BigWindow />,
            showSearch: false,
            titleOK: 'OK',
            titleCancel: false
        }));
    }
    
    render() {
        return (
            <>
                <CommandBarButton onClick={this.buttonClick}>
                    <div ref={this.state._button}>
                        <span className={'fa-layers fa-fw'}>
                            <i className={'fas fa-file-upload'}></i>
                            <span className={'fa-layers-counter' + (this.Color() === 'Tomato' ? ' blink' : '')} style={{
                                background: this.Color(),
                                fontSize: '1.5rem',
                                marginTop: '-3px'
                            }}>{' '}</span>
                        </span>
                        <ProgressIndicator
                            percentComplete={this.state.progress/100} 
                            progressHidden={false}
                            styles={{
                                itemProgress: {
                                    padding: 0
                                },
                                progressTrack: {
                                    backgroundColor: 'transparent'
                                },
                                progressBar: {
                                    backgroundColor: 'white'
                                }
                            }}
                        />
                    </div>
                </CommandBarButton>
                <Callout
                    hidden={!this.state.callout}
                    target={this.state._button}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                    coverTarget={false}
                    onDismiss={this.hideCallout}
                    style={{
                        padding: 20,
                        width: 350
                    }}
                    preventDismissOnScroll={true}
                    preventDismissOnResize={true}
                >
                    <UploadPaste 
                        onPasteText={this.onPasteText}
                        onPasteLink={this.onPasteLink}
                        onPasteFiles={this.onPasteFiles}
                    />
                    <Separator></Separator>
                
                    {Object.keys(this.state.uploads).map((upload_id) => (
                        
                        <div key={`${upload_id}-${this.state.uploads[upload_id].progres}`}>
                            <UploadSingleSmall upload={this.state.uploads[upload_id]} />
                            <Separator />
                        </div>
                    ))}
                    
                    <Separator>
                        <Link onClick={this.showBigUploads}>{'Show all uploads'}</Link>
                    </Separator>
                </Callout>
            </>
        );
    }
    
}

export default connect(mapStateToProps)(UploadNavigationButton);