import React from 'react';
import { mapStateToProps, connect } from '../../containers/DefaultContainer';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack} from 'office-ui-fabric-react/lib/Stack';
import API from 'actions/api';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

class Login extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.columnProps = {
            tokens: {
                childrenGap: 50
            },
            styles: {
                root: {
                    width: '100%',
                    height: '100vh'
                } 
            }
        };
        
        this.state = {
            username: '',
            password: '',
            result: {
                error: ''
            },
            data: {},
            showLogin: false
        }
    }
    
    componentDidMount() {
        let hash = localStorage.getItem('AGENZIA-API-KEY');
        if (hash) {
            this.props.dispatch(
                API.PostJSON('API/RemoteLogin', {
                    hash: hash
                })
            );
        } else {
            this.setState({
                showLogin: true
            })
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            props.data.requestStatus === 'done' &&
            props.data.result &&
            props.data.result.error 
        ) {
            return {
                showLogin: true
            }
        }
        return {}
    }
    
    login() {
        this.props.dispatch(
            API.PostJSON('API/RemoteLogin', {
                username: this.state.username,
                password: this.state.password
            })
        );
    }
    
    showLogin() {
        return this.state.showLogin;
    }
    
    loginForm() {
        const isErrorVisible = this.props.data.api.result && this.props.data.api.result.error;
        const isLoadingVisible = ( this.props.data.api.requestStatus === 'pending' );
        
        return (
            <div>
                <TextField 
                    underlined 
                    placeholder="Username" 
                    style={{width: '500px'}} 
                    onChange={(e) => { this.setState({username: e.target.value})}} 
                />
                
                <TextField 
                    type="password" 
                    underlined 
                    placeholder="Password" 
                    style={{width: '500px'}} 
                    onChange={(e) => { this.setState({password: e.target.value})}} 
                />
                { 
                    isLoadingVisible ?
                    (<Spinner label="Login ..." labelPosition="right" />) :
                    (<PrimaryButton onClick={this.login.bind(this)} text="Login" />) 
                }
    
                { isErrorVisible && (<MessageBar messageBarType={MessageBarType.error}>{this.props.data.api.result.error}</MessageBar>) }
            </div>
        )
    }
    
    render() {
        
        
        return (
            <div>
                <Stack verticalAlign="center" horizontalAlign="center" {...this.columnProps}>
                    <Stack tokens={{childrenGap: 20}} styles={{root: { background: 'white', padding: '20px' }}}>
                        {
                            this.showLogin() ? this.loginForm() :
                            (
                                <Spinner label="Please wait ..." labelPosition="right" />
                            )
                        }
                    </Stack>
                </Stack>
            </div>
            
        ) 
    }
    
}

export default connect(mapStateToProps)(Login);