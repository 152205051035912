import React from 'react';

import { mapStateToProps, connect } from 'containers/FilterFormContainer';

import Loader from 'components/Loader';
import Country from 'components/Country';
import RobotLinks from 'components/RobotLinks';
import API from 'actions/api';

class RobotLinksFilterForm extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            links: [],
            countries: [],
            listLinks: [],
            loaded: false,
            selectedKey: false,
            sort: [],
            ids: {},
            Filter: '',
            selected: {},
            selectedCountries: {}
        }
        this.getLinks = this.getLinks.bind(this);
        this.renderCountryList = this.renderCountryList.bind(this);
        this.onCountryClick = this.onCountryClick.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (props.Filter !== state.Filter) return {
            Filter: props.Filter,
            listLinks: [],
            selected: props.getFilterData ? props.getFilterData('link') : {}
        }
        
        return null;
    }
    
    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (typeof(id)=='string') {
            if (!isChecked) delete selected[id];
            else selected[id] = title;
        } else {
            const k = Object.keys(id);
            k.forEach((key) => {
                if (!isChecked) delete selected[key];
                else selected[key] = id[key];
            });
        }
        this.setState({
            selected: selected
        });
        return this.props.onFilter ? this.props.onFilter('link', selected) : null;
    }
    
    getCountries(data) {
        let countries = [];
        let names = [];
        let ids = {};
        
        if (this.state.Filter.length > 0) {
            data = data.filter((link) => (link.publish_title.toLowerCase().indexOf(this.state.Filter.toLowerCase())>-1));
        }
        
        let linkCountries = {};
        
        data.forEach((link) => {
            if (link.country !== null && link.country !== 0) {
                linkCountries[link.id] = link.country;
                if ( typeof(countries[link.country]) === 'undefined' ) {
                    names.push(link.countryName);
                    ids[link.country] = link.countryName;
                    countries[link.country] = {
                        country: link.countryName,
                        links: {},
                        count: 0,
                        countAll: 0
                    }
                }
                if ( typeof(countries[link.country].links[link.publish_title]) === 'undefined' ) {
                    countries[link.country].links[link.publish_title] = {
                        title: link.publish_title,
                        id: link.id,
                        count: 0,
                        links: []
                    }
                    countries[link.country].count++;
                }
                countries[link.country].countAll++;
                countries[link.country].links[link.publish_title].links.push(link);
                countries[link.country].links[link.publish_title].count++;
            }
        });
        
        const selectedLinks = Object.keys(this.props.getFilterData('link'));
        let selectedCountries = {};
        selectedLinks.forEach((link_id) => {
            const ctry = linkCountries[link_id];
            if ( typeof(selectedCountries[ctry]) === 'undefined' ) selectedCountries[ctry] = 1;
            else selectedCountries[ctry]++;
        })
        
        return {
            countries: countries,
            names: names.filter((name)=>(name.length>1)).sort(),
            ids: ids,
            selectedCountries: selectedCountries
        };
    }
    
    getLinks() {
        API.returnGet(`Links/getAllLinks/?output=json`, true).then((result) => {
            const countries = this.getCountries(Object.values(result.data.Data));
            this.setState({
                links: result.data.Data,
                countries: countries.countries,
                names: countries.names,
                ids: countries.ids,
                loaded: true,
                selectedCountries: countries.selectedCountries
            });
        });
    }
    
    componentDidMount() {
        this.getLinks()
    }
    
    onCountryClick(country) {
        const cx = this.getCountries(Object.values(this.state.links));
        const countries = cx.countries;
        
        try {
            let links = countries[country.id].links;
            
            if (this.state.Filter.length > 0) {
                let nl = {};
                Object.keys(countries[country.id].links)
                .filter((title) => (title.toLowerCase().indexOf(this.state.Filter.toLowerCase())>-1))
                .forEach((title) => {
                    nl[title] = countries[country.id].links[title];
                });
                links = nl;
            } 
            this.setState({
                selectedKey: country.id,
                listLinks: []
            }, () => {
                this.setState({
                    listLinks: links
                })    
            });
            
        } catch (e) {
            this.setState({
                listLinks: []
            })
        }
    }

    renderCountryList() {
        const cx = this.getCountries(Object.values(this.state.links));
        const names = cx.names;
        const ids = cx.ids;
        const countries = cx.countries;
        
        const sortedCountries = Object.keys(countries).sort((x,y) => {
           const xpos = names.indexOf(ids[x]); 
           const ypos = names.indexOf(ids[y]);
           if (xpos<ypos) return -1;
           else if (xpos>ypos) return 1;
           else return 0;
        });
        return (
            <>
            { sortedCountries.map((country) => {
                const className = 'menuItemSimulation' + (this.state.selectedKey === country ? ' selected' : '');    
                return <Country.Inline key={country} id={country} className={className} onClick={this.onCountryClick} getFilterData={this.props.getFilterData} count={this.state.selectedCountries[country]} />
            }) 
                
            }
            </>
        );
    }
    
    renderLink(link) {
        return (
            <div key={link.id} style={{
                width: '100%',
                boxSizing: 'border-box'
            }}>
            <RobotLinks.Group link={link} onCheckboxClick={this.onCheckboxClick} getFilterData={this.props.getFilterData} />
            </div>
        );
    }

    render() {
        if (this.state.loaded === false) return <Loader.Inline />
        else {
            const { listLinks } = this.state;
            return (
                <div style={{
                   display: 'flex',
                   flexDirection: 'row',
                   width: '100%',
                   height: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 0,
                        flexShrink: 0,
                        overflowY: 'auto',
                        width: 250
                    }}>
                        { this.renderCountryList() }
                    </div>
                    <div className={'List-FullWidth no-padding'} style={{
                        display: 'flex',
                        flexGrow: 1,
                        overflowY: 'auto',
                        flexDirection: 'column'
                    }}>
                        {Object.values(listLinks).map((link) => (this.renderLink(link)))}
                    </div>
                </div>
            );
        }
    }
    
}

export default connect(mapStateToProps)(RobotLinksFilterForm);