
function sourcesReducer(state={}, action) {

    switch (action.type) {
        
        case 'SOURCES_MASS_ACTION': 
            return {
                massAction: action.payload.action
            }
            
        case 'SOURCES_TOGGLE_SINGLE': 
            return {
                singleAction: action.payload.id,
                collapsed: action.payload.collapsed
            }
            
        default:
            return {
                
            }
        
    }
    
}

export default sourcesReducer;