import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ComboBox } from 'office-ui-fabric-react/lib/index';

import Flag from "react-flags";

class CountrySingle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            form: { ...props.data }
        }
        
        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
    }
    
    onChange(event) {
        let oldForm = this.state.form;
        oldForm[event.target.name] = event.target.value;
        this.setState({
            form: oldForm
        });
        this.props.setForm(this.state.form);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        let items = [{
            key: '0',
            text: '---'
        }];
        props.countryList.filter(
            (c) => (c.parent==='0')
        ).forEach((c) => {
            if (c.id !== props.data.id) items.push({
                key: c.id,
                text: c.name,
                data: {
                    code: c.code
                }
            })  
        });
        return {
            items: items
        }
    }
    
    onRenderOption(option) {
        return (
            <div>
                {option.data && option.data.code && option.data.code.length>1 && (
                    <Flag
                        name={option.data.code}
                        format="png"
                        pngSize={16}
                        shiny={true}
                        basePath={'/img/'}
                    />
                )}
                <span> {option.text}</span>
            </div>
        )
    }
    
    onRenderTitle(options) {
        return this.onRenderOption(options[0]);
    }
    
    onChangeSelect(event, item) {
        this.onChange({
            target: {
                name: 'parent',
                value: item.key
            }
        });
    }
    
    render() {
        return (
            <div>
                <h1 style={{display: 'flex', alignItems: 'center'}}>
                Edit country:
                &nbsp;
                {( this.props.data && this.props.data.code &&
                    <Flag
                        name={this.props.data.code}
                        format="png"
                        pngSize={32}
                        shiny={true}
                        basePath={'/img/'}
                    /> 
                )}
                &nbsp;
                {this.props.data.name}
                </h1>
                <Stack vertical tokens={{childrenGap: 10}}>
                    <Stack.Item grow={1}>
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item grow={1}>
                                <TextField label={'ISO Code'} name={'code'} defaultValue={this.props.data.code} onChange={this.onChange} />
                            </Stack.Item>
                            <Stack.Item grow={11}>
                                <TextField label={'Title'} name={'name'} defaultValue={this.props.data.name} onChange={this.onChange} />
                            </Stack.Item>
                        </Stack>                    
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <ComboBox
                            label={'Parent'}
                            options={this.state.items}
                            allowFreeform={false}
                            name={'parent'}
                            onChange={this.onChangeSelect}
                            onRenderOption={this.onRenderOption}
                            onRenderTitle={this.onRenderTitle}
                            selectedKey={this.state.form.parent ? this.state.form.parent : undefined}
                            styles={
                                {
                                    callout: {
                                        height: 300,
                                        width: 500
                                    }
                                }
                            }
                        />    
                    </Stack.Item>
                    <Stack.Item grow={11}>
                        <TextField label={'Groups'} name={'groups'} defaultValue={this.props.data.groups} onChange={this.onChange} />
                    </Stack.Item>
                </Stack>
            </div>
        )
    }
    
}

export default CountrySingle