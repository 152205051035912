const defaultState = {
    status: 'offline',
    online: []
}

function realtimeReducer(state=defaultState, action) {
 
    switch (action.type) {
     
        case 'REALTIME_STATUS':
            return {
                ...state,
                status: action.payload.status
            }
        
        case 'REALTIME_USERS':
            return {
                ...state,
                online: action.payload.online
            }
        
        default:
            return state            

    }
    
}

export default realtimeReducer;