import React from 'react';
import Flag from "react-flags";
import { connect } from 'react-redux';

const simpleName = (countries, countryID) => {
    try {
        const c = countries[countryID];
        let n = c.name;
        n = n.split('- ');
        n = n.length === 1 ? n[0] : n[1];
        
        if (c.parent !== '0') {
            const p = countries[c.parent];
            let pn = p.name;
            pn = pn.split('- ');
            pn = pn.length === 1 ? pn[0] : pn[1];
            
            n = `${pn} > ${n}`;
        }
        
        return n;
        
    } catch (e) {
        return '';
    }
}

const fFlag = (countries, countryID) => {
    try {
        let c = countries[countryID];
        if (c.code==='' && c.parent !== '0') c = countries[c.parent];
        let code = c.code;
        if (code && code.length) {
            return (<Flag
                name={code}
                format="png"
                pngSize={16}
                shiny={true}
                basePath={'/img'}
            />);
        } else return '';
    } catch (e) {
        return '';
    }
}            

const countryName = (countries, countryID) => {
    try {
        const c = countries[countryID];
        let n = c.name;
        n = n.split('- ');
        n = n.length === 1 ? n[0] : n[1];
        
        if (c.parent !== '0') {
            const p = countries[c.parent];
            let pn = p.code.length ? p.code : p.name;
            pn = pn.split('- ');
            pn = pn.length === 1 ? pn[0] : pn[1];
            
            n = (<span>{pn} <i className={'fad fa-caret-right'} /> {n}</span>);
        }
        
        return n;
        
    } catch (e) {
        return '';
    }
}

const mapStateToProps = (state, ownProps) => {
    let ret = {};
    if ( 
        state.r_country &&
        state.r_country.countries
    ) return {
        countryName: countryName(state.r_country.countries, ownProps.id),
        simpleName: simpleName(state.r_country.countries, ownProps.id),
        Flag: fFlag(state.r_country.countries, ownProps.id),
        countryList: Object.values(state.r_country.countries),
        Filter: state.navigation && state.navigation.Popup && state.navigation.Popup.Filter ? state.navigation.Popup.Filter : ''
    }
    
    if (
        state.api.requestStatus === 'done' &&
        state.api.result.Module === 'Country' &&
        state.api.result.Function === 'index'
    ) {
        let x = {};
        state.api.result.Data.forEach((a) => {
            x[a.id] = a;
        })
        ret = {
            countryName: countryName(x, ownProps.id),
            simpleName: simpleName(x, ownProps.id),
            Flag: fFlag(x, ownProps.id),
            countryList: Object.values(state.api.result.Data),
            Filter: state.navigation && state.navigation.Popup && state.navigation.Popup.Filter ? state.navigation.Popup.Filter : ''
        };
    }

    return ret;
};

export { mapStateToProps, connect }