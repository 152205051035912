import React from 'react';
import { mapStateToProps, connect } from 'containers/FilterTreeContainer';
import { Country, Category, Language } from 'helpers/';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { Loader } from 'components';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';

class FilterTree extends React.Component {
    
    constructor(props) {
        
        super(props);
        
        this.state = {
            countries: [],
            categories: [],
            languages: [],
            checked: [],
            expanded: []
        }
        
        this.onRenderLink = this.onRenderLink.bind(this);
        this.onLinkExpandClick = this.onLinkExpandClick.bind(this);
        this.dispatchFilter = this.dispatchFilter.bind(this);
    }
    
    dispatchFilter() {
        const keys = Object.keys(this.state.checked);
        let dispatch = {};
        keys.forEach((key) => {
            const sel = this.state.checked[key];
            if (sel === true) {
                const x = key.split(':');
                const type = x[0];
                const id = x[1];
                const name = x[2];
                
                if (typeof(dispatch[type]) === 'undefined') dispatch[type] = {};
                dispatch[type][id] = name;
            }
        });
        
        this.props.dispatch({
            type: 'SET_FILTER_DATA',
            payload: dispatch
        });
        
    }
    
    componentDidMount() {
        Country.Load(this.props.dispatch);
        Language.Load(this.props.dispatch);
        Category.Load(this.props.dispatch);

    }
    
    static getDerivedStateFromProps = (props, state) => {
        
        return props;
        
    }
    
    onLinkExpandClick(e, link) {
        if (e.target.nodeName === 'I' && typeof(link.links) !== 'undefined') {
            const isChecked = typeof(this.state.checked[link.key]) !== 'undefined' && this.state.checked[link.key] === true;
            const checked = !isChecked;
            let checkedList = this.state.checked;
            checkedList[link.key] = checked;
            link.links.forEach((l) => {
                checkedList[l.key] = checked;
            });
            this.setState({
                checked: checkedList
            }, () => {
                this.dispatchFilter()
            })
            return false;
        }
    }
        
    onRenderLink(link) {
        const _onChange = (event, checked) => {
            let checkedList = this.state.checked;
            if (checked) checkedList[link.key] = true;
            else delete checkedList[link.key];
            
            if (typeof(link.links) !== 'undefined') {
                link.links.forEach((l) => {
                    checkedList[l.key] = checked;
                })
            }
            
            this.setState({
                checked: checkedList
            }, () => {
                this.dispatchFilter()
            })
            
        }
        
        const cd = typeof(this.state.checked[link.key]) !== 'undefined' && this.state.checked[link.key] === true;
        
        return <Checkbox label={link.name} checked={cd} onChange={_onChange} />
    }
    
    render() {
        const loaded = this.state.countries.length > 0 && this.state.categories.length > 0 && this.state.languages.length > 0;
        return !loaded ? <Loader.Big /> : (
            <Nav
                styles={{ root: { width: 300 } }}
                onRenderLink={this.onRenderLink}
                onLinkExpandClick={this.onLinkExpandClick}
                groups={[
                    {
                        name: 'Country',
                        links: this.state.countries,
                        collapseByDefault: true
                    },
                    {
                        name: 'Category',
                        links: this.state.categories,
                        collapseByDefault: true
                    },
                    {
                        name: 'Language',
                        links: this.state.languages,
                        collapseByDefault: true
                    }
                ]}
            />
        )
    }
    
}

export default connect(mapStateToProps)(FilterTree);