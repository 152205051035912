import API from 'actions/api';
import { Cache } from 'helpers';
const debounce = require('debounce');

const Country = {
    
    Load: debounce((dispatch) => {
        Cache.GetIfValid('R_FILL_LINKS')
        .then((cacheData) => {
            dispatch({
               type: 'R_FILL_LINKS_FULFILLED',
               payload: {
                   data: {
                       Data: cacheData.data
                   },
                   isFromCache: true
               }
            });    
        })
        .catch((e) => {
            dispatch({
                type: 'R_FILL_LINKS',
                payload: API.returnGet('Links/getAllLinks/?output=json'),
            })
        })
    }, 60000, true),
    
    Get: (dispatch) => {
        dispatch({
            type: 'R_GET_LINKS',
        })
    }
    
}

export default Country; 