
let Navigation = {

    showLeftSidebar: () => ({
        type: 'SIDEBARLEFT_SHOW'
    }),
    
    hideLeftSidebar: () => ({
        type: 'SIDEBARLEFT_HIDE'
    }),
    
    toggleLeftSidebar: () => ({
        type: 'SIDEBARLEFT_TOGGLE'
    }),
    
    changePage: () => ({
        type: 'CHANGE_PAGE'
    }),
    
    showConfirmation: (data) => ({
        type: 'CONFIRMATION_SHOW',
        payload: data
    }),
    
    hideConfirmation: () => ({
        type: 'CONFIRMATION_HIDE'
    }),
    
    showPopup: (payload) => ({
        type: 'POPUP_SHOW',
        payload: payload
    }),
    
    hidePopup: () => ({
        type: 'POPUP_HIDE'
    }),
    
    showPagePopup: (payload) => ({
        type: 'PAGE_POPUP_SHOW',
        payload: payload
    }),
    
    hidePagePopup: () => ({
        type: 'PAGE_POPUP_HIDE'
    }),
    
    showSlidePopup: (payload) => ({
        type: 'SLIDE_POPUP_SHOW',
        payload: payload
    }),
    
    hideSlidePopup: () => ({
        type: 'SLIDE_POPUP_HIDE'
    }),
    
    filterPopup: (t) => ({
        type: 'POPUP_SEARCH',
        payload: {
            text: t
        }
    })
    
}

export default Navigation;