import React from 'react';
import { FontWeights } from '@uifabric/styling';
import { Card } from '@uifabric/react-cards';
import { Icon, Image, Text } from 'office-ui-fabric-react';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            article: props.source._source ? props.source._source : props.source,
        }
        
        this.onClick = this.onClick.bind(this);
        this.removeSource = this.removeSource.bind(this);
    }
    
    onClick() {
        //this.props.onClick(this.state.article);
    }
    
    removeSource() {
        return this.props.removeSource ? this.props.removeSource(this.state.article.id) : null;
    }
    
    render() {
        const siteTextStyles = {
            root: {
                color: '#025F52',
                fontWeight: FontWeights.semibold
            }
        };
        const descriptionTextStyles = {
            root: {
                color: '#333333',
                fontWeight: FontWeights.regular
            }
        };
        const iconStyles = {
            root: {
                color: '#0078D4',
                fontSize: 16,
                fontWeight: FontWeights.regular
            }
        };
        const footerCardSectionStyles = {
            root: {
                borderLeft: '1px solid #F3F2F1'
            }
        };
        
        const cardTokens = { 
            childrenMargin: 12,
            maxWidth: '100%'
        };
        const footerCardSectionTokens = { padding: '0px 0px 0px 12px' };
        
        return (
            <div style={{marginBottom: 20}} data-source-id={`Intelligence:${this.state.article.id}`}>
                <Card horizontal tokens={cardTokens}>
                    <Card.Item fill>
                        <div style={{
                            width: 180,
                            height: 130,
                            background: 'var(--gray-dark)'
                        }} className={'FillImage'}>
                            <Image src={this.state.article.image} alt={''} style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    </Card.Item>
                    <Card.Section style={{flexGrow: 1}}>
                        <Text variant="small" styles={siteTextStyles}>Intelligence</Text>
                        <Text styles={descriptionTextStyles}>{this.state.article.title}</Text>
                    </Card.Section>
                    <Card.Section styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
                        <a href={this.state.article.original_link} target={'_blank'}><Icon iconName="Link" styles={iconStyles} /></a>
                        <Icon iconName={'Delete'} styles={iconStyles} onClick={this.removeSource} />
                    </Card.Section>
                </Card>
            </div>
        )
    }
}