import React from 'react';

import Table from 'components/Table';
import User from 'components/User';
import Elements from 'elements';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'ID',
                    fieldName: 'id',
                    className: 'id',
                    minWidth: 50,
                    maxWidth: 50
                },
                {
                    key: 'when',
                    name: 'When',
                    onRender: (item) => <Elements.DateTime stamp={item.datetime} />,
                    maxWidth: 50,
                    minWidth: 50,
                    className: 'text-center'
                },
                {
                    key: 'user',
                    name: 'User',
                    fieldName: 'user_id',
                    minWidth: 100,
                    maxWidth: 100,
                    onRender: (item) => {
                        let server = {
                            REMOTE_ADDR: ''
                        }
                        try {
                            server = JSON.parse(item.server);
                        } catch {
                            
                        }
                        return (<div><div><User.Inline id={item.user_id} /></div><small>{server.REMOTE_ADDR}</small></div>)
                    },
                    className: 'text-center'
                },
                {
                    key: 'browser',
                    name: 'Browser',
                    onRender: (item) => {
                        let j = JSON.parse(item.server);
                        return <Elements.DeviceInfo agent={j.HTTP_USER_AGENT} />
                    },
                    minWidth: 75,
                    maxWidth: 75,
                    className: 'text-center'
                },
                {
                    key: 'link',
                    name: 'Link',
                    fieldName: 'uri',
                    minWidth: 350
                }
            ]
        }
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'UserActivity/index'}
                    infinite={true} 
                    columns={this.state.columns} 
                />
            </div>
        )
    }
    
}