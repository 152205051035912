import { Cache } from 'helpers';

let defaultState = {
    sources: {}
}

function draftsReducer(state=defaultState, action) {
    let sources = {...state.sources};
    
    switch (action.type) {
        
        case 'DRAFTS_SOURCE_ADD':
            sources[action.payload.type + ':' + action.payload.source.id] = action.payload.source;
            Cache.Save('DRAFTS_SOURCES', sources, 300 * 1000);
            return {
                sources: sources
            }
        
        case 'DRAFTS_SOURCE_DELETE':
            delete sources[action.payload.type + ':' + action.payload.source.id];
            Cache.Save('DRAFTS_SOURCES', sources, 300 * 1000);
            return {
                sources: sources
            }
        
        case 'DRAFTS_SOURCE_CLEAR':
            Cache.Save('DRAFTS_SOURCES', {}, 300 * 1000);
            return {
                sources: {}
            }
        
        case 'DRAFTS_FILL_SOURCES': 
            return {
                sources: action.payload.sources
            }
        
        case 'DRAFTS_GET_SOURCES':
            return state;
            
        default:
            return state;
        
    }
    
}

export default draftsReducer;