import React from 'react';
import { mapStateToProps, connect } from 'containers/CountryContainer';
import { Country } from 'helpers/';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

class CountryInline extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            id: props.id,
            countryName: '',
            simpleName: '',
            Flag: '',
            display: true,
            count: props.count ? props.count : 0
        }

        Country.Load(this.props.dispatch);
        Country.Get(props.dispatch);
        
        this.renderNormal = this.renderNormal.bind(this);
        this.renderReverse = this.renderReverse.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderText = this.renderText.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.getStyle = this.getStyle.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onRender = this.onRender.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.countryName &&
            props.countryName !== ''
        ) {
            return {
                countryName: props.countryName,
                simpleName: props.simpleName,
                Flag: props.Flag,
                display: (!props.displayCondition || props.displayCondition.length === 0 || props.simpleName.toLowerCase().indexOf(props.displayCondition.toLowerCase())>-1)
            }
        } 
        
        return null;
    }
    
    renderNormal() {
        let count = '';
        if (this.state.count>0) count = ' (' + this.state.count + ')';
        return (
            <span style={{fontWeight: (this.state.count>0 ? 'bold' : 'normal')}}>
                {this.state.countryName}{count}
            </span>
        )
    }
    
    renderReverse() {
        let count = '';
        if (this.state.count>0) count = ' (' + this.state.count + ')';
        return (
            <span style={{fontWeight: (this.state.count>0 ? 'bold' : 'normal')}}>
                {this.state.countryName}{count}
            </span>
        )
    }
    
    renderCheckbox() {
        return (
            <Checkbox 
                styles={{
                    root: {
                        display: 'flex-inline'
                    }
                }} 
                onRenderLabel={this.renderText}
                onChange={this.onCheckboxChange}
                checked={this.props.checkboxChecked}
            />
        )
    }
    
    renderText() {
        return !this.props.reverse || this.props.reverse===false ? this.renderNormal() : this.renderReverse()
    }
    
    onCheckboxChange(event, isChecked) {
        return this.props.onCheckboxClick ? this.props.onCheckboxClick(event, isChecked, this.props.id, this.state.simpleName) : false;
    }
    
    getStyle() {
        return this.props.className ? {} : {display: 'inline-flex', alignItems: 'center'};
    }
    
    onClick() {
        return this.props.onClick ? this.props.onClick(this.state) : false;
    }
    
    onRender() {
        return this.props.onRender ? this.props.onRender(this.state) : false;
    }
    
    render() {
        if (this.state.countryName === '' || this.state.display === false) return null;
        else {
            this.onRender();
            return  (
                <span style={this.props.styles ? this.props.styles.wrapper : {}}>
                    <div title={this.state.simpleName} style={this.getStyle()} className={this.props.className} onClick={this.onClick}>
                        {
                            this.props.checkbox && this.props.checkbox !== false ? this.renderCheckbox() : this.renderText()
                        }
                        {
                            
                        }
                    </div>
                </span>
            )
        }
    }
}

export default connect(mapStateToProps)(CountryInline);