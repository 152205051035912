import React from 'react';
import Table from 'components/Table';

import Flag from "react-flags";

import Navigation from 'components/Navigation';
import Language from 'components/Language';

import API from 'actions/api';
import { mapStateToProps, connect } from 'containers/FormContainer';


class LanguageList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Code',
                    fieldName: 'shortcode',
                    maxWidth: 100,
                    onRender: (item) => {
                        return item.shortcode ? item.shortcode.toUpperCase() : '';
                    }
                },
                {
                    key: 'name',
                    name: 'Language',
                    fieldName: 'name'
                },
                {
                    key: 'flag',
                    name: 'Flag',
                    onRender: (item) => {
                        return item.shortcode.length ? (
                            <Flag
                                name={item.shortcode}
                                format="png"
                                pngSize={32}
                                shiny={true}
                                alt={item.name}
                                basePath={'/img/'}
                            />
                        ) : '';
                    }
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                name: ''
            }
        })
    }
    
    onFormSave(form) {
        this.onHide();
        this.props.dispatch(API.PostForm(`Nationality/edit/${this.state.selectedItem.shortcode}`, form));
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Language/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide} onSave={this.onFormSave} initialData={this.state.selectedItem}>
                    <Language.Single data={this.state.selectedItem} />
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(LanguageList);