import React from 'react';
import { mapStateToProps, connect } from '../../containers/MediaContainer';

import Navigation from 'components/Navigation';
import Loader from 'components/Loader';
import Media from 'components/Media'

import aNavigation from 'actions/navigation';
import aMedia from 'actions/media';

import InfiniteScroll from 'react-infinite-scroll-component';

import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

import { registerIcons } from '@uifabric/styling';

registerIcons({
    icons: {
        'View': <i className={'fal fa-eye hoverIcon'} />,
        'Share': <i className={'fal fa-share-square hoverIcon'} />,
        'Pin': <i className={'fal fa-thumbtack hoverIcon'} />,
        'Edit': <i className={'fal fa-edit hoverIcon'} />,
        'Delete': <i className={'fal fa-trash-alt hoverIcon'} />
    }
});

class MediaList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            media: [],
            filteredMedia: [],
            doFilter: false,
            filter: '',
            mediaList: '',
            onMediaClick: this.onMediaClick.bind(this),
            addSlide: props.addSlide ? props.addSlide : false
        };
        
        this.getMedia = this.getMedia.bind(this);
        this.state.getMedia = this.getMedia.bind(this);
        this.onMediaClick = this.onMediaClick.bind(this);
    };
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            props.RELOAD_DATA &&
            props.RELOAD_DATA === true
        ) {
            state.getMedia();
            return {
                media: []
            }
        }
        
        let res = {};
        
        if (typeof props.media !== 'undefined') res.media = state.media.concat(props.media);
        
        if (typeof state.media !=='undefined' && state.media.length>0 && typeof props.Search !== 'undefined' && typeof props.Search.text !=='undefined' && props.Search.text.length>1) {
            res.doFilter = props.Search.didSearch;
            if (res.doFilter) res.media = [];
            res.filter = props.Search.text;
        }
        if (typeof props.Search === 'undefined' || props.Search.text.length<2) res.doFilter = false;
        
        if (typeof(res.media) !== 'undefined') {
            res.mediaList = res.media.map((item, key) => (
                <Media.Item item={item} key={key} onClick={state.onMediaClick} MediaType={props.MediaType} addSlide={state.addSlide} />
            ));
        }
        
        return res;
    }
    
    onMediaClick(type, data, added = false) {
        if (!added) {
            this.props.dispatch({
                type: 'DRAFTS_SOURCE_ADD',
                payload: {
                    type: type,
                    source: data
                }
            })
        } else {
            this.props.dispatch(aNavigation.showConfirmation({
                title: 'Delete source',
                text: 'Delete this source?',
                titleOK: 'OK',
                titleCancel: 'Cancel',
                functionOK: () => { 
                    this.props.dispatch({
                        type: 'DRAFTS_SOURCE_DELETE',
                        payload: {
                            type: type,
                            source: data
                        }
                    })    
                },
                functionCancel: () => { 
                }
            }));
        }
        
        return this.props.onClick ? this.props.onClick(type, data) : null;
    }
    
    getMedia() {
        window.requestAnimationFrame(() => {
            aMedia.List(this.props.dispatch, {
                type: this.props.MediaType,
                start: this.state.media.length,
                q: this.state.filter
            });
        });
    }
    
    componentDidUpdate() {
        if ( this.state.doFilter === true ) {
            this.getMedia();
        }
    }
    
    componentDidMount() {
        this.setState({
            media: [],
            filteredMedia: [],
            doFilter: false
        })
        this.getMedia();
    }
    
    setMedia(media) {
        this.setState({
            media: media
        })
    }
    
    onRenderRow(row) {
        return (
            <h1>{row.name}</h1>
        )
    }
    
    render() {
        if (this.state.media.length === 0) return <Loader.Big />;

        return (
            <>
                <Navigation.SidebarLeft>
                    <h1>Photo options</h1>
                </Navigation.SidebarLeft>
               <InfiniteScroll
                    dataLength={this.state.media.length}
                    next={this.getMedia}
                    hasMore={true}
                    className="gridWrapper"
                    height="100%"
                    loader={<Spinner label="Loading..." ariaLive="assertive" labelPosition="right" />}
                >
                {this.state.mediaList}
                </InfiniteScroll>
            </>
        )
    }
    
}

export default connect(mapStateToProps)(MediaList);