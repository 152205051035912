import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    let countries = {};
    let categories = {};
    let languages = {};
    
    let res = {};
    
    //Countries
    if (state.r_country && state.r_country.countries && Object.keys(state.r_country.countries).length > 0) {
        const country_ids = Object.keys(state.r_country.countries);
        country_ids.forEach((cid) => {
           const country = state.r_country.countries[cid];
           if (country.parent === '0') {
               countries['country:' + cid + ':' + country.name] = {
                   key: 'country:' + cid + ':' + country.name,
                   name: country.name
               }
           }
        });
        country_ids.forEach((cid) => {
           const country = state.r_country.countries[cid];
           if (country.parent !== '0') {
               if ( typeof(countries['country:' + country.parent + ':' + state.r_country.countries[country.parent].name].links) === 'undefined' ) {
                   countries['country:' + country.parent + ':' + state.r_country.countries[country.parent].name]['links'] = [];
               }
               countries['country:' + country.parent + ':' + state.r_country.countries[country.parent].name].links.push({
                   key: 'country:' + cid + ':' + country.name.split('- ')[1],
                   name: country.name.split('- ')[1]
               });
           }
        });
    }
    
    //Categories
    if (state.r_category && state.r_category.categories && Object.keys(state.r_category.categories).length > 0) {
        categories = Object.values(state.r_category.categories).map((c) => ({
            key: 'category:' + c.id + ':' + c.name,
            name: c.name
        }));
    }
    
    //Language
    if (state.r_language && state.r_language.languages && Object.keys(state.r_language.languages).length > 0) {
        languages = Object.values(state.r_language.languages).map((l) => ({
            key: 'language:' + l.shortcode + ':' + l.name,
            name: l.name
        }));
    }
    
    if ( Object.keys(countries).length ) res['countries'] = Object.values(countries);
    if ( Object.keys(languages).length ) res['languages'] = Object.values(languages);
    if ( categories.length ) res['categories'] = categories;
    
    return res;
    
}

export { mapStateToProps, connect }