import React from 'react';
import { Formik, Form } from 'formik';
import { TextField, Checkbox, Label, DatePicker } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import API from 'actions/api';
import { Loader, Country, Language, Users } from 'components'

import * as Yup from 'yup';

let doSubmit = null;

class AssignmentNew extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dispatched: false,
            hideFooter: false,
            submitAssignment: this.submitAssignment.bind(this),
            loadFormData: this.loadFormData.bind(this),
            loaded: false
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        return null;
    }
    
    submitAssignment() {
        doSubmit();
        return false;
    }
    
    loadFormData() {
        API.returnGet(`Assignments/newAssignmentForm/${this.props.id}?output=json`, true).then((result) => {
            this.setState({
                loaded: true,
                formData: result.data.Data
            })
        });
    }
    
    componentDidMount() {
        this.loadFormData()
    }
    
    render() {
        
        return this.state.loaded ? (
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    deadline: new Date().toISOString().slice(0, 10)
                }}
                validationSchema={
                    Yup.object().shape({
                    	title:
                    		Yup.string()
                    		.min(5, 'Minimum 5 characters')
                    		.required('Title is required'),
                    	country:
                    	    Yup.string()
                    	    .required('Country is required'),
                    	language:
                    	    Yup.string()
                    	    .required('Language is required'),
                    	user:
                    	    Yup.string()
                    	    .required('User is required'),
                    	deadline:
                    	    Yup.string()
                    })
                }
                validateOnBlur={true}
                validateOnChange={true}
                validateOnMount={true}
            >
                {({ values, errors, initialErrors, touched, handleChange, handleBlur, handleSubmit, validateForm, setFieldValue, submitForm, isValid }) => {
                    doSubmit = handleSubmit;
                    
                    let setFieldValueDO = (field, value) => {
                        setFieldValue(field, value);
                        if (this.props.updateForm) this.props.updateForm(field, value, errors);             
                    }

                    let handleChangeDO = (target) => {
                        handleChange(target);
                        if (this.props.updateForm)this.props.updateForm(target.target.name, target.target.value, errors);             
                    }
                    
                    return (
                        <Form>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1
                            }}>
                                <div className={'paddedRow'}>
                                    <TextField 
                                        label="Title" 
                                        name="title" 
                                        errorMessage={errors.title ? errors.title : null}
                                        onChange={handleChangeDO}
                                        value={values.title}
                                    />
                                </div>
                                <div className={'paddedRow'}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        fleDirection: 'column',
                                        flexBasis: '50%'
                                    }}>
                                        <DatePicker 
                                            label={'Data di consegna'}
                                            styles={{
                                                root: {
                                                    display: 'flex',
                                                    flexGrow: 1
                                                },
                                                callout: {
                                                    display: 'flex',
                                                    flexGrow: 1
                                                }
                                            }}
                                            value={new Date(values.deadline)}
                                            onSelectDate={(d) => {setFieldValueDO('deadline', d.toISOString().slice(0, 10))}}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexDirection: 'column',
                                        flexBasis: '50%'
                                    }}>
                                        <Label styles={{
                                            root: {
                                                marginBottom: 8
                                            }
                                        }}>{'Priority'}</Label>
                                        <Checkbox label="Assignment is high priority" onChange={(ev, checked) => {setFieldValueDO('priority', checked ? '1' : '0')}} />
                                    </div>
                                </div>
                                <div className={'paddedRow'}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexBasis: '50%'
                                    }}>
                                        <Country.Dropdown
                                            name={'country'}
                                            onChange={(e,o) => {setFieldValueDO('country', o.key)}}
                                            errorMessage={errors.country ? errors.country : null}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexBasis: '50%'
                                    }}>
                                        <Language.Dropdown
                                            name={'language'}
                                            onChange={(e,o) => {setFieldValueDO('language', o.key)}}
                                            errorMessage={errors.language ? errors.language : null}
                                        />
                                    </div>
                                </div>
                                <div className={'paddedRow'}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1
                                    }}>
                                        <Users.Dropdown
                                            name={'user'}
                                            onChange={(e,o) => {setFieldValueDO('user', o.key)}}
                                            errorMessage={errors.user ? errors.user : null}
                                        />
                                    </div>
                                </div>
                                <div className={'paddedRow'}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1
                                    }}>
                                        <TextField 
                                            label="Message" 
                                            name="message" 
                                            onChange={handleChangeDO}
                                            multiline
                                            rows={5}
                                            resizable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        ) : <Loader.Big />
        
    }
    
}

export default connect()(AssignmentNew);