import React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export default class extends React.Component {
    
    constructor(props) {
        
        super(props);
        
        this.state = {
            time: props.time ? props.time * (props.seconds ? 1 : 10) : (props.seconds ? 5: 50),
            precision: props.seconds ? 'seconds' : 'milli',
            interval: setInterval(() => {
                this.decreaseCounter()
            }, props.seconds ? 1000 : 100)
        }
        
        this.Retry = this.Retry.bind(this);
        this.decreaseCounter = this.decreaseCounter.bind(this);
        this.counterText = this.counterText.bind(this);
        this.retryText = this.retryText.bind(this);
    }
    
    counterText() {
        let time = this.state.time;
        if (time>0) {
            if (this.state.precision === 'seconds') time = this.state.time - 1;
            else {
                time = this.state.time / 10;
                if (Math.round(time) === time) time = `${time}.0`;
            }
        }
        return this.props.counterText ? this.props.counterText.replace(/\{COUNTER}/, time) : `Retry in ${time} seconds`;
    }
    
    retryText() {
        return this.props.retryText ? this.props.retryText : 'Retrying';
    }
    
    componentWillUnmount() {
        clearInterval(this.state.interval);
    }
    
    decreaseCounter() {
        if (this.state.time>0) {
            this.setState({
                time: this.state.time-1
            })
        }
    }
    
    Retry() {
        return this.props.onRetry ? this.props.onRetry() : null;
    }
    
    render() {
    
        return (
            <Spinner
                size={SpinnerSize.large}
                label={this.state.time > 0 ? this.counterText() : this.retryText()} 
                styles={{
                    circle: {
                        borderColor: 'rgba(255, 0, 0, 1) rgba(255, 0, 0, .2) rgba(255, 0, 0, .2)'
                    },
                    label: {
                        color: 'rgb(255, 0, 0)'
                    }
                }}
            />
        );
        
    }
    
}