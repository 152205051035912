import API from '../actions/api';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    if (typeof state.navigation.sidebarLeft != 'undefined') return {
        sidebarLeft: state.navigation.sidebarLeft
    }
    return {}
};
    
const mapDispatchToProps = (dispatch) => {
    return {
        API: () => {
            dispatch(API.Get('Test'))
        },
        Login: () => {
            dispatch(API.Login())
        },
        Logout: () => {
            dispatch(API.Logout())
        }
    }
};

export { mapStateToProps, mapDispatchToProps, connect }