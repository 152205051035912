import React from 'react';
import { mapStateToProps, connect } from 'containers/CategoryContainer';
import { Category } from 'helpers/';
import Loader from 'components/Loader';
import { Nav } from 'office-ui-fabric-react/lib/Nav';

class CategoryTree extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            categoryList: [],
            loaded: false
        }

        Category.Load(this.props.dispatch);
        Category.Get(props.dispatch);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.categoryList &&
            props.categoryList.length &&
            state.categoryList.length === 0
        ) {
            return {
                categoryList: props.categoryList ? props.categoryList : [],
                loaded: true
            }
        } 
        
        return null;
    }
    

    render() {
        return this.state.loaded ? (<>
            {(
                <Nav
                  styles={{ root: { width: 300 } }}
                  groups={[
                    {
                        links: [
                            {
                                name: 'Category',
                                links: this.state.categoryList.map((category) => ({
                                    name: category.name
                                }))
                            }
                        ]
                    }
                  ]}
                />
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(CategoryTree);