import List from './RobotLinksList';
import Single from './RobotLinksSingle';
import Stats from './RobotLinksStats'
import Inline from './RobotLinksInline'
import FilterForm from './RobotLinksFilterForm'
import Group from './RobotLinksGroup'

export default { 
    List, 
    Single, 
    Stats,
    Inline,
    FilterForm,
    Group
}