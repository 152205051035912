import { connect } from 'react-redux';

const languageName = (languages, languageID) => {
    try {
        const c = languages[languageID];
        let n = c.name;

        return n;
        
    } catch (e) {
        return '';
    }
}

const mapStateToProps = (state, ownProps) => {
    let ret = {};
    if ( 
        state.r_language &&
        state.r_language.languages
    ) return {
        languageName: languageName(state.r_language.languages, ownProps.id),
        languageList: Object.values(state.r_language.languages)
    }
    
    if (
        state.api.requestStatus === 'done' &&
        state.api.result.Module === 'Language' &&
        state.api.result.Function === 'index'
    ) {
        let x = {};
        state.api.result.Data.forEach((a) => {
            x[a.shortcode] = a;
        })
        ret = {
            languageName: languageName(x, ownProps.id),
            languageList: state.api.result.Data
        };
    }

    return ret;
};

export { mapStateToProps, connect }