import React from 'react';

import { SourcePreview as IntelligenceSource } from 'components/Intelligence';
import { SourcePreview as MediaSource } from 'components/Media';

export default (props) => {
    
    const removeSource = () => {
        return props.removeSource ? props.removeSource(props.draft) : null
    }
    
    if (props.draft.MediaType === 'Intelligence') return <IntelligenceSource removeSource={removeSource} source={props.draft} />
    else if ( ['Photo', 'Video', 'Scan', 'Document'].indexOf(props.draft.MediaType) > -1 ) return <MediaSource removeSource={removeSource} source={props.draft} />
    else return (
        <div style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 5,
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            borderBottom: '1px solid var(--gray-light)',
            cursor: 'default'
        }}
        className={'grayHover'}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}>
                <div style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                    <h4 style={{
                        margin: 0
                    }}>
                        {props.draft.title ? props.draft.title : props.draft.name}
                    </h4>
                </div>
                <div 
                    style={{
                        width: 20,
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        return props.removeSource ? props.removeSource(props.draft) : null
                    }}
                >
                <i class={'fal fa-times'} />
                </div>
            </div>
            <div style={{
                display: 'flex'
            }}>
                <small>
                    {`${props.draft.MediaType}:${props.draft.id}`}
                </small>
            </div>
        </div>
    );
    
}