import API from 'actions/api';
import { Cache } from 'helpers';
const debounce = require('debounce');

const Users = {
    
    Load: debounce((dispatch) => {
        Cache.GetIfValid('R_FILL_USERS')
        .then((cacheData) => {
            dispatch({
                type: 'R_FILL_USERS_FULFILLED',
                payload: {
                       data: {
                           Data: Object.values(cacheData.data)
                    }
                }
            });
        })
        .catch((e) => {
            dispatch({
                type: 'R_FILL_USERS',
                payload: API.returnGet('Users/List?output=json')
            })
        })
    }, 60000, true),
    
    Get: (dispatch) => {
        dispatch({
            type: 'R_GET_USERS'
        })
    }
    
}

export default Users; 