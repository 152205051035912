import React from 'react';
import { ResponsiveBar, Bar } from '@nivo/bar'
import API from 'actions/api';
import { AutoSizer } from 'react-virtualized'

import { mapStateToProps, connect } from 'containers/APIContainer';

class RobotLinksStats extends React.Component {
    
    static updateTimeout = 0;
    
    constructor(props) {
        super(props);
        
        this.state = {
            workingKeys: [],        
            workingData: [],
            periodKeys: [],
            periodData: [],
        }
        
        this.state.colors = this.colors;
        this.callAPI = this.callAPI.bind(this);
        this.repeatUpdate = this.repeatUpdate.bind(this);
    }
    
    colors = (name) => {
        switch (name) {
            case 'overtime':
                return 'orange';
            case 'not_visited':
                return 'blue';
            case 'not_working':
                return 'red';
            case 'ok':
                return 'green';
            case 'disabled':
                return 'gray';
            case 'es-3':
                return 'green';
            case 'es-4':
                return 'blue';
            case 'es-5':
                return 'orange';
            case 'm.e':
                return 'red';
            case 'bl.m':
                return 'purple';
            default:
                return 'black'
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        
        let res = {};
        
        if (
            props.MyData &&
            props.MyData.links &&
            props.MyData.links.break
        ) {
            let tmp = {
                name: 'Links',
                colors: []
            };
            const keys = Object.keys( props.MyData.links.break );
            keys.forEach((a) => {
                tmp[a] = props.MyData.links.break[a].count
                tmp['colors'][a] = state.colors(a);
            });
            res['workingData'] = [tmp];
            res['workingKeys'] = keys;
            
        }
        
        if (
            props.MyData &&
            props.MyData.intelligence
        ) {
            res['intelligenceData'] = [];
            res['intelligenceKeys'] = [];
            
            const intelligenceServers = Object.keys( props.MyData.intelligence );
            
            intelligenceServers.forEach((server) => {
                
                const dates = props.MyData.intelligence[server];
                dates.forEach((date) => {
                   if (res.intelligenceKeys.indexOf(date.x) === -1) res.intelligenceKeys.push(date.x);
                });
                
            });

            res.intelligenceKeys.forEach((date) => {
                let tmp = {
                    DT: date,
                }
                intelligenceServers.forEach((server) => {
                     let x = 1;
                     tmp['color' + server] = state.colors(server);
                     props.MyData.intelligence[server].forEach((entry) => {
                         if (entry.x === date) x = entry.y;
                     });
                     tmp[server] = x;
                });
                res.intelligenceData.push(tmp);
            });
            
            res.intelligenceKeys = intelligenceServers;
            
        }
        return res;
        
    }
    
    
    repeatUpdate() {
        RobotLinksStats.updateTimeout = setTimeout(() => {
            this.callAPI();
        }, 120000);
    }
    
    callAPI() {
        this.props.dispatch(API.Get('Links/stats?output=json'));
        this.repeatUpdate();
    }
    
    componentDidMount() {
        this.callAPI();
    }
    
    componentWillUnmount() {
        clearTimeout(RobotLinksStats.updateTimeout);
    }
    
    render() {
        if (this.state.workingData.length === 0) return '';
        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{height: 20}}>
                    <ResponsiveBar
                        data={this.state.workingData}
                        keys={this.state.workingKeys}
                        indexBy={'name'}
                        layout="horizontal" 
                        enableGridY={false} 
                        enableGridX={false}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        padding={0}
                        colors={(e) => (e.data.colors[e.id])}
                        label={(e) => (e.id.toUpperCase().replace(/_/g, ' '))}
                        labelSkipWidth={50}
                        labelTextColor="#ffffff"
                    />
                </div>
                <div style={{flexGrow: 1, display: 'flex'}}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Bar
                                height={height}
                                width={width}
                                data={this.state.intelligenceData}
                                keys={this.state.intelligenceKeys}
                                indexBy={'DT'}
                                enableGridY={true} 
                                enableGridX={true}
                                margin={{ top: 1, right: 0, bottom: 0, left: 0 }}
                                padding={0.1}
                                groupMode={'stacked'}
                                enableLabel={false}
                                animate={false}
                                axisLeft={null}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={null}
                                colors={(e) => (e.data[`color${e.id}`])}
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
    
}

export default connect(mapStateToProps('Links/stats'))(RobotLinksStats);