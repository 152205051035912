import MenuTop from './MenuTop';
import MenuBottom from './MenuBottom';
import MenuMain from './MenuMain';
import SidebarLeft from './SidebarLeft'
import SidebarRight from './SidebarRight'
import ConfirmationBox from './ConfirmationBox'
import Popup from './Popup';
import ReloadButton from './ReloadButton';
import PagePopup from './PagePopup'
import PageOverlay from './PageOverlay'
import SlidePopup from './SlidePopup'

export default { 
    MenuTop,
    SidebarLeft,
    MenuBottom,
    MenuMain,
    SidebarRight,
    ConfirmationBox,
    Popup,
    ReloadButton,
    PagePopup,
    PageOverlay,
    SlidePopup
}