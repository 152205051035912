import React from 'react';
import RobotLinks from 'components/RobotLinks';
import Intelligence from 'components/Intelligence';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { OverflowSet } from 'office-ui-fabric-react/lib/OverflowSet';
import { connect, mapStateToProps } from 'containers/DraftsContainer';

class IntelligenceArticleRow extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            article: props.article._source ? props.article._source : props.article,
            active: false,
            expanded: false,
            inSources: false
        }
        this.state.article.MediaType = 'Intelligence';
        this.onClick = this.onClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
    }
    
    onClick() {
        this.setState({
            active: true,
            expanded: this.props.noExpand ? false : !this.state.expanded
        });
        if (this.props.onClick) this.props.onClick(this.state.article);
    }
    
    onMouseOver() {
        if (this.props.onMouseOver) this.props.onMouseOver(this.state.article);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        
        if (props.sources && props.sources[`Intelligence:${state.article.id}`]) return {
            inSources: true
        } 
        else return {
            inSources: false
        }
        
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.inSources !== this.state.insSources
    }
    
    render() {
        return (
             <div className={'intelligence-Article' + (this.props.active ? ' active' : '')} data-id={this.state.article.id} onMouseOver={this.onMouseOver} onClick={this.onClick} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row'
                }}>
                    <div style={{
                        width: 50,
                        padding: 0,
                        margin: 0,
                        flexGrow: 0,
                        flexShrink: 0
                    }}>
                        <OverflowSet
                            aria-label="Vertical Example"
                            vertical
                            items={[
                              {
                                key: 'add_source',
                                icon: !this.state.inSources ? 'Add' : 'Remove',
                                name: 'Add source',
                                className: !this.state.inSources ? 'btnGreen' : 'btnRed',
                                onClick: (e) => {
                                    e.stopPropagation();
                                    if (this.state.inSources) {
                                        if (this.props.onRemove) this.props.onRemove(this.state.article)
                                    }
                                    else {
                                        if (this.props.onAdd) this.props.onAdd(this.state.article)
                                    }
                                }
                              },
                              {
                                key: 'e_org',
                                icon: 'WebComponents',
                                name: 'Link 2',
                                onClick: (e) => {
                                    e.stopPropagation();
                                    window.open(`https://newsbox.tech/article/${this.state.article.hash}`)
                                }
                              },
                              {
                                key: 'original_link',
                                icon: 'Link',
                                name: 'Open original link',
                                onClick: (e) => {
                                    e.stopPropagation();
                                    window.open(this.state.article.original_link)
                                }
                              }
                            ]}
                            overflowItems={[
                              {
                                key: 'item4',
                                icon: 'Mail',
                                name: 'Overflow Link 1'
                              },
                              {
                                key: 'item5',
                                icon: 'Calendar',
                                name: 'Overflow Link 2'
                              }
                            ]}
                            onRenderOverflowButton={(overflowItems) => (null)}
                            onRenderItem={(item) => (
                                <CommandBarButton
                                    role="menuitem"
                                    aria-label={item.name}
                                    styles={{ root: { padding: '10px' } }}
                                    iconProps={{ iconName: item.icon }}
                                    onClick={item.onClick}
                                    className={item.className}
                                />
                            )}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        padding: 0,
                        margin: 0,
                        flexDirection: 'column'
                    }}>
                       
                            <div className={'intelligence-TitleContainer'} style={{
                                    width: this.state.expanded ? '750px' : '100%',
                                    flexDirection: this.state.expanded ? 'column' : 'row',
                                    alignItems : 'baseline'
                                }}>
                                { this.props.noMedia ? null : (
                                <RobotLinks.Inline id={this.state.article.link_real_id} Flag={'left'} hideCountry />
                                )}
                                <div className={'intelligence-Title'} style={{
                                    marginLeft: this.state.expanded ? '20px' : '5px'
                                }} dangerouslySetInnerHTML={{
                                    __html: this.state.article.title
                                }}>
                                </div>
                                {/* <div className={'intelligence-Link'}>
                                    <RobotLinksInline id={this.state.article.link_real_id} />
                                </div> */}
                            </div>
                            <div className={'intelligence-Intro font-reading font-reading-small'} style={{
                                height: this.state.expanded ? 'auto' : '50px',
                                overflow: this.state.expanded ? 'visible' : 'hidden',
                                margin: this.state.expanded ? '20px' : '5px',
                                width: this.state.expanded ? '750px' : '100%'
                            }}>{this.state.article.text.trim()}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                { this.props.noCountry ? null : (
                                <div style={{ display: 'flex', width: 'auto', whiteSpace: 'nowrap', flexGrow: 0, marginRight: 10 }}>
                                    <RobotLinks.Inline id={this.state.article.link_real_id} Flag={'left'} hideTitle /> 
                                </div>
                                )}
                                <div style={{ display: 'flex', flexGrow: 0, alignItems: 'center' }}>
                                </div>
                                { this.props.noKeywords ? null : (
                                <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                                    <Intelligence.InlineKeywords id={this.state.article.id} keywords />
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            
        )
    }
}

export default connect(mapStateToProps)(IntelligenceArticleRow);