import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

class LanguageSingle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            form: { ...props.data }
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    onChange(event) {
        let oldForm = this.state.form;
        oldForm[event.target.name] = event.target.value;
        this.setState({
            form: oldForm
        });
        this.props.setForm(this.state.form);
    }

    render() {
        return (
            <div>
                <h1 style={{display: 'flex', alignItems: 'center'}}>
                Edit language:
                &nbsp;
                {this.props.data.name}
                </h1>
                <Stack vertical tokens={{childrenGap: 10}}>
                    <Stack.Item grow={1}>
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item grow={1}>
                                <TextField label={'ISO Code'} name={'shortcode'} defaultValue={this.props.data.shortcode} onChange={this.onChange} />
                            </Stack.Item>
                            <Stack.Item grow={11}>
                                <TextField label={'Title'} name={'name'} defaultValue={this.props.data.name} onChange={this.onChange} />
                            </Stack.Item>
                        </Stack>                    
                    </Stack.Item>
                </Stack>
            </div>
        )
    }
    
}

export default LanguageSingle