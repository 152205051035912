import API from './api';

export default {
    
    List: (dispatch, data) => {
        let { q, start, type } = data;
        if (!start) start = 0;
        if (!q) q = '';
        dispatch(API.Get(`${type}/?start=${start}&q=${q}&output=json`));
    }
    
}