import React from 'react';

export default (props) => {

    return <iframe 
        title={'DocumentPreview'}
        width={'100%'}
        height={'100%'}
        frameborder={0}
        src={props.data.fileData.location}
        style={{
            width: '100%',
            height: '100%',
            border: 0
        }}
    />;
}