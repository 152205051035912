import React from 'react';
import { TextField, PrimaryButton } from 'office-ui-fabric-react';
import { Country, Language } from 'components';
import { LocationMap as Map } from 'components/Location';
import Navigation from 'actions/navigation';
import { connect } from 'react-redux';

class SingleAssignmentInfo extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            assignment: this.props.assignment
        }
        
        this.updateData = this.updateData.bind(this);
        this.introChange = this.textChange.bind(this, 'intro');
        this.journalistChange = this.textChange.bind(this, 'journalist');
        this.trendChange = this.textChange.bind(this, 'meta.trend');
        this.locationChange = this.textChange.bind(this, 'meta.locationText');
        this.countryChange = this.textChange.bind(this, 'country');
        this.languageChange = this.textChange.bind(this, 'lang');
        this.showMap = this.showMap.bind(this);
        this.selectLocation = this.selectLocation.bind(this);
    }
    
    updateData (key, value) {
        if (this.props.updateData) this.props.updateData(key, value);
    }
    
    textChange(key, ev, value) {
        if (value.key) this.updateData(key, value.key);
        else this.updateData(key, value);
    }
    
    selectLocation(data) {
        let a = this.state.assignment;
        if (!a.savedMeta) a.savedMeta = {};
        if (!a.savedMeta.locationText) a.savedMeta.locationText = '';
        a.savedMeta.locationText = data.term;
        this.setState({
            assignment: a
        })
        console.log(a);
        console.log('selectLocation', data);
    }
    
    showMap() {
        this.props.dispatch(Navigation.showPagePopup({
            title: 'Location Map',
            children: <Map onSelectLocation={this.selectLocation} />,
            showSearch: false,
            titleOK: 'OK',
            titleCancel: false
        }));
    }
    
    render() {
        console.log('render', this.state.assignment);
        return this.state.assignment ? (
        
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box'
            }}>
                <div style={{ 
                    display: 'flex',
                    padding: 10
                }}>
                    <TextField label={'Introduction'} multiline rows={3} defaultValue={this.state.assignment.intro} onChange={this.introChange} />
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexBasis: '33%',
                        padding: 10
                    }}>
                        <Country.Dropdown selectedItem={this.state.assignment.country} onChange={this.countryChange} />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexBasis: '33%',
                        padding: 10
                    }}>
                        <Language.Dropdown selectedItem={this.state.assignment.language} onChange={this.languageChange} />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexBasis: '33%',
                        padding: 10
                    }}>
                        <TextField label={'Journalist'} defaultValue={this.state.assignment.journalist} onChange={this.journalistChange} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexBasis: '33%',
                        padding: 10
                    }}>
                        <TextField label={'Trend'} onChange={this.trendChange} defaultValue={this.state.assignment.savedMeta && this.state.assignment.savedMeta.trend ? this.state.assignment.savedMeta.trend : ''} />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexBasis: '33%',
                        padding: 10
                    }}>
                        <TextField label={'Location'} onChange={this.locationChange} defaultValue={this.state.assignment.savedMeta && this.state.assignment.savedMeta.locationText ? this.state.assignment.savedMeta.locationText : ''} />
                        <PrimaryButton text={'Map'} styles={{root: {marginTop: 29}}} onClick={this.showMap} />  
                    </div>
                </div>
            </div>
            
            
        ) : null;
        
    }
    
}

export default connect()(SingleAssignmentInfo);