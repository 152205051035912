import React from 'react';

import Table from 'components/Table';

var moment = require('moment');

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'ID',
                    fieldName: 'id',
                    className: 'id',
                    maxWidth: 150
                },
                {
                    key: 'when',
                    name: 'When',
                    onRender: (item) => (moment(item.datetime).format('DD.MM.YYYY hh:mm')),
                    maxWidth: 150
                },
                {
                    key: 'user',
                    name: 'User',
                    onRender: (item) => (`${item.user.first_name} ${item.user.last_name}`),
                    maxWidth: 300
                },
                {
                    key: 'ip',
                    name: 'IP',
                    fieldName: 'ip',
                    maxWidth: 200
                },
                {
                    key: 'type',
                    name: 'Type',
                    onRender: (item) => (item.type.charAt(0).toUpperCase() + item.type.slice(1)),
                    maxWidth: 150
                },
                {
                    key: 'term',
                    name: 'Term',
                    fieldName: 'search'
                },
                {
                    key: 'results',
                    name: 'Results',
                    fieldName: 'results',
                    maxWidth: 150
                }
            ]
        }
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'LogViewer/Search'}
                    infinite={true} 
                    columns={this.state.columns} 
                />
            </div>
        )
    }
    
}