import React from 'react';
import { mapStateToProps, connect } from 'containers/MenuContainer';
import hUtilities from 'helpers/Utilities';

import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';

class RefreshButton extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loading: false
        }
        this.onClick = this.onClick.bind(this);
    }
    
    onClick = () => {
        this.props.dispatch({type: 'RELOAD_DATA'});
        hUtilities.Unfocus();
    }

    static getDerivedStateFromProps = (props, state) => {
        
        if (typeof(props.loading) !== 'undefined' && props.loading !== state.loading) return { loading: props.loading }
        
        return null
        
    }
    
    render() {
        return (
            <CommandBarButton onClick={this.onClick}>
                <i className={(this.state.loading ? 'fad fa-sync fa-spin' : 'fas fa-sync')} style={{'--fa-secondary-color': 'var(--etleboro-red)'}} />
            </CommandBarButton>
        );
    }
    
}

export default connect(mapStateToProps)(RefreshButton);