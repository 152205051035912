import React from 'react';

import Loader from 'components/Loader';
import API from 'actions/api';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default class extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            loaded: props.item ? true : false,
            item: props.item ? props.item : {},
            type: props.type ? props.type : 'Photo'
        }
        
        this.getItem = this.getItem.bind(this);
    }
    
    getItem() {
        API.returnGet(`${this.state.type}/ID/${this.props.id}?output=json`, true).then((result) => {
            this.setState({
                loaded: true,
                item: result.data.Data
            });
        });
    }
    
    componentDidMount() {
        if (!this.state.loaded) this.getItem();
    }

    render() {
        
        if (!this.state.loaded) return <Loader.Big />;
        
        const { item } = this.state;

        let thumbnail = '';
        
        try {
            thumbnail = item.fileData.location
        } catch(e) {
            thumbnail = '';
        }    

        const key = window.localStorage.getItem('AGENZIA-API-KEY');
        
        let people = [];
        if (typeof item.assignments !== 'undefined' && item.assignments !== false && item.assignments.length > 0) {
            item.assignments.map((a) => {
                people.push({
                    name: a.user.name,
                    profileImageSrc: `https://bl.m.etleboro.org/APP/img/UserIcon.php?uid=${a.user.id}&X_API_KEY=${key}&p=1`
                });
                return true;
            })
        }
        if (people.length === 0) people = [{
            name: '',
            allowPhoneInitials: false,
            initials: '',
            initialsColor: 'transparent'
        }];
        
        if (this.state.type==='Video') return <video src={thumbnail} width={'100%'} controls />
        else if (this.state.type==='Document') return <iframe src={thumbnail} title={thumbnail} width={'100%'} height={500} frameBorder={0} />
        else if (this.state.type==='Photo' && this.state.item.connected.length) return (
            <Carousel
                showThumbs={false}
                infiniteLoop={true}
            >
                <div>
                    <img src={thumbnail} alt={''} />
                </div>
                {this.state.item.connected.map((connected) => (<div style={{backgroundColor: 'black'}}><img alt={''} style={{maxHeight: 500, objectFit: 'contain', objectPosition: 'center'}} src={connected.fileData.location} /></div>))}
            </Carousel>
        )
        else return <img width={'100%'} src={thumbnail} alt={''} />
    }
    
}