import React from 'react';
import Keywords from 'components/Keywords';
import Loader from 'components/Loader';
import API from 'actions/api';

import { mapStateToProps, connect } from 'containers/FilterFormContainer';

import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { List } from 'office-ui-fabric-react/lib/List';

class KeywordsFilterForm extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            keywords: [],
            loaded: false,
            idt: {},
            selectedKey: false,
            Filter: '',
            selected: {},
            selectedCountries: {}
        }
        
        
        
        this.getKeywords = this.getKeywords.bind(this);
        this.getTypes = this.getTypes.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
        this.renderKeyword = this.renderKeyword.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
        this.getSelectedCountries = this.getSelectedCountries.bind(this);
        this.state.getSelectedCountries = this.getSelectedCountries.bind(this);
    }

    static getDerivedStateFromProps = (props, state) => {
        if (props.Filter !== state.Filter || props.getFilterData) {
            const ret = {
                Filter: props.Filter ? props.Filter : state.Filter,
                selectedKey: (props.Filter !== state.Filter) ? false : state.selectedKey,
                listKeywords: (props.Filter !== state.Filter) ? [] : state.listKeywords,
                selected: props.getFilterData ? props.getFilterData('keywords') : state.selected,
                selectedCountries: props.getFilterData ? state.getSelectedCountries(props.getFilterData('keywords')) : state.selectedCountries,
            }
            return ret;
        }
        
        return null;
    }

    getSelectedCountries() {
        const ids = Object.keys(this.state.selected);
        const { idt } = this.state;
        let countries = {};
        
        ids.forEach((id) => {
            if (idt[id]) {
                const type = idt[id];
                if (typeof(countries[type]) === 'undefined') countries[type] = 1;
                else countries[type]++;
            }
        });
        
        return countries;
    }

    getTypes(data) {
        let types = {};
        let idt = {};
        data.forEach((keyword) => {
            const type = keyword.type.toLowerCase();
            if (Keywords.Icons.Exists(type) && type.length > 0) {
                keyword.calculated_type = keyword.type;
                idt[keyword.id] = keyword.type;
                if ( typeof(types[type]) === 'undefined' ) types[type] = {
                    key: type,
                    icon: `icon-${type}`,
                    name: keyword.type,
                    title: keyword.type,
                    keywords: [],
                    total: 0
                };
                types[type].keywords.push(keyword);
                types[type].total++;
                if (this.state.selectedCountries[keyword.type]) {
                    types[type].name = `${keyword.type} (${this.state.selectedCountries[keyword.type]} / ${types[type].keywords.length})`;
                } else {
                    types[type].name = `${keyword.type} (${types[type].keywords.length})`;
                }
            }
        });
        return {
            types: types,
            idt: idt
        }
    }

    getKeywords() {
        API.returnGet(`ResearchKeywords/getAllGroupsAllKeywords/?output=json`, true).then((result) => {
            const types = this.getTypes(result.data.Data)
            this.setState({
                keywords: result.data.Data,
                types: types.types,
                idt: types.idt,
                selectedKey: Object.keys(types)[0], 
                listKeywords: types[Object.keys(types)[0]].keywords,
                loaded: true
            }, () => {
                this.setState({
                    selectedCountries: this.state.getSelectedCountries()
                })
            })
            
        });
    }
    
    onLinkClick(event, item) {
        let keywords = this.state.types[item.key].keywords;
        if (this.state.Filter.length > 0) {
            keywords = keywords.filter((keyword) => (keyword.title.toLowerCase().indexOf(this.state.Filter.toLowerCase())>-1));
        }
        this.setState({
            listKeywords: []
        }, () => {
            this.setState({
                selectedKey: item.key,
                listKeywords: keywords
            })
        });
    }

    renderKeyword(keyword) {
        return (
            <Keywords.Inline id={keyword.id} data={keyword} checkbox onCheckboxClick={this.onCheckboxClick} hideIcon checkboxChecked={this.state.selected[keyword.id] ? true : false}  />
        )
    }
    
    componentDidMount() {
        this.getKeywords();
    }
    
    getLinks() {
        if (this.state.Filter.length === 0) return Object.values(this.state.types).map((type) => {
            if (this.state.selectedCountries[type.title]) {
                type.name = (<strong style={{color: 'var(--blue-light)'}}>{`${type.title} (${this.state.selectedCountries[type.title]} / ${type.total})`}</strong>);
            }
            else type.name = `${type.title} (${type.total})`;

            return type;
        })
        
        const types = this.getTypes(this.state.keywords.filter((keyword) => (keyword.title.toLowerCase().indexOf(this.state.Filter.toLowerCase())>-1))).types;
         
        return Object.values(types);
    }

    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (!isChecked) delete selected[id];
        else selected[id] = title;
        let listKeywords = this.state.listKeywords.concat({});
        listKeywords.pop()
        this.setState({
            selectedCountries: this.getSelectedCountries(selected)
        }, ()=> {
            this.setState({
                listKeywords: listKeywords    
            }, () => {
                this.setState({
                    selected: selected        
                })
            });
        });
        return this.props.onFilter ? this.props.onFilter('keywords', selected) : null;
    }

    render() {
        const { listKeywords } = this.state;
        
        if (this.state.loaded === false) return <Loader.Inline />
        else {
            return (
                <div style={{
                   display: 'flex',
                   flexDirection: 'row',
                   width: '100%',
                   height: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 0
                    }}>
                        <Nav
                            className={'SubNav'}
                            onLinkClick={this.onLinkClick}
                            selectedKey={this.state.selectedKey}
                            groups={[
                                {
                                    links: this.getLinks()
                                }
                            ]}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        overflowY: 'auto'
                    }} data-is-scrollable={'true'}>
                    <List 
                        items={listKeywords}
                        onRenderCell={this.renderKeyword} 
                        className={'List-FullWidth'}
                    />
                    </div>
                </div>
            )
        }
    }
    
}

export default connect(mapStateToProps)(KeywordsFilterForm);