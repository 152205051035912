import { Cache } from 'helpers';

function categoryReducer(state={countries:{}}, action) {
    
    switch (action.type) {
        
        case 'R_GET_CATEGORIES':
            return state;
        
        case 'R_FILL_CATEGORIES_PENDING':
            return state;
        
        case 'R_FILL_CATEGORIES_FAILED':
            return state;
        
        case 'R_FILL_CATEGORIES_FULFILLED':
            let x = {};
            action.payload.data.Data.forEach((c) => {
                x[c.id] = c;
            })
            Cache.Save('R_FILL_CATEGORIES', x, 300 * 1000);
            return {
                categories: x
            }
            
        default:
            return state;
        
    }
    
}

export default categoryReducer;