import React from 'react';
import Loader from 'components/Loader';
import Keywords from 'components/Keywords';
import API from 'actions/api';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            keywords: [],
            loaded: false,
            id: props.id
        }
        
        this.getKeywords = this.getKeywords.bind(this);
    }
    
    getKeywords() {
        API.returnGet(`Intelligence/getKeywords/${this.state.id}?output=json`).then((result) => {
            this.setState({
                keywords: result.data.Data,
                loaded: true
            })
        });
    }
    
    componentDidMount() {
        this.getKeywords();
    }
    
    render() {
        if (this.state.loaded === false) return <Loader.Inline />
        else return (
            <div className={'KeywordsInline'}>
                {this.state.keywords.map((x) => (
                    <Keywords.Inline id={x.id} data={x} key={x.id} />
                ))}
            </div>
        )
    }
    
}