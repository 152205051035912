import React from 'react';
import { mapStateToProps, connect } from '../../containers/AssignmentsContainer';
import Navigation from 'components/Navigation';
import actionAssignments from 'actions/assignments';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import { Stack} from 'office-ui-fabric-react/lib/Stack';
import { List } from 'office-ui-fabric-react/lib/List';
import Assignments from 'components/Assignments'


class AssignmentsList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            assignments: [],
            filteredAssignments: [],
            doFilter: false,
            assignment: {
                id: '0'
            },
            displaySidebar: false
        };
        
    };
    
    static getDerivedStateFromProps = (props, state) => {
        let res = {};
        if (typeof props.assignments !== 'undefined') res.assignments = props.assignments;
        
        if (typeof state.assignments !=='undefined' && state.assignments.length>0 && typeof props.Search !== 'undefined' && typeof props.Search.text !=='undefined' && props.Search.text.length>1) {
            res.filteredAssignments = state.assignments.filter((a) => {
                if (typeof a.name !== 'undefined' && a.name !== null) {
                    if (a.name.toLowerCase().indexOf(props.Search.text.toLowerCase()) > -1) return true;
                }
                if (typeof a.intro !== 'undefined' && a.intro !== null) {
                    if (a.intro.toLowerCase().indexOf(props.Search.text.toLowerCase()) > -1) return true;
                }
                if (typeof a.info !== 'undefined' && a.info !== null) {
                    if (a.info.toLowerCase().indexOf(props.Search.text.toLowerCase()) > -1) return true;
                }
                return false;
            });
            res.doFilter = true;
        }
        if (typeof props.Search !== 'undefined' && props.Search.text.length<2) res.doFilter = false;
        
        return res;
    }
    
    getAssignments() {
        actionAssignments.List(this.props.dispatch);
    }
    
    componentDidMount() {
        this.getAssignments();
    }
    
    onRenderDetailsHeader(props, defaultRender) {
      return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {defaultRender({
            ...props
          })}
        </Sticky>
      );
    }
    
    setAssignment(assignment) {
        this.setState({
            assignment: assignment,
            displaySidebar: true
        })
    }
    
    onRenderRow(row) {
        return (
            <Assignments.SingleAssignmentPreviewSmall assignment={row} onClick={this.setAssignment.bind(this, row)} />
        )
    }
    
    render() {
        const { assignments, filteredAssignments, doFilter } = this.state;
        const displayAssignments = doFilter ? filteredAssignments : assignments;
        return (
            <div>
                <Navigation.SidebarLeft>
                    <h1>Assignments options</h1>
                </Navigation.SidebarLeft>
                <Stack horizontal styles={{root: { overflow: 'auto', display: 'flex' }}}>
                    <Stack.Item grow={1} styles={{root: {display: 'flex', 'overflow-x': 'hidden', 'overflow-y': 'auto', height: 'calc(100vh - 88px)', background: 'white'}}} className='TableStack'>
                        <List style={{width: '100%'}} items={displayAssignments} onRenderCell={this.onRenderRow.bind(this)} />
                    </Stack.Item>
                    { this.state.displaySidebar ? (
                    <Stack.Item disableShrink styles={{root: {display: 'flex', width: '30%', background: 'white', overflow: 'auto', height: 'calc(100vh - 88px)'}}}>
                        <Assignments.SingleAssignmentPreviewBig assignment={this.state.assignment} key={`assignmentPreview${this.state.assignment.id}`}/>
                    </Stack.Item>
                    ) : null }
                </Stack>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(AssignmentsList);