import React from 'react';
import AssignmentsList from './AssignmentsList';
import SingleAssignmentPreviewBig from './SingleAssignmentPreviewBig';
import SingleAssignmentPreviewSmall from './SingleAssignmentPreviewSmall';
import AssignmentEdit from './SingleAssignmentEdit'
import AssignmentSources from './SingleAssignmentSources'
import New from './AssignmentNew'
import SmallList from './AssignmentsSmallList'
import AssignmentInfo from './SingleAssignmentInfo';

class Assignments extends React.Component {
    
    render() {
        return (<AssignmentsList />)
    }
    
}

export default { 
    Assignments, 
    AssignmentsList, 
    SingleAssignmentPreviewBig, 
    SingleAssignmentPreviewSmall,
    AssignmentEdit,
    AssignmentSources,
    SmallList,
    AssignmentInfo,
    New
}

export { 
    Assignments, 
    AssignmentsList, 
    SingleAssignmentPreviewBig, 
    SingleAssignmentPreviewSmall,
    AssignmentEdit,
    AssignmentSources,
    SmallList,
    AssignmentInfo,
    New
}