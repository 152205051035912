import React from 'react';
import { mapStateToProps, connect } from 'containers/LanguageContainer';
import { Language } from 'helpers/';
import LanguageInline from './LanguageInline'
import Loader from 'components/Loader';

class LanguageFilterForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            languageList: [],
            Filter: '',
            loaded: false,
            selected: {}
        }

        Language.Load(this.props.dispatch);
        Language.Get(props.dispatch);
        
        this.name = this.name.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            (
                props.languageList &&
                props.languageList.length &&
                state.languageList.length === 0
            ) || props.getFilterData
        ) {
            return {
                languageList: props.languageList ? props.languageList : [],
                loaded: props.languageList && props.languageList.length > 0,
                Filter: props.Filter ? props.Filter : state.Filter,
                selected: props.getFilterData ? props.getFilterData('language') : state.selected
            }
        } 
        
        if (props.Filter !== state.Filter) return {
            Filter: props.Filter
        }
        
        return null;
    }
    
    name(n) {
        n = n.split('- ');
        return n.length === 1 ? n[0] : n[1];
    }
    
    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (!isChecked) delete selected[id];
        else selected[id] = title;
        this.setState({
            selected: selected
        })
        return this.props.onFilter ? this.props.onFilter('language', selected) : null;
    }
    
    render() {
        return this.state.loaded ? (<>
            {(
                this.state.languageList.map((c) => (
                    <LanguageInline key={c.id} id={c.id} onCheckboxClick={this.onCheckboxClick} checkbox styles={{
                        wrapper: {
                            display: 'inline-block',
                            width: '25%',
                            paddingRight: 10,
                            paddingBottom: 10,
                            boxSizing: 'border-box',
                            overflow: 'hidden'
                        }
                    }} displayCondition={this.state.Filter} checkboxChecked={this.state.selected[c.id] ? true : false} />
                ))
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(LanguageFilterForm);