import React from 'react';
import { geolocated } from "react-geolocated";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import Geocode from "react-geocode";
import axios from 'axios';
import Loader from 'components/Loader'
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';

class LocationMap extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            lat: 51.505,
            lng: -0.09,
            zoom: 13,
            result: false,
            resultsOSM: [],
            resultsGoogle: [],
            index: 0,
            loadingOSM: false,
            loadingGoogle: false,
            mapType: 'street'
        }
        
        this.term = '';
    
        this.renderResults = this.renderResults.bind(this);
        this.renderResultsOSM = this.renderResultsOSM.bind(this);
        this.renderResultsGoogle = this.renderResultsGoogle.bind(this);
        this.SearchOSM = this.SearchOSM.bind(this);
        this.SearchGoogle = this.SearchGoogle.bind(this);
        this.Clear = this.Clear.bind(this);
        this.mapTypeChange = this.mapTypeChange.bind(this);
        this.onSelectLocation = this.onSelectLocation.bind(this);
    }
    
    mapTypeChange(ev, option) {
        this.setState({
            mapType: option.key
        })
    }
    
    onSelectLocation(data) {
        if (this.props.onSelectLocation) this.props.onSelectLocation(data);
    }
    
    SearchOSM(term) {
        this.term = term;
        this.setState({
            loadingOSM: true,
            loadingGoogle: true
        }, () => {
            axios.get(`https://nominatim.openstreetmap.org/search/${encodeURIComponent(term)}?format=json`, false).then((result) => {
                this.setState({
                    resultsOSM: result.data,
                    lat: result.data.length > 0 ? result.data[0].lat : 44,
                    lng: result.data.length > 0 ? result.data[0].lon : 17,
                    index: 0,
                    result: true,
                    loadingOSM: false
                });
                this.SearchGoogle(term);
            });    
        });
    }
    
    SearchGoogle(term) {
        this.term = term;
        this.setState({
            loadingGoogle: true
        }, () => {
            Geocode.fromAddress(term, 'AIzaSyDc7w0CRn6ewVaTMST1bt-mUOAgGeBJEyY').then(
                (response) => {
                    this.setState({
                        resultsGoogle: response.results.map((res) => ({
                            place_id: res.place_id,
                            type: res.types[0],
                            display_name: res.formatted_address,
                            lat: res.geometry.location.lat,
                            lon: res.geometry.location.lng
                        })),
                        loadingGoogle: false,
                        result: true
                    },() => {
                        //
                    })
                },
                (error) => {
                    this.setState({
                        loading: false,
                        results: [],
                        result: true
                    })
                }
            );
        })
    }
    
    renderTitle(icon, item) {
        return (
            <>
                <i className={icon} style={{paddingRight: 5}} />
                {item.headerText}{(item.itemCount && item.itemCount>0 ? ` (${item.itemCount})` : '')}
            </>
        )
    }
    
    renderResults() {
        return (
            <div style={{ display: 'flex', flexGrow: 1}}>
                <Pivot>
                    <PivotItem headerText="OSM" itemCount={this.state.resultsOSM.length} onRenderItemLink={this.renderTitle.bind(this, 'fad fa-map-marked-alt')}>
                        <div style={{height: 'calc(100vh - 185px)', overflowX: 'hidden', overflowY: 'auto'}}>
                            {this.state.result && this.state.resultsOSM.length === 0 && !this.state.loadingOSM ? <h3>{'No results found'}</h3> : this.renderResultsOSM()}
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Google" itemCount={this.state.resultsGoogle.length} onRenderItemLink={this.renderTitle.bind(this, 'fab fa-google')}>
                        <div style={{height: 'calc(100vh - 185px)', overflowX: 'hidden', overflowY: 'auto'}}>
                            {this.state.result && this.state.resultsGoogle.length === 0 && !this.state.loadingGoogle ? <h3>{'No results found'}</h3> : this.renderResultsGoogle()}
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
        );
    }
    
    renderResultsOSM() {
        return this.state.loadingOSM ? <div style={{margin: 20}}><Loader.Circle /></div> : (
            <ol>
                {this.state.resultsOSM.map((r, index) => (
                    <li 
                        key={r.place_id} 
                        onClick={this.showLocation.bind(this, r, index, 'OSM')}
                        style={{
                            padding: 10,
                            cursor: 'pointer',
                            fontWeight: (this.state.index === index && this.state.clickType==='OSM') ? 'bold' : 'normal'
                        }}
                    >
                        { (r.icon ? <img alt={''} title={r.type} style={{marginRight: 10}} src={r.icon} /> : null) }
                        {r.display_name}
                    </li>
                ))}
            </ol>
        )
    }
    
    renderResultsGoogle() {
        return this.state.loadingGoogle ? <div style={{margin: 20}}><Loader.Circle /></div> : (
            <ol>
                {this.state.resultsGoogle.map((r, index) => (
                    <li 
                        key={r.place_id} 
                        onClick={this.showLocation.bind(this, r, index, 'Google')}
                        style={{
                            padding: 10,
                            cursor: 'pointer',
                            fontWeight: (this.state.index === index && this.state.clickType==='Google') ? 'bold' : 'normal'
                        }}
                    >
                        { (r.icon ? <img alt={''} title={r.type} style={{marginRight: 10}} src={r.icon} /> : null) }
                        {r.display_name}<br /><small>{r.type}</small>
                    </li>
                ))}
            </ol>
        )
    }
    
    showLocation(location, index, type) {
        this.onSelectLocation({...location, term: this.term});
        this.setState({
            result: true,
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lon),
            index: index,
            clickType: type
        })
    }
    
    Clear() {
        this.setState({
            result: false,
            index: 0,
            resultsOSM: [],
            resultsGoogle: []
        })
    }
    
    render() {
        let position = [this.state.lat, this.state.lng]
        if (
            !this.state.result &&
            this.props.isGeolocationAvailable && 
            this.props.isGeolocationEnabled &&
            this.props.coords
        ) {
            position = [
                this.props.coords.latitude,
                this.props.coords.longitude
            ];
        }
        
        return (
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 300,
                        flexGrow: 0,
                        flexShrink: 0,
                        padding: 20
                    }}>
                        <SearchBox
                            placeholder="Search"
                            onSearch={this.SearchOSM}
                            onClear={this.Clear}
                          />
                          {this.renderResults()}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        height: '100%',
                        background: 'blue'
                    }}>
                        <Map center={position} zoom={this.state.zoom}>
                            {(this.state.mapType === 'satellite' || this.state.mapType === 'hybrid' ? <TileLayer 
                                    attribution={null}
                                    maxNativeZoom={17}
                                    url={'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'}
                                /> : null )}
                            {(this.state.mapType === 'street' || this.state.mapType === 'hybrid' ? <TileLayer 
                                    attribution={null}
                                    opacity={(this.state.mapType === 'hybrid' ? 0.5 : 1)}
                                    url={'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'}
                                /> : null )}
                            <Marker position={position}>
                                <Popup>
                                    {JSON.stringify(this.props)}
                                </Popup>
                            </Marker>
                        </Map>
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    top: 25,
                    right: 25,
                    background: 'white',
                    padding: 20,
                    width: 150,
                    zIndex: 10001,
                    height: 'auto'
                }}>
                    <ChoiceGroup
                        defaultSelectedKey="street"
                        options={[
                            {
                                key: 'street',
                                text: 'Street'
                            },
                            {
                                key: 'satellite',
                                text: 'Satellite'
                            },
                            {
                                key: 'hybrid',
                                text: 'Hybrid',
                            }
                        ]}
                        label="Map type"
                        onChange={this.mapTypeChange}
                    />
                </div>
            </>
        )
        
    }
    
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
        maximumAge: 300,
        timeout: Infinity
    },
    userDecisionTimeout: 5000,
    watchPosition: true
})(LocationMap);