import * as React from 'react';
import { mapStateToProps, connect } from 'containers/MenuContainer';
import { withRouter } from 'react-router-dom';

import Menu from 'helpers/Navigation/menu.js'
//import { UserMenuButton } from 'components/User';
import Navigation from 'actions/navigation';
import { MenuButton as SourcesMenuButton } from 'components/Sources';

import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';

class MenuTop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            logoutDialog: false,
            forceRender: 0
        }

        this.state.navigateTo = this.navigateTo.bind(this);

    }
    
    render() {
        
        return this.props.User.loggedIn ?
            (
              <CommandBar items={this.getItems()} farItems={this.getFarItems()} className={'navigationBlue'} styles={
                {
                  root:{
                      marginLeft: 0, 
                      paddingLeft: 0, 
                      marginRight: 0, 
                      paddingRight: 0
                  }
                }
              } />
            ) : '';
    }

    getItems = () => {
        return [
            /*{
                key: 'sidebar',
                iconProps: {
                    iconName: 'GlobalNavButton'
                },
                iconOnly: true,
                onClick: () => { this.props.dispatch(Navigation.toggleLeftSidebar()) }
            },*/
            ...this.state.mainMenu
        ]
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = { ...state, mainMenu: [] }
        
        if (typeof(state.User)=='undefined' || props.User.loggedIn !== state.User.loggedIn) newState.User = props.User;
        
        
        if (typeof(props.User.userData.permissions) != 'undefined') {
            newState.permissions = props.User.userData.permissions;
            newState.mainMenu = Menu(props.User.userData.permissions, state.navigateTo);
        }
        
        return newState;

    }
    
    navigateTo = (page) => {
        this.props.dispatch(Navigation.changePage());
        this.props.history.push(`/${page}`);
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    getFarItems = () => {
        return [{
            key: 'drafts',
            commandBarButtonAs: () => <SourcesMenuButton />
        }];
    };
}

export default connect(mapStateToProps)(withRouter(MenuTop));