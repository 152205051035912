import React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            show: props.show ? true : false,
            title: props.title ? props.title : 'POPUP',
            Filter: '',
            showFilter: false,
            titleOK: props.titleOK ? props.titleOK : 'OK',
            titleCancel: props.titleCancel ? props.titleCancel : 'Cancel'
        }

        this.renderTitle = this.renderTitle.bind(this);
        this.functionOK = this.functionOK.bind(this);
        this.functionCancel = this.functionCancel.bind(this);
    }
    
    functionOK() {
        this.setState({
            show: false
        }, () => {
            if (this.props.functionOK) this.props.functionOK();
        })
    }
    
    functionCancel() {
        this.setState({
            show: false
        }, () => {
            if (this.props.functionCancel) this.props.functionCancel();
        })
    }
    
    renderTitle() {
        return ( this.state.title && this.state.title.length ) || this.state.showFilter ? (
            <span style={{
                display: 'flex',
                width: '100%'
            }}>
                {this.state.title && this.state.title.length ? (<span style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                {this.state.title}
                </span>) : null}
                {this.state.showFilter ? (
                    <span style={{
                        display: 'flex',
                        width: 673,
                        flexGrow: 0,
                        flexShrink: 0,
                        textAlign: 'right'
                    }}>
                        <SearchBox
                            placeholder={'Filter'}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            value={this.state.Filter}
                            onChanged={this.filterFilter}
                            onSearch={this.filterFilter}
                            onClear={this.clearFilter}
                            onEscape={this.clearFilter}
                        />
                    </span>
                ) : null }
            </span>
        ) : null
    }
    
    static getDerivedStateFromProps = (props, state) => {
         return {}
    }
    
    render() {

        return (
            <Dialog
                hidden={!this.state.show}
                shiftOnReduce={true}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: this.renderTitle(),
                  styles: {
                    title: {
                        paddingRight: 24,
                        paddingTop: 24,
                        paddingBottom: 0
                    }  
                  }
                }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  className: 'PageOverlay',
                  styles: { 
                    root: {
                        top: 0,
                        zIndex: 9999
                    },
                    main: { 
                        maxWidth: 'calc(100vw - 20px) !important',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    },
                    scrollableContent: {
                        maxWidth: 'calc(100vw - 20px)',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    }
                }}}
            >
                {this.props.children}
                <DialogFooter>
                  <PrimaryButton onClick={this.functionOK} text={this.state.titleOK} />
                  <DefaultButton onClick={this.functionCancel} text={this.state.titleCancel} />
                </DialogFooter>
            </Dialog>
        )
    }
    
}