import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';

import Navigation from 'components/Navigation';
import Projects from 'components';
import User from 'components/User';

import API from 'actions/api';
import { mapStateToProps, connect } from 'containers/FormContainer';

var moment = require('moment');

class ProjectList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: ''
            },
            columns: [
                {
                    key: 'id',
                    name: 'Project',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.title}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                },
                {
                    key: 'description',
                    name: 'Description',
                    fieldName: 'description'
                },
                {
                    key: 'user',
                    name: 'User',
                    onRender: (item) => (<User.Inline id={item.user_id} />)
                },
                {
                    key: 'datetime',
                    name: 'Created',
                    onRender: (item) => moment(item.insert_datetime).format('DD.MM.YYYY')
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                title: ''
            }
        })
    }
    
    onFormSave(form) {
        this.onHide();
        this.props.dispatch(API.PostForm(`Project/edit/${this.state.selectedItem.id}`, form));
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Project/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide} onSave={this.onFormSave} initialData={this.state.selectedItem}>
                    <Projects.ProjectSingle data={this.state.selectedItem} />
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(ProjectList);