import React from 'react';
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { mapStateToProps, connect } from 'containers/DefaultContainer'

class UserMenuButton extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            render: false,
            Realtime: {
                status: 'offline'
            }
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        
        let newState = { ...prevState, render: false }
        
        if (typeof nextProps.User !== 'undefined') {
            newState.User = {
                text: nextProps.User.userData.name,
                imageUrl: 'https://bl.m.etleboro.org/APP/img/UserIcon.php?X_API_KEY=' + window.localStorage.getItem('AGENZIA-API-KEY') + '&uid=' + nextProps.User.userData.id
            };
            newState.render = true;
        }
        
        if (typeof nextProps.Realtime !== 'undefined') {
            newState.Realtime = nextProps.Realtime;
        } 

        return newState;
        
    }
    
    render() {
        return this.state.render ? (
            <CommandBarButton onClick={this.props.onClick} menuProps={this.props.menu}>
                <Persona {...this.state.User} size={PersonaSize.size24} presence={this.state.Realtime.status === 'online' ? PersonaPresence.online : PersonaPresence.offline} hidePersonaDetails={false} />
            </CommandBarButton>
        ) : '';
    }
    
}

export default connect(mapStateToProps)(UserMenuButton);