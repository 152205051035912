import React from 'react';
import { connect } from 'react-redux';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { DefaultButton } from 'office-ui-fabric-react';

class UploadSingleSmall extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            upload: props.upload ? props.upload : {},
            actionButton: null,
            lastUpdate: 0
        }
    }
    
    static getDerivedStateFromProps = (props, state) => { 
        return props;
    }
    
    render () {
        const { upload } = this.state;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}>
                {(
                    this.state.upload.type && this.state.upload.type.substring(0, 5) === 'image' ? 
                    (<div style={{
                        display: 'flex',
                        width: 100,
                        boxSizing: 'border-box',
                        paddingRight: 10
                    }}><img alt={''} src={this.state.upload.fileData} style={{
                        maxWidth: '100%'
                    }} /></div>) : null
                )}
                {(
                    this.state.upload && this.state.upload.image ? 
                    (<div style={{
                        display: 'flex',
                        width: 100,
                        boxSizing: 'border-box',
                        paddingRight: 10
                    }}><im alt={''}g src={this.state.upload.image} style={{
                        maxWidth: '100%'
                    }} /></div>) : null
                )}
                <div style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                    { upload.progress < 100 || !this.state.actionButton ? (
                        <ProgressIndicator
                            label={upload.title} 
                            description={upload.shimmer ? 'Loading...' : (upload.progress+'%')} 
                            percentComplete={upload.shimmer ? null : upload.progress/100} 
                            styles={{
                                root: {
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'column'
                                },
                                itemDescription: { 
                                    textAlign: upload.shimmer ? 'center' : 'right'
                                },
                                itemName: {
                                    maxWidth: 245
                                }
                            }} 
                        />
                        ) : (<div style={{ margin: 'auto' }}><DefaultButton text={this.state.actionButton.title} onClick={this.state.actionButton.onClick.bind(this, upload)} /></div>)
                    }
                </div>
            </div>
        );
    }
}

export default connect((state, ownProps) => {
    let ret = {};

    if (state.upload.smallActionButton) {
        ret['actionButton'] = state.upload.smallActionButton
        ret['lastUpdate'] = new Date().getTime();
    } else {
        ret['actionButton'] = null;
        ret['lastUpdate'] = new Date().getTime();
    }
    
    if (state.upload.uploads[ownProps.upload.id]) {
        ret['upload'] = state.upload.uploads[ownProps.upload.id];
        ret['lastUpdate'] = new Date().getTime();
    }
    
    return ret;
})(UploadSingleSmall);