import React from 'react';
import { mapStateToProps, connect } from 'containers/CategoryContainer';
import { Category } from 'helpers/';
import CategoryInline from './CategoryInline'
import Loader from 'components/Loader';

class CategoryFilterForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            categoryList: [],
            Filter: '',
            loaded: false,
            selected: {}
        }

        Category.Load(this.props.dispatch);
        Category.Get(props.dispatch);
        
        this.name = this.name.bind(this);
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            (
                props.categoryList &&
                props.categoryList.length &&
                state.categoryList.length === 0
            ) || props.getFilterData
        ) {
            return {
                categoryList: props.categoryList ? props.categoryList : [],
                loaded: props.categoryList && props.categoryList.length > 0,
                Filter: props.Filter ? props.Filter : state.Filter,
                selected: props.getFilterData ? props.getFilterData('category') : state.selected
            }
        } 
        
        if (props.Filter !== state.Filter) return {
            Filter: props.Filter
        }
        
        return null;
    }
    
    name(n) {
        n = n.split('- ');
        return n.length === 1 ? n[0] : n[1];
    }
    
    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (!isChecked) delete selected[id];
        else selected[id] = title;
        this.setState({
            selected: selected
        })
        return this.props.onFilter ? this.props.onFilter('category', selected) : null;
    }
    
    render() {

        return this.state.loaded ? (<>
            {(
                this.state.categoryList.map((c) => (
                    <CategoryInline key={c.id} id={c.id} onCheckboxClick={this.onCheckboxClick} checkbox styles={{
                            wrapper: {
                                display: 'inline-block',
                                width: '25%',
                                paddingRight: 10,
                                paddingBottom: 10,
                                boxSizing: 'border-box',
                                overflow: 'hidden'
                            }   
                        }} displayCondition={this.state.Filter} checkboxChecked={this.state.selected[c.id] ? true : false} />
                ))
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(CategoryFilterForm);