import React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { mapStateToProps, connect } from '../../containers/MainContainer';
import Navigation from 'actions/navigation';

class SidebarRight extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            form: this.props.initialData ? { ...this.props.initialData } : {},
            children: [],
            setForm: this.setForm.bind(this)
        }
        
        this.onHide = this.onHide.bind(this);
        this.onSave = this.onSave.bind(this);
        this.setForm = this.setForm.bind(this);
        this.FooterContent = this.FooterContent.bind(this);
    }
    
    static getDerivedStateFromProps(props, state) {
        let tmpArray =  React.Children.toArray(props.children);
        let children = [];
        tmpArray.forEach((a) => {
            children.push(React.cloneElement(a, { ...a.props, setForm: state.setForm }));
        });
        tmpArray = null;
        
        let r = {
            children: children
        }
        
        if (typeof(props.initialData) !== 'undefined') {
            r['form'] = {
                ...state.form,
                ...props.initialData
            }
            if (typeof(props.initialData.title)) r['form']['name'] = props.initialData.title;
        }
        
        return r;
        
        
    }
    
    FooterContent() {
        return (
            <div style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row'
            }}>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row'
                }}>
                    {(
                        (typeof(this.props.hideSave)==='undefined' || this.props.hideSave===false) ?
                        <PrimaryButton onClick={this.onSave} style={{ marginRight: '8px' }}><i className={'fal fa-check'} />&nbsp;Save</PrimaryButton> :
                        ''
                    )}
                    <DefaultButton onClick={this.onHide}><i className={'fad fa-times-circle'} />&nbsp;Close</DefaultButton>
                </div>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row',
                    alignItems: 'right',
                    alignContent: 'right'
                }}>
                {this.props.AdditionalButtons ? this.props.AdditionalButtons() : null}
                </div>
            </div>
        )
    }
    
    setForm(form) {
        this.setState({
            form: {
                ...this.state.form,
                ...form
            }
        });
    }
    
    onSave() {
        this.props.onSave(this.state.form);
    }
    
    onHide() {
        if (typeof(this.props.confirm) === 'undefined' || this.props.confirm===true) {
            this.props.dispatch(Navigation.showConfirmation({
                title: 'Close?',
                text: 'Close and loose all changes?',
                titleOK: 'OK',
                titleCancel: 'Cancel',
                functionOK: () => { 
                    this.props.onHide() 
                },
                functionCancel: () => { 
                }
            }));
        } else this.props.onHide();
    }
    
    render() {
        return (
            <div>
                <Panel
                    isOpen={this.props.open ? this.props.open : false}
                    onLightDismissClick={this.onHide}
                    type={PanelType.custom}
                    customWidth={this.props.width ? this.props.width : '600px'}
                    { ...(this.props.title ? {headerText: this.props.title} : {}) }
                    layerProps={{hostId: 'MainLayerHost'}}
                    hasCloseButton={false}
                    isLightDismiss={true}
                    isFooterAtBottom={true}
                    onRenderFooterContent={this.FooterContent}
                >
                    {this.state.children}
                </Panel>
          </div>
        )
        
    }
    
}

export default connect(mapStateToProps)(SidebarRight);