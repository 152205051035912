import React from 'react';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { connect } from 'react-redux';
import Navigation from 'actions/navigation';
import Assignments from 'components/Assignments';

class NotificationButton extends React.Component {

    render() {
        return (
            <CommandBarButton onClick={() => {
                this.props.dispatch(Navigation.showPopup({
                    title: 'Create new assignment',
                    children: <Assignments.New />,
                    showSearch: false
                }));
            }}>
                <i className={'fas fa-bell'} />
            </CommandBarButton>
        );
    }
    
}

export default connect()(NotificationButton);