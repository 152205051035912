import React from 'react';
import { mapStateToProps, connect } from 'containers/RobotLinksContainer';
import Country from 'components/Country';
import { RobotLinks } from 'helpers/';

class RobotLinksInline extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            link: null
        }

        RobotLinks.Load(props.dispatch);
        this.country = this.country.bind(this);
        this.title = this.title.bind(this);
        this.separator = this.separator.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            state.link === null &&
            props.link &&
            props.link.id
        ) {
            return {
                link: props.link
            }
        } 
        
        return null;
    }

    country() {
        if (
            !this.props.hideCountry ||
            this.props.hideCountry === false
        ) return <Country.Inline id={this.state.link.country} reverse={!this.props.Flag || this.props.Flag==='right'} />
        else return '';
    }
    
    separator() {
        if (
            (
                !this.props.hideCountry ||
                this.props.hideCountry === false
            ) &&
            (
                !this.props.hideTitle ||
                this.props.hideTitle === false
            )
        ) return <>&nbsp;|&nbsp;</>
        else return '';
    }
    
    title() {
        if (
            !this.props.hideTitle ||
            this.props.hideTitle === false
        ) return <span className={'MediaTitle'} title={this.state.link.title} style={{whiteSpace: 'nowrap', flexShrink: 0, width: 'auto'}}>{this.state.link.publish_title}</span>
        else return '';
    }
    
    render() {
        return (
            this.state.link && this.state.link.id ? 
            (
                <div title={this.state.link.title} style={{display: 'inline-flex', alignItems: 'baseline', whiteSpace: 'nowrap', flexShrink: 0, width: 'auto'}}>
                    {this.country()}
                    {this.separator()}
                    {this.title()}
                </div>
            ) : null
        )
    }
    
}

export default connect(mapStateToProps)(RobotLinksInline);