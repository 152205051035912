import React from 'react';

import Data from 'actions/data';
import { mapStateToProps, connect } from 'containers/APIContainer';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/Loader';
import { Utilities } from 'helpers/';

class TableList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            data: [],
            doFilter: false,
            filter: '',
            APIModule: this.props.APIModule
        };
        
        this.getData = this.getData.bind(this);
        this.state.getData = this.getData.bind(this);
        this.Click = this.Click.bind(this);
    };

    componentDidMount() {
        this.getData();
    }
    
    /* componentDidUpdate(prevProps, prevState) {
      Object.entries(this.props).forEach(([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed`)
      );
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    } */

    static getDerivedStateFromProps = (props, state) => {
        if (
            props.RELOAD_DATA &&
            props.RELOAD_DATA === true
        ) {
            state.getData();
            return {
                data: []
            }
        }
        let res = null;
        if (
            typeof props.data !== 'undefined' &&
            typeof props.data[state.APIModule] !== 'undefined'
        ) {
            if (props.infinite) {
                res = {
                    data: Utilities.arrayUnique(props.data[state.APIModule].concat(state.data))
                }
            } else {
                res = {
                    data: props.data[state.APIModule]
                }
            }
        }

        return res;
    }
    
    getData() {
        window.requestAnimationFrame(() => {
            Data.List(this.props.dispatch, {
                type: this.props.PathOverride ? this.props.PathOverride : this.props.APIModule,
                start: this.state.data.length,
                q: this.state.filter
            });
        });
    }

    onRenderDetailsHeader(props, defaultRender) {
        return (
            <div className={'fixedTop'}>
                {defaultRender({
                    ...props
                })}
            </div>
        );
    }

    Click(item) {
        return this.props.onClick && this.props.onClick(item, this.state.data);
    }

    render() {
        if (this.state.data.length === 0) return <Loader.Big />;
        return (
            <div className={'TableDetailsListScroll'} id={'TableDetailsListScroll'} data-is-scrollable={'true'}>
                <InfiniteScroll
                    dataLength={this.state.data.length}
                    next={this.props.infinite ? this.getData : () => ([])}
                    hasMore={this.props.infinite ? this.props.infinite : false}
                    loader={<Spinner label="Loading..." ariaLive="assertive" labelPosition="right" />}
                    scrollableTarget={'TableDetailsListScroll'}
                    className={'HideScroll'}
                >
                    <DetailsList
                        items={this.state.data}
                        columns={this.props.columns}
                        selectionMode={SelectionMode.multiple}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selectionPreservedOnEmptyClick={false}
                        enterModalSelectionOnTouch={true}
                        data-is-scrollable={'true'}
                        className={'TableList'}
                        onRenderDetailsHeader={this.onRenderDetailsHeader}
                        enableUpdateAnimations={false}
                        onActiveItemChanged={this.Click}
                    />
                </InfiniteScroll>
            </div>
        )
    }
    
}

export default connect(mapStateToProps(null))(TableList);