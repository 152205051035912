import React from 'react';

import Intelligence from 'components/Intelligence';
import Media from 'components/Media';
import { Cache } from 'helpers';

import { mapStateToProps, connect } from 'containers/DraftsContainer';

class SourcesAdd extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            sources: [],
            children: [],
            addSource: this.addSource.bind(this),
            onSourceClick: this.onSourceClick.bind(this),
            preview: false,
            addSlide: props.addSlide ? props.addSlide : false
        }
        
        this.sourcePreview = this.sourcePreview.bind(this);
        this.renderSources = this.renderSources.bind(this);
        this.removeSource = this.removeSource.bind(this);
    }
    
    static getDerivedStateFromProps(props, state) {
        if (state.children.length === 0) {
            let tmpArray =  React.Children.toArray(props.children);
            let children = [];
            tmpArray.forEach((a) => {
                let props = { ...a.props, addSource: state.addSource, onClick: state.onSourceClick };
                if (state.addSlide) props['addSlide'] = state.addSlide;
                children.push(React.cloneElement(a, props));
            });
            return {
                children: children,
                sources: props.sources ? props.sources: {}
            }
        } else return {
            sources: props.sources ? props.sources : {}
        }
        
    }
    
    componentDidMount() {
        Cache.Get('DRAFTS_SOURCES').then((data) => {
            if (data.data) {
                this.props.dispatch({
                    type: 'DRAFTS_FILL_SOURCES',
                    payload: {
                        sources: data.data
                    }
                })
            }
        })
    }
    
    addSource(type,data) {
        
    }
    
    onSourceClick(type, data) {
        this.props.dispatch({
            type: 'DRAFTS_SOURCE_ADD',
            payload: {
                type: type,
                source: data
            }
        });
    }
    
    renderSources() {
        const sources = Object.values(this.state.sources).reverse();
        this.props.updateSources ? this.props.updateSources(sources) : void(0);
        return sources.map((source) => {
            const props = {
                source: source,
                key: source.id,
                removeSource: this.removeSource
            }
            switch (source.MediaType) {
                case 'Intelligence':
                    return <Intelligence.SourcePreview {...props} />
                   
                case 'Photo':
                    return <Media.SourcePreview {...props} />
                    
                case 'Scan':
                    return <Media.SourcePreview {...props} />
                
                case 'Video':
                    return <Media.SourcePreview {...props} />
                    
                case 'Document':
                    return <Media.SourcePreview {...props} />
                 
                default:
                    return <h1 key={source.id}>{source.title}</h1>
           }
        });
    }
    
    removeSource(id) {
        if (window.confirm('Remove source?')) {
            let sources = this.state.sources;
            delete sources[id];
            this.setState({
                sources: sources
            }, () => {
                this.props.updateSources ? this.props.updateSources(sources) : void(0);
            });
        }
    }
    
    sourcePreview() {
        if (this.state.preview) {
            
            switch (this.state.sourceType) {
                
                case 'Intelligence':
                    return <Intelligence.ArticlePreview article={this.state.sourceData} smallImage key={this.state.sourceData.id} />
                    
                default:
                    return null;
                
            }
            
        }
    }
    
    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                width: '100%',
                padding: 0,
                margin: 0
            }}>
                <div className={'FullWidth'} style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row'
                }}>
                    <div style={{
                        flexGrow: 1,
                        flexBasis: '50%',
                        overflowY: 'auto',
                        overflowX: 'hidden'
                    }}>
                    {this.state.children}
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexGrow: 0,
                    flexShrink: 0,
                    width: 500,
                    boxSizing: 'border-box',
                    padding: 20,
                    flexDirection: 'column',
                    overflowY: 'auto'
                }}>
                    <div style={{marginBottom: 20}}>
                        <h3 style={{margin: 0}}>{Object.keys(this.state.sources).length>0 ? 'Selected sources: ' + (Object.keys(this.state.sources).length) : 'Select some sources'}</h3>
                    </div>
                    {this.renderSources()}
                </div>
            </div>
        )
        
    }
    
}

export default connect(mapStateToProps)(SourcesAdd);