import React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { Form as MediaForm } from 'components/Media';

export default (props) => {
    
    const { upload } = props;
    if (!upload) return null;
    
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            <div style={{
                display: 'flex',
                flexGrow: 1,
                flexBasis: '30%',
                boxSizing: 'border-box',
                padding: 10
            }}>
                {(
                    upload.type && upload.type.substring(0, 5) === 'image' ? 
                    (<img alt={''} src={upload.fileData} style={{
                        maxWidth: '100%',
                        objectFit: 'contain',
                        margin: 'auto'
                    }} />) : null
                )}
                {(
                    upload && upload.image ? 
                    (<img alt={''} src={upload.image} style={{
                        maxWidth: '100%',
                        objectFit: 'contain',
                        margin: 'auto'
                    }} />) : null
                )}
            </div>
            <div style={{
                display: 'flex',
                flexGrow: 1,
                flexBasis: '50%'
            }}>
                <MediaForm data={upload} />
            </div>
            <div style={{
                display: 'flex',
                flexGrow: 1,
                flexBasis: '20%'
            }}>
                <ProgressIndicator
                    label={upload.title} 
                    description={upload.shimmer ? 'Loading...' : (upload.progress+'%')} 
                    percentComplete={upload.shimmer ? null : upload.progress/100} 
                    styles={{
                        root: {
                            display: 'flex',
                            flexGrow: 1,
                            flexDirection: 'column'
                        },
                        itemDescription: { 
                            textAlign: upload.shimmer ? 'center' : 'right'
                        }
                    }} 
                />
            </div>
        </div>
    )
    
}