import { Cache } from 'helpers';

let defaultState = {
    countries: {}
}
try {
    const data = Cache.Get('R_FILL_COUNTRIES');
    if (data.data) defaultState = {
        countries: data.data
    }
} catch (e) {
    defaultState = {
        countries: {}
    }    
}

function countryReducer(state=defaultState, action) {
    
    switch (action.type) {
        
        case 'R_GET_COUNTRIES':
            return state;
        
        case 'R_FILL_COUNTRIES_PENDING':
            return state;
        
        case 'R_FILL_COUNTRIES_FAILED':
            return state;
        
        case 'R_FILL_COUNTRIES_FULFILLED':
            let x = {};
            action.payload.data.Data.forEach((c) => {
                x[c.id] = c;
            })
            Cache.Save('R_FILL_COUNTRIES', x, 300 * 1000);
            return {
                countries: x
            }
            
        default:
            return state;
        
    }
    
}

export default countryReducer;