import React from 'react';
import {render} from 'react-dom';

import store from './store'
import APP from './components/APP'
import { Provider } from 'react-redux'

//window.store = store;

render(
    <Provider store={store}>
        <APP />
    </Provider>,
    document.getElementById('root')
);