import { LocalDatabase } from 'helpers';

const Cache = {
 
    Get: (id) => {
        return new Promise((resolve, reject) => {
            LocalDatabase
            .Cache
            .where('key')
            .equals(id)
            .toArray().then((i) => {
                if (i.length===0) resolve(false);
                else {
                    try {
                        i = JSON.parse(i[0].data);
                        resolve(i);
                    } catch (e) {
                        reject(e);
                    }
                }
            });
        });

    },
    
    GetIfValid: (id) => {
        return new Promise((resolve, reject) => {
           Cache.Get(id).then((data) => {
                if (data===false) reject(false);
                if (data.validUntil > new Date().getTime()) resolve(data);
                else reject(false);
            }).catch((e) => {
                reject(e);
            })
        });
    },
    
    Delete: (id) => {
        return window.localStorage.removeItem(id);
    },
    
    Save: (id, data, validityPeriod = 60000) => (
        new Promise((resolve, reject) => {
            LocalDatabase.Cache.where('key').equals(id).delete().then((count) => {
                const save = {
                    id: id,
                    validUntil: new Date().getTime() + validityPeriod,
                    data: data
                };
                
                LocalDatabase.Cache.add({
                    key: id,
                    data: JSON.stringify(save)
                }).then((result) => {
                    resolve(result);
                }).catch((e) => {
                    reject(e);
                });
            });
        })
    )
    
}

export default Cache;