import React from 'react';
import { mapStateToProps, connect } from 'containers/LanguageContainer';
import { Language } from 'helpers/';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react';

class LanguageDropdown extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            languageList: [],
            Filter: '',
            loaded: false,
            selected: {},
            options: [],
            selectedItem: false
        }
        
        this.onChange = this.onChange.bind(this);

        Language.Load(this.props.dispatch);
        Language.Get(props.dispatch);
    }
    
    onChange(ev, item) {
        if (this.props.onChange) this.props.onChange(ev, item);
        this.setState({
            selectedItem: item.key
        });
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.languageList &&
            props.languageList.length &&
            state.languageList.length === 0
        ) {
            let options = props.languageList.map((l) => ({
                key: l.shortcode,
                text: l.name
            }))
            return {
                languageList: props.languageList ? props.languageList : [],
                options: props.languageList ? options : [],
                loaded: true
            }
        } 
        
        if (props.selectedItem && state.selectedItem === false) {
            return {
                selectedItem: props.selectedItem
            }
        }

        return null;
    }

    render() {
        return <Dropdown
                    label={"Language"}
                    options={this.state.options}
                    ResponsiveMode={ResponsiveMode.Small}
                    name={this.props.name}
                    errorMessage={this.props.errorMessage}
                    onChange={this.onChange}
                    selectedKey={this.state.selectedItem ? this.state.selectedItem : undefined}
                    styles={{
                        root: {
                            flexGrow: 1
                        },
                        container: {
                            flexGrow: 1
                        },
                        dropdown: {
                            flexGrow: 1
                        }
                    }}
                />
    }
    
}

export default connect(mapStateToProps)(LanguageDropdown);