import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: ''
            },
            columns: [
                {
                    key: 'id',
                    name: 'Client',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.title}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                },
                {
                    key: 'domain',
                    name: 'Domain',
                    fieldName: 'domain',
                    className: 'text-center'
                },
                {
                    key: 'email',
                    name: 'E-mail',
                    fieldName: 'email',
                    className: 'text-center'
                },
                {
                    key: 'report_article',
                    name: 'Report article',
                    onRender: (item) => (item.report_article===1 ? (<i className="fad fa-check"></i>) : ''),
                    className: 'text-center'
                },
                {
                    key: 'disable_news',
                    name: 'Disable news',
                    onRender: (item) => (item.disable_news===1 ? (<i className="fad fa-check"></i>) : ''),
                    className: 'text-center'
                }
            ]
        }

    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Client/index'}
                    infinite={false} 
                    columns={this.state.columns}
                />
            </div>
        )
    }
    
}