import React from 'react';
import { mapStateToProps, connect } from 'containers/DefaultContainer';

import Loader from './Loader';
import Navigation from './Navigation';

import { BrowserRouter, Route } from 'react-router-dom';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { LayerHost } from 'office-ui-fabric-react/lib/Layer';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { Customizer } from '@uifabric/utilities';
import { Layer } from 'office-ui-fabric-react/lib/Layer';
import Keywords from 'components/Keywords'
import Sources from 'components/Sources'
import Login from './Login';
import Realtime from 'components/Realtime';
import ErrorHandler from 'components/Main/ErrorHandler';
import UploadController from 'components/Upload/UploadController'

const ComponentsMap = {
    AssignmentsList: React.lazy(() => import('./Assignments/AssignmentsList')),
    AssignmentsEdit: React.lazy(() => import('./Assignments/SingleAssignmentEdit')),
    PhotoList: React.lazy(() => import('./Photo/PhotoList')),
    PhotoEdit: React.lazy(() => import('./Photo/PhotoEdit')),
    VideoList: React.lazy(() => import('./Video/VideoList')),
    VideoEdit: React.lazy(() => import('./Video/VideoEdit')),
    ScanList: React.lazy(() => import('./Scan/ScanList')),
    ScanEdit: React.lazy(() => import('./Scan/ScanEdit')),
    DocumentList: React.lazy(() => import('./Document/DocumentList')),
    DocumentEdit: React.lazy(() => import('./Document/DocumentEdit')),
    CountryList: React.lazy(() => import('./Country/CountryList')),
    CategoryList: React.lazy(() => import('./Category/CategoryList')),
    NationalityList: React.lazy(() => import('./Nationality/NationalityList')),
    ExternalLinksList: React.lazy(() => import('./ExternalLinks/ExternalLinksList')),
    LanguageList: React.lazy(() => import('./Language/LanguageList')),
    ProjectList: React.lazy(() => import('./Project/ProjectList')),
    TranslateList: React.lazy(() => import('./Translate/TranslateList')),
    UsersList: React.lazy(() => import('./Users/UsersList')),
    RobotLinksList: React.lazy(() => import('./RobotLinks/RobotLinksList')),
    ClientsList: React.lazy(() => import('./Clients/ClientsList')),
    SitesList: React.lazy(() => import('./Sites/SitesList')),
    WikiList: React.lazy(() => import('./Wiki/WikiList')),
    SearchLog: React.lazy(() => import('./Log/SearchLog')),
    UserLog: React.lazy(() => import('./Log/UserLog')),
    KeywordsList: React.lazy(() => import('./Keywords/KeywordsList')),
    TagsList: React.lazy(() => import('./Keywords/TagsList')),
    ReportList: React.lazy(() => import('./Report/ReportList')),
    IntelligenceList: React.lazy(() => import('./Intelligence/IntelligenceList')),
    Map: React.lazy(() => import('./Location/LocationMap'))
}

Keywords.Icons.Register();

class APP extends React.Component {

    constructor(props) {
        super(props);
        initializeIcons();
        
        this.PagePopup = this.PagePopup.bind(this);
        this.OriginalProps = props;
    }

    userLoggedIn() {
        
        let res = false;
        res = this.props.User.loggedIn;
        
        return res;
        
    }

    PagePopup() {
        if (window.location.hash && window.location.hash.indexOf('PagePopup:')>-1) {
            let sub = window.location.hash.split(':')[1];
            
            if (sub.indexOf('addSource') > -1) {
                const Sub = ComponentsMap[sub.replace(/addSource/,'')];
                return <React.Suspense fallback={<Loader.Page />}><Sources.Add><Sub /></Sources.Add></React.Suspense>
            } else {
                const Sub = ComponentsMap[sub];
                let id = window.location.hash.split(':')[2];
                return <React.Suspense fallback={<Loader.Page />}><Sub id={id} /></React.Suspense>
            }
        }
        return null
    }
    
    render() {
        return this.userLoggedIn() ? (
            <BrowserRouter>
                <Stack styles={{root:{height:'100vh', position: 'relative'}}}>
                    <Stack.Item grow={0} className={'MenuBar'}>
                        <Route render={() => (
                            <>
                                <Realtime.Connection />
                                <UploadController />
                                <Navigation.MenuMain />
                            </>
                        )} />
                    </Stack.Item>
                    <Stack.Item grow={0} className={'MenuBar'}>
                        <Route render={() => <Navigation.MenuTop />} />
                    </Stack.Item>
                    <Stack.Item grow={0} className={'ConfirmationBox'}>
                        <Navigation.ConfirmationBox />
                    </Stack.Item>
                    <Stack.Item grow={0} className={'ApplicationPopup'}>
                        <Navigation.Popup />
                    </Stack.Item>
                    <Stack.Item grow={0} className={'PagePopup'}>
                        <Navigation.PagePopup key={'PagePopup'}>
                        {this.PagePopup()}
                        </Navigation.PagePopup>
                        <Navigation.SlidePopup />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <React.Suspense fallback={<Loader.Page />}>
                            <LayerHost id={'MainLayerHost'} style={{
                                position: 'relative',
                                height: 'calc(100vh - 88px)',
                                overflow: 'hidden'
                              }} data-is-scrollable={'true'} />
                              <Customizer 
                                scopedSettings={
                                    {
                                        Layer: {
                                            hostId: 'MainLayerHost'
                                        }
                                
                                    }
                                }
                            >
                                <Layer styles={{root: {height: '100%', background: 'white'}}} className="MainLayer">
                                    <Route exact path='/Assignments' render={() => { const Component = ComponentsMap['AssignmentsList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Assignment/:id' render={(props) => { const Component = ComponentsMap['AssignmentsEdit']; return (<ErrorHandler><Component id={props.match.params.id} /></ErrorHandler>);}} />
                                    
                                    <Route exact path='/Intelligence' render={() => { const Component = ComponentsMap['IntelligenceList']; return (<ErrorHandler><Component noExpand /></ErrorHandler>); }} />
                                    
                                    <Route path='/(Photo|Video|Scan|Document)/:action?/:data?' render={(props) => { 
                                        const Component = ComponentsMap[`${props.match.params[0]}List`]; 
                                        
                                        const subs = () => {
                                            
                                            let SubComponent = null;
                                            
                                            switch (props.match.params.action) {
                                                
                                                case 'Edit':
                                                    SubComponent = ComponentsMap[`${props.match.params[0]}Edit`];
                                                    break;
                                                
                                                default:
                                                    SubComponent = null;
                                                
                                            }
                                            
                                            return SubComponent === null ? null : <ErrorHandler><Navigation.PageOverlay 
                                                key={props.match.params.action + props.match.params.data} 
                                                functionCancel={() => {
                                                  props.history.push(`/${props.match.params[0]}`)  
                                                }}
                                                functionOK={() => {
                                                    this.OriginalProps.dispatch({
                                                        type: 'PAGE_OVERLAY_OK',
                                                        payload: {
                                                            action: props.match.params.action,
                                                            module: props.match.params[0],
                                                            data: props.match.params.data
                                                        }
                                                    });
                                                    props.history.push(`/${props.match.params[0]}`)
                                                }}
                                                show
                                                disconnected
                                                title={`Edit ${props.match.params[0]}`}
                                            >
                                                <SubComponent id={props.match.params.data} />
                                            </Navigation.PageOverlay></ErrorHandler>
                                            
                                        }
                                        
                                        return (
                                            <>
                                                {subs()}
                                                <ErrorHandler><Component /></ErrorHandler>
                                            </>
                                        ); 
                                    }} />

                                    <Route exact path='/Country' render={() => { const Component = ComponentsMap['CountryList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Category' render={() => { const Component = ComponentsMap['CategoryList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Nationality' render={() => { const Component = ComponentsMap['NationalityList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/ExternalLinks' render={() => { const Component = ComponentsMap['ExternalLinksList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Language' render={() => { const Component = ComponentsMap['LanguageList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Project' render={() => { const Component = ComponentsMap['ProjectList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Translate' render={() => { const Component = ComponentsMap['TranslateList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Users' render={() => { const Component = ComponentsMap['UsersList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/RobotLinks' render={() => { const Component = ComponentsMap['RobotLinksList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Clients' render={() => { const Component = ComponentsMap['ClientsList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Sites' render={() => { const Component = ComponentsMap['SitesList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Wiki' render={() => { const Component = ComponentsMap['WikiList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    
                                    <Route exact path='/SearchLog' render={() => { const Component = ComponentsMap['SearchLog']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/UserLog' render={() => { const Component = ComponentsMap['UserLog']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    
                                    <Route exact path='/Keywords' render={() => { const Component = ComponentsMap['KeywordsList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    <Route exact path='/Tags' render={() => { const Component = ComponentsMap['TagsList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    
                                    <Route exact path='/Report' render={() => { const Component = ComponentsMap['ReportList']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                    
                                    <Route exact path='/Map' render={() => { const Component = ComponentsMap['Map']; return (<ErrorHandler><Component /></ErrorHandler>); }} />
                                </Layer>
                            </Customizer>
                        </React.Suspense>
                    </Stack.Item>
                </Stack>
            </BrowserRouter>        
        ) : (<Login />);
    }
    
}

export default connect(mapStateToProps)(APP);