import { Cache } from 'helpers';

let defaultState = {
    users: {}
}
try {
    const data = Cache.Get('R_FILL_USERS');
    if (data.data) defaultState = {
        users: data.data
    }
} catch (e) {
    defaultState = {
        users: {}
    }    
}

function usersReducer(state=defaultState, action) {
    
    switch (action.type) {
        
        case 'R_GET_USERS':
            return state;
        
        case 'R_FILL_USERS_PENDING':
            return state;
        
        case 'R_FILL_USERS_FAILED':
            return state;
        
        case 'R_FILL_USERS_FULFILLED':
            let x = {};
            action.payload.data.Data.forEach((c) => {
                x[c.id] = c;
            })
            Cache.Save('R_FILL_USERS', x, 300 * 1000);
            return {
                users: x
            }
            
        default:
            return state;
        
    }
    
}

export default usersReducer;