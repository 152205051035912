import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    
    return {
        Filter: state.navigation.Popup.Filter ? state.navigation.Popup.Filter : '',
        FilterData: state.navigation.Popup.FilterData ? state.navigation.Popup.FilterData : {}
    }
    
}

export { mapStateToProps, connect }