import React from 'react';
import Table from 'components/Table';
import User from 'components/User';
import { Text } from 'office-ui-fabric-react/lib/Text';

import Navigation from 'components/Navigation';

var moment = require('moment');

class ExternalLinksList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'ExternalLinks',
                    onRender: (item) => {
                        let name = item.title;
                        let icon = '';
                        if (name.indexOf('&#160;') > -1) {
                            name = name.split('- ');
                            name = name[1];
                            icon = (<i className="fad fa-arrow-from-left" style={{marginLeft: 20}}></i>);
                        }
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{icon} {name}</Text>
                                <a className={'id'} href={'https://etleboro.org/l/' + item.id} target={'_blank'}>ID: {item.id}</a>
                            </div>
                        )                 
                    },
                    minWidth: 200,
                    maxWidth: 200
                },
                {
                    key: 'note',
                    name: 'Note',
                    fieldName: 'note',
                    minWidth: 300
                },
                {
                    key: 'short',
                    name: 'Short',
                    onRender: (item) => (<a className={'id'} href={'https://etleboro.org/l/' + item.short} target={'_blank'}>{item.short}</a>)
                },
                {
                    key: 'datetime',
                    name: 'Date',
                    onRender: (item) => (moment(item.datetime).format('DD.MM.YYYY hh:mm'))
                },
                {
                    key: 'user',
                    name: 'User',
                    onRender: (item) => (item.user_id !== '' ? <User.Inline id={item.user_id} /> : '')
                },
                {
                    key: 'expires',
                    name: 'Expires',
                    onRender: (item) => (moment(item.expires).format('DD.MM.YYYY hh:mm'))
                },
                {
                    key: 'views',
                    name: 'Views',
                    fieldName: 'views'
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                title: ''
            }
        })
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'ExternalLinks/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide}>
                    <h1>{this.state.selectedItem.title}</h1>
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default ExternalLinksList;