import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

class CategorySingle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            form: { ...props.data }
        }
        
        this.onChange= this.onChange.bind(this);
    }
    
    onChange(event) {
        let oldForm = this.state.form;
        oldForm[event.target.name] = event.target.value;
        this.setState({
            form: oldForm
        });
        this.props.setForm(this.state.form);
    }
    
    render() {
        return (
            <div>
                <h1>Edit category: {this.props.data.title}</h1>
                <TextField label={'Title'} name={'name'} defaultValue={this.props.data.title} onChange={this.onChange} />
            </div>
        )
    }
    
}

export default CategorySingle