const defaultState = {
    pageRefresh: false,
    confirmationBox: {
        show: false
    },
    Search: {
        clearSearch: true
    },
    Popup: {
        Filter: '',
        FilterData: {
        }
    },
    PagePopup: {
        Filter: '',
        FilterData: {
            
        }
    }
}

function navigationReducer(state=defaultState, action) {
    state = {
        ...state,
        RELOAD_DATA: false
    }
    delete state.pageOverlayOK;
    
    switch (action.type) {
        
        case 'SIDEBARLEFT_SHOW':
            return {
                ...state,
                pageRefresh: false,
                sidebarLeft: true
            }
        case 'SIDEBARLEFT_HIDE':
            return {
                ...state,
                pageRefresh: false,
                sidebarLeft: false
            }
            
        case 'SIDEBARLEFT_TOGGLE':
            return {
                ...state,
                pageRefresh: false,
                sidebarLeft: !state.sidebarLeft
            }
            
        case 'CHANGE_PAGE': 
            return {
                ...state,
                pageRefresh: true,
                navigationPageChanged: new Date().getTime(),
                Search: {
                    clearSearch: true
                }
            }
            
        case 'RELOAD_DATA': 
            return {
                RELOAD_DATA: true
            }
            
        case 'CONFIRMATION_SHOW': {
            
            if (typeof(action.payload.functionOK) == 'undefined') {
                action.payload.functionOK = () => {}
            }
            
            return {
                ...state,
                confirmationBox: {
                    ...action.payload,
                    show: true
                }
            }
        }
        
        case 'CONFIRMATION_HIDE': {
            return {
                ...state,
                confirmationBox: {
                    show: false,
                    title: '',
                    text: '',
                    functionOK: () => ({}),
                    functionCancel: () => ({})
                }
            }
        }
        
        case 'POPUP_SHOW': {
            return {
                ...state,
                Popup: {
                    ...state.Popup,
                    ...action.payload,
                    show: true,
                    Filter: ''
                }
            }
        }
        
        case 'POPUP_HIDE': {
            return {
                ...state,
                Popup: {
                    ...state.Popup,
                    show: false,
                    title: '',
                    text: '',
                    functionOK: () => ({}),
                    functionCancel: () => ({}),
                    children: '',
                    Filter: ''
                }
            }
        }
        
        case 'PAGE_POPUP_SHOW': {
            return {
                ...state,
                PagePopup: {
                    ...state.PagePopup,
                    ...action.payload,
                    show: true,
                    Filter: ''
                }
            }
        }
        
        case 'PAGE_POPUP_HIDE': {
            return {
                ...state,
                PagePopup: {
                    ...state.PagePopup,
                    show: false,
                    title: '',
                    text: '',
                    functionOK: () => ({}),
                    functionCancel: () => ({}),
                    children: '',
                    Filter: ''
                }
            }
        }
        
        case 'SLIDE_POPUP_SHOW': {
            return {
                ...state,
                SlidePopup: {
                    ...state.SlidePopup,
                    ...action.payload,
                    show: true,
                    Filter: ''
                }
            }
        }
        
        case 'SLIDE_POPUP_HIDE': {
            return {
                ...state,
                SlidePopup: {
                    ...state.SlidePopup,
                    show: false,
                    title: '',
                    text: '',
                    functionOK: () => ({}),
                    functionCancel: () => ({}),
                    children: '',
                    Filter: ''
                }
            }
        }
        
        case 'POPUP_SEARCH': {
            return {
                ...state,
                Popup: {
                    ...state.Popup,
                    Filter: action.payload.text
                }
            }
        }
        
        case 'SET_FILTER_DATA': {
            return {
                ...state,
                Popup: {
                    ...state.Popup,
                    FilterData: action.payload
                }
            }
        }
        
        case 'GET_FILTER_DATA': {
            return {
                ...state
            }
        }
        
        case 'PAGE_OVERLAY_OK': {
            return {
                ...state,
                pageOverlayOK: action.payload
            }
        }
        
        default: {
            return {
                ...state,
                RELOAD_DATA: false,
                pageRefresh: false
            }
        }

    }
    
}

export default navigationReducer;