import React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import Navigation from 'actions/navigation';
import { mapStateToProps, connect } from '../../containers/MainContainer';

class SidebarLeft extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            sidebarLeft: false
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        
        if ( props.sidebarLeft !== state.sidebarLeft ) {
            return {
                sidebarLeft: props.sidebarLeft
            }
        }
        return {}
    }

    hideSidebar(force=false) {
        if (force) this.props.dispatch(Navigation.hideLeftSidebar());
    }
    
    componentDidMount() {
        this.setState({
            sidebarLeft: this.props.show
        })
    }
    
    render() {
        return (
            <Panel
                isOpen={this.state.sidebarLeft}
                type={PanelType.smallFixedNear}
                onLightDismissClick={this.hideSidebar.bind(this, true)}
                { ...(this.props.title ? {headerText: this.props.title} : {}) }
                layerProps={{hostId: 'MainLayerHost'}}
                hasCloseButton={false}
                isLightDismiss={true}
            >
                {this.props.children}
            </Panel>
        )
        
    }
    
}

export default connect(mapStateToProps)(SidebarLeft);