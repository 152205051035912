import React from 'react'
import AssignmentSources from './SingleAssignmentSources';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { registerIcons } from '@uifabric/styling';
import { PrimaryButton } from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

registerIcons({
    icons: {
        'faInfo': <i className={'fad fa-file-alt'} />,
        'faLink': <i className={'fad fa-link'} />,
        'faProcess': <i className={'fad fa-shapes'} />
    }
});

class SingleAssignmentPreviewBig extends React.Component {

    editAssignment(id) {
        this.props.history.push(`/Assignment/${id}`);
    }

    render() {
        
        return this.props.assignment ? (
            <Pivot className="PivotContainer">
                <PivotItem headerText="Info" itemCount={<NumberFormat thousandSeparator={'.'} decimalSeparator={','} value={this.props.assignment.char_number} displayType={'text'} />} style={{boxSizing: 'border-box', padding: '20px', paddingTop: 0}} itemIcon={'faInfo'}>
                    <h1>{this.props.assignment.name}</h1>
                    <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: this.props.assignment.info}}></p>
                </PivotItem>
                <PivotItem headerText="Sources" itemIcon={'faLink'}>
                    <AssignmentSources id={this.props.assignment.id} hideEdit hideInfo />
                </PivotItem>
                <PivotItem headerText="Process" itemIcon={'faProcess'} itemCount={this.props.assignment.status ? this.props.assignment.status : 0} style={{textAlign: 'center'}}>
                    <div style={{margin: 20}}>
                        <PrimaryButton text="Edit assignment" onClick={this.editAssignment.bind(this, this.props.assignment.id)} allowDisabledFocus />
                    </div>
                </PivotItem>
            </Pivot>
        ) : <div />;
    }
    
}

export default withRouter(SingleAssignmentPreviewBig);