import React from 'react';
import { mapStateToProps, connect } from 'containers/PopupContainer';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { Breadcrumb } from 'office-ui-fabric-react/lib/Breadcrumb';
import Navigation from 'actions/navigation';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

class SlidePopup extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            show: false,
            index: 0,
            children: {},
            firstTime: true,
            slides: {
                'slide0': {
                    title: '',
                    render: this.clone(this.props.children)
                }
            },
            clone: this.clone.bind(this)
        }
        
        this.addSlide = this.addSlide.bind(this);
        this.renderSlide = this.renderSlide.bind(this);
        this.breadcrumbs = this.breadcrumbs.bind(this);
        this.clone = this.clone.bind(this);
        this.goTo = this.goTo.bind(this);
        this.functionOK = this.functionOK.bind(this);
        this.functionCancel = this.functionCancel.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
         if (
            typeof props.navigation !== 'undefined' &&
            typeof props.navigation.SlidePopup !== 'undefined'
        ) {
            if ( props.navigation.SlidePopup.show === false ) 
                return { 
                    show: false, 
                    Filter: {
                        SearchBox: ''
                    },
                    slides: {},
                    children: null,
                    index: 0,
                    firstTime: true
                }
            else {
                let slides = state.slides;
                if (state.firstTime) {
                    if (props.navigation.SlidePopup.children) slides = {
                        'slide0': {
                            title: props.navigation.SlidePopup.title,
                            render: state.clone(props.navigation.SlidePopup.children)
                        }
                    }
                }
                return {
                    slides: slides,
                    firstTime: false,
                    ...props.navigation.SlidePopup
                }
            }
        }
        
        return null;
    }
    
    clone(a) {
        if (a) return React.cloneElement(a, { ...a.props, addSlide: this.addSlide })
        else return null;
    }
    
    addSlide(title, render) {
        let slides = {...this.state.slides};
        slides[`slide${Object.keys(slides).length}`] = {
            title: title,
            render: render
        };

        this.setState({
            slides: slides,
            index: Object.keys(slides).length-1
        });
    }
    
    goTo(index) {
        let slides = { ...this.state.slides };
        let newSlides = {};
        if ( index===0 ) newSlides['slide0'] = slides['slide0'];
        else {
            for (let i=0; i<=index; i++) newSlides[`slide${i}`] = slides[`slide${i}`];
        }
        
        this.setState({
            index: index,
            slides: newSlides
        })
    }
    
    breadcrumbs() {
        let items = [];
        const allSlides = this.state.slides;
        const slides = Object.keys(allSlides);

        slides.forEach((slide, index) => {
            items.push({
                text: allSlides[slide].title,
                key: `slide${index}`,
                onClick: () => {
                    this.goTo(index);
                },
                isCurrentItem: (index === this.state.index)
            });
        });

        return (
            <span style={{
                display: 'flex',
                width: '100%'
            }}>
                <Breadcrumb
                    onReduceData={()=>(undefined)}
                    items={items}
                    styles={{
                        root: {
                            width: '100%'
                        },
                        list: {
                            width: '100%'
                        }
                    }}
                />
            </span>
        );
    }
    
    renderSlide(params) {
        const { index } = params;
        let ret = <></>;
        if (this.state.slides[`slide${index}`]) ret = this.state.slides[`slide${index}`].render;
        return ret;
    }
    
     functionOK() {
        this.props.dispatch(Navigation.hideSlidePopup());
        this.state.functionOK();
    }
    
    functionCancel() {
        this.setState({
            children: ''
        }, function() {
            this.props.dispatch(Navigation.hideSlidePopup());
            this.state.functionCancel();    
        })
    }
    
    render() {
        const titleOK = this.state.titleOK ? this.state.titleOK : 'OK';
        const titleCancel = this.state.titleCancel ? this.state.titleCancel : 'Cancel';
        
        return (
            <Dialog
                hidden={!this.state.show}
                shiftOnReduce={true}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: this.breadcrumbs(),
                  styles: {
                    title: {
                        paddingRight: 24,
                        paddingTop: 24,
                        paddingBottom: 0
                    }  
                  }
                }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  className: 'SlidePopup',
                  styles: { 
                    main: { 
                        maxWidth: 'calc(100vw - 20px) !important',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    },
                    scrollableContent: {
                        maxWidth: 'calc(100vw - 20px)',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    }
                }}}
            >
                <VirtualizeSwipeableViews 
                    overscanSlideAfter={0} 
                    overscanSlideBefore={0}
                    slideRenderer={this.renderSlide}
                    index={this.state.index} 
                    style={{ height: '100%', width: '100%' }} 
                    containerStyle={{ height: '100%', width: '100%' }} 
                />
                <DialogFooter>
                  <PrimaryButton onClick={this.functionOK} text={titleOK} />
                  <DefaultButton onClick={this.functionCancel} text={titleCancel} />
                </DialogFooter>
            </Dialog>
        )
    }
}

export default connect(mapStateToProps)(SlidePopup);