import React from 'react';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import Keywords from 'components/Keywords';
import Loader from 'components/Loader';

import 'react-daterange-picker/dist/css/react-calendar.css'
import { mapStateToProps, connect } from 'containers/FilterFormContainer';

const CountryForm = React.lazy(() => import('components/Country/CountryFilterForm'));
const CategoryForm = React.lazy(() => import('components/Category/CategoryFilterForm'));
const LanguageForm = React.lazy(() => import('components/Language/LanguageFilterForm'));
const LinkForm = React.lazy(() => import('components/RobotLinks/RobotLinksFilterForm'));
const KeywordsForm = React.lazy(() => import('components/Keywords/KeywordsFilterForm'));
const TagsForm = React.lazy(() => import('components/Keywords/TagsFilterForm'));
const DateRangePicker = React.lazy(() => import('elements/DateRangePicker.js'));

Keywords.Icons.Register();

const LinkTitle = (props) => {
    if (props.FilterData[props.item] && Object.keys(props.FilterData[props.item]).length>0) {
        return (<strong style={{color: 'var(--blue-light)'}}>{props.forms[props.item].title} {'(' + Object.keys(props.FilterData[props.item]).length + ')'}</strong>)
    } else {
        return (<span>{props.forms[props.item].title}</span>)
    }
}

class FilterForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedKey: 'none',
            FilterData: {}
        }
        
        this.onLinkClick = this.onLinkClick.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.getFilterSelected = this.getFilterSelected.bind(this);
        this.calcGroups = this.calcGroups.bind(this);
        this.state.calcGroups = this.calcGroups.bind(this);
        
        this.state.forms = {
            country: {
                title: 'Country',
                icon: 'icon-country',
                module: <CountryForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            category: {
                title: 'Category',
                icon: 'icon-organization',
                module: <CategoryForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            language: {
                title: 'Language',
                icon: 'icon-language',
                module: <LanguageForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            link: {
                title: 'Links',
                icon: 'icon-link',
                module: <LinkForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            keywords: {
                title: 'Keywords',
                icon: 'icon-keywords',
                module: <KeywordsForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            tags: {
                title: 'Tags',
                icon: 'icon-tags',
                module: <TagsForm getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            period: {
                title: 'Period',
                icon: 'icon-calendar',
                module: <DateRangePicker getFilterData={this.getFilterSelected} onFilter={this.onFilter} />
            },
            none: {
                title: 'Unknown / Not selected',
                module: <h3>Select item</h3>,
                hide: true
            }
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (props.FilterData) return { 
            FilterData: props.FilterData,
            groups: state.calcGroups(props.FilterData)
        }
        return null;
    }
    
    calcGroups(FilterData = false) {
        const FData = (FilterData ? FilterData : this.state.FilterData);
        return Object.keys(this.state.forms)
                .filter((item) => (this.state.forms[item].hide !== true))
                .map((item) => {
                    const x = this.state.forms[item];
                    if (!x.hide) {
                        return {
                            key: item,
                            name: <LinkTitle item={item} FilterData={FData} forms={this.state.forms} />,
                            icon: x.icon
                        }
                    } else return false;
                });
    }
    
    componentDidMount() {
        this.props.dispatch({
            type: 'GET_FILTER_DATA'
        })
    }
    
    onFilter(key, values) {
        let filter = { ...this.state.FilterData }
        filter[key] = values;

        this.props.dispatch({
            type: 'SET_FILTER_DATA',
            payload: filter
        });
    }
    
    getFilterSelected(key = false) {
        if (!key) return this.state.FilterData;
        else return this.state.FilterData[key] || {};
    }
    
    onLinkClick(event, item) {
        if (typeof(this.state.forms[item.key]) !== 'undefined') {
            this.setState({
                selectedKey: item.key
            })
        } else {
            this.setState({
                selectedKey: 'none'
            })
        }
    }
    
    render() {
        return (
            <div style={{height: 600, display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                <div style={{width: 200, flexGrow: 0, flexShrink: 0}}>
                    <Nav
                        onLinkClick={this.onLinkClick}
                        groups={[
                            {
                                links:this.state.groups
                            }
                        ]}
                    />
                </div>
                <div style={{flexGrow: 1, boxSizing: 'border-box', padding: 10, overflowY: 'auto', paddingTop: 0, marginTop: 14}}>
                    <React.Suspense fallback={<Loader.Inline />}>
                        {this.state.forms[this.state.selectedKey].module}
                    </React.Suspense>
                </div>
            </div>
        );
    }
    
}

export default connect(mapStateToProps)(FilterForm);