import Dexie from 'dexie';

const DB = new Dexie('AgenziaM');

DB
.version(1)
.stores({ 
    Cache: '&key'
});

export default DB;