import React from 'react';

import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

const Sub = (props) => {
    const { link, selected, expanded, onCheckboxClick } = props;

    if (expanded && link.links && link.links.length>1) return link.links.map((l) => {
        const cbClick = (event, isSelected) => {
            onCheckboxClick(event, isSelected, l.id, l.title);
        }
        return (<RobotLinksGroup key={l.id} link={l} sub={true} selected={selected} onCheckboxClick={cbClick} getFilterData={props.getFilterData} />)
    });
    else return null;
}

class RobotLinksGroup extends React.Component {
    
    constructor(props) {
        super(props);
        
        let state = {
            expanded: false,
            link: props.link,
            sub: props.sub ? props.sub : false,
            selected: props.selected ? props.selected : false,
            oldProps: props,
            subSelected: 0
        }
        
        const data = props.getFilterData('link');
        
        if ( state.selected === false && ( !props.link.links || props.link.links.length === 0 ) ) {
            if ( typeof(data[props.link.id]) !== 'undefined' )state.selected = true;
        }
        
        if ( props.link.links && props.link.links.length>1 ) { 
            props.link.links.forEach((subLink) => {
                if ( typeof(data[subLink.id]) !== 'undefined' ) state.subSelected++;
            })
            if (state.subSelected === props.link.links.length) {
                state.selected = true
            }
        }
        
        this.state = state;
        
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.toggleSelected = this.toggleSelected.bind(this);
    }
    
    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded 
        });
    }
    
    toggleSelected (event, isSelected) {
        if (this.props.onCheckboxClick) {
            if (this.state.link.links && this.state.link.links.length) {
                let ids = {};
                this.state.link.links.forEach((l) => {
                    ids[l.id] = l.title;
                })   
                this.props.onCheckboxClick(event, isSelected, ids);
            }
            else {
                this.props.onCheckboxClick(event, isSelected, this.state.link.id, this.state.link.title)
            }
        }
        this.setState({
            selected: isSelected,
            clicked: true
        })
    }

    renderTitle() {
        const { link } = this.state;
        
        let count = '';
        if (link.links && link.links.length>1) {
            if (this.state.subSelected > 0) count = ' ( ' + this.state.subSelected + ' / ' + link.links.length + ' )';
            else count = ' (' + link.links.length + ')';
        }
        
        return (
            <span style={{fontSize: 16, fontWeight: (this.state.subSelected > 0 ? 'bold' : 'normal')}}>{link.title + count}</span>
        )
    }
    
    renderCheckbox = () => {
        const { link, expanded } = this.state;
        const chevron = expanded ? 'down' : 'right';
        
        return link.links && link.links.length > 1 ? 
            ( <i className={`fad fa-chevron-${chevron}`} style={{margin: 10}} onClick={this.toggleExpanded} /> ) : 
            ( <div style={{ display: 'inline-block', width: 28.5 }} /> )
    }
    
    static getDerivedStateFromProps(props, state) {
        if (state.oldProps.selected !== props.selected) {
            return {
                oldProps: props,
                selected: props.selected
            }
        }
        return null;
    }

    render() {
        const { link, selected } = this.state;
        return (
            <>
                <div className={'menuItemSimulation'} key={link.id}>
                    {this.state.sub ? (<div style={{display: 'inline-block', width: 65}} />) : (<div style={{
                        display: 'inline-block',
                        width: 32,
                        height: 32,
                        background: `url(https://bl.m.etleboro.org/APP/img/mediaLogo.php?id=${link.id}&X_API_KEY=d924b1681e7bb2ed98d6fd9c237f9ed06b4f65da208b90c0c5eb05066efcd064)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        marginRight: 10
                    }}></div>)}
                    {this.renderCheckbox()}
                    <Checkbox onRenderLabel={this.renderTitle} onChange={this.toggleSelected} checked={selected} />
                </div>
                <Sub key={`sub-${link.id}`} link={link} selected={this.state.selected} expanded={this.state.expanded} onCheckboxClick={this.props.onCheckboxClick} getFilterData={this.props.getFilterData} />
            </>
        )
        
    }
    
}

export default RobotLinksGroup;