import API from 'actions/api';
import { Cache } from 'helpers';
const debounce = require('debounce');

const Country = {
    
    Load: debounce((dispatch) => {
        Cache.GetIfValid('R_FILL_COUNTRIES')
        .then((cacheData) => {
            dispatch({
               type: 'R_FILL_COUNTRIES_FULFILLED',
               payload: {
                   data: {
                       Data: Object.values(cacheData.data)
                   }
               }
            });
        })
        .catch((e) => {
            dispatch({
                type: 'R_FILL_COUNTRIES',
                payload: API.returnGet('Country/index?output=json', false),
            })  
        })
    }, 60000, true),
    
    Get: debounce((dispatch) => {
        dispatch({
            type: 'R_GET_COUNTRIES',
        })
    }, 1000, true)
    
}

export default Country; 