import React from 'react';
import axios from 'axios';
import Config from 'config.js';
import { connect, mapStateToProps } from 'containers/UploadContainer';
import { OG } from 'helpers';

class UploadController extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            onPasteLink: this.onPasteLink.bind(this),
            onPasteFiles: this.onPasteFiles.bind(this),
            addUpload: this.addUpload.bind(this),
            fetchExternal: this.fetchExternal.bind(this)
        }
    }
    
    static getDerivedStateFromProps = (props, state) => { 

        if (props.uploadFunction) {
            
            if (state[props.uploadFunction.f]) {
                const f = state[props.uploadFunction.f];
                f(props.uploadFunction.data);
            }
            
        }
        
        return null;
        
    }
    
    onPasteLink(link) {
        const id = new Date().getTime();
        
        this.props.dispatch({
            type: 'UPLOAD_ADD',
            payload: {
                upload: {
                    id: id,
                    title: link,
                    progress: 0,
                    shimmer: true
                }
            }
        });

        let body = new FormData();
        body.set('type', 'link');
        body.set('link', link);
        axios.post(Config.API_PATH + 'Upload/ID?output=json', body, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((response) => {
            if (response.data.Data.id) {
                const upload = {
                    id: response.data.Data.id,
                    title: link,
                    type: 'link',
                    progress: 0,
                    replace_id: id
                }
                this.props.dispatch({
                    type: 'UPLOAD_ADD',
                    payload: {
                        upload: upload
                    }
                });
                OG(link).then((result) => {
                    this.props.dispatch({
                        type: 'UPLOAD_DATA',
                        payload: {
                            id: upload.id,
                            data: {
                                image: result.data.data.ogImage.url,
                                title: result.data.data.ogTitle,
                                description: result.data.data.ogDescription,
                                site: result.data.data.ogSiteName
                            }
                        }
                    })
                });
            }
        });
    }
    
    onPasteFiles(files) {
        for (let i=0; i<files.length; i++) {
            const file = files[i].getAsFile();
            if ( file && file.type ) {
                const type = file.type.split('/');
                
                
                if (type[0] !== 'text') {
                    let reader = new FileReader();
                    reader.onload = ((readData) => {
                        this.addUpload({
                            id: file.lastModified,
                            title: file.name,
                            progress: 0,
                            fileData: readData.target.result,
                            type: file.type
                        });
                    });
                    reader.readAsDataURL(file);
                }
            }
        }
    }
    
    addUpload(upload) {
        let body = new FormData();
        body.set('type', upload.type);
        body.set('isScan', '0');
        body.set('folder', '');
        body.set('name', upload.title);
        axios.post(Config.API_PATH + 'Upload/ID?output=json', body, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((response) => {
            if (response.data.Data.id) {
                upload.id = response.data.Data.id;
                this.props.dispatch({
                    type: 'UPLOAD_ADD',
                    payload: {
                        upload: upload
                    }
                });
                let bodyFile = new FormData();
                bodyFile.set('id', upload.id);
                bodyFile.set('name', upload.title);
                bodyFile.set('type', upload.type);
                bodyFile.set('isScan', '0');
                bodyFile.set('folder', '');
                bodyFile.set('data', upload.fileData);
                axios.post(Config.API_PATH + 'Upload/File?output=json', bodyFile, {
                    headers: {
                        'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
                    },
                    onUploadProgress: (progressEvent) => {
                        this.props.dispatch({
                            type: 'UPLOAD_PROGRESS',
                            payload: {
                                id: upload.id,
                                progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            }
                        })
                    }
                })
                
            }
        });
    }
    
    fetchExternal(link) {
        let body = new FormData();
        body.set('preview', '1');
        body.set('url', link);
        axios.post(Config.API_PATH + 'Intelligence/fetchExternal?output=json', body, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            if (result.data.Data.existing.id) {
                this.props.dispatch({
                    type: 'UPLOAD_DISPLAY_EXISTING',
                    payload: {
                        data: result.data.Data.existing
                    }
                })
            } 
            else if (Object.keys(result.data.Data.result).length) {
                this.props.dispatch({
                    type: 'UPLOAD_EXTERNAL_NEW_ARTICLE',
                    payload: {
                        data: result.data.Data.result
                    }
                })
            }
        });
    }
    
    render() {
        return null;
    }
    
}

export default connect(mapStateToProps)(UploadController);