import { Cache } from 'helpers';

let defaultState = {
    links: {}
}
try {
    const data = Cache.Get('R_FILL_LINKS');
    if (data.data) defaultState = {
        links: data.data
    }
} catch (e) {
    defaultState = {
        links: {}
    }    
}

function linksReducer(state=defaultState, action) {
    
    switch (action.type) {
        
        case 'R_GET_LINKS':
            return state;
        
        case 'R_FILL_LINKS_PENDING':
            return state;
        
        case 'R_FILL_LINKS_FAILED':
            return state;
        
        case 'R_FILL_LINKS_FULFILLED':
            if (typeof(action.payload.data.isFromCache)=='undefined') {
                Cache.Save('R_FILL_LINKS', action.payload.data.Data, 300 * 1000);
            }
            return {
                links: action.payload.data.Data
            }
            
        default:
            return state;
        
    }
    
}

export default linksReducer;