import React from 'react';
import { connect, mapStateToProps } from 'containers/UploadContainer';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { Upload } from 'components';
import Navigation from 'actions/navigation';

class UploadExternalButton extends React.Component {

    constructor(props) {
        super(props);
        
        this.buttonClick = this.buttonClick.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }
    
    onAdd(data) {
        this.props.dispatch({
            type: 'DRAFTS_SOURCE_ADD',
            payload: {
                type: 'Intelligence',
                source: data
            }
        })
    }
    
    onRemove(source) {
        this.props.dispatch(Navigation.showConfirmation({
            title: 'Delete source',
            text: 'Delete this source?',
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                this.props.dispatch({
                    type: 'DRAFTS_SOURCE_DELETE',
                    payload: {
                        type: source.MediaType,
                        source: source
                    }
                })    
            },
            functionCancel: () => { 
            }
        }));
    }
    
    buttonClick() {
        this.props.dispatch(Navigation.showPagePopup({
            title: 'External Link',
            children: <Upload.External onAdd={this.onAdd} onRemove={this.onRemove} />,
            showSearch: false,
            titleOK: 'OK',
            titleCancel: false
        }));
    }
    
    render() {
        return (
            <CommandBarButton onClick={this.buttonClick}>
                <div>
                    <i className={'fas fa-link'}></i>
                </div>
            </CommandBarButton>
        );
    }
    
}

export default connect(mapStateToProps)(UploadExternalButton);