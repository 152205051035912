import React from 'react';
import Loader from 'components/Loader';
import API from 'actions/api';
import MediaPreview from './MediaPreview';
import MediaForm from './MediaForm';
import { connect } from 'react-redux';

let sendData = {};

class MediaEdit extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            loaded: false,
            data: {}
        }
        
        this.modifyData = this.modifyData.bind(this);
        this.updateForm = this.updateForm.bind(this);
    }
    
    componentDidMount() {
        if (!this.state.loaded) {
            API.returnGet(`${this.props.MediaType}/edit/${this.props.id}?output=json`, false).then((result) => {
                this.setState({
                    loaded: true,
                    data: result.data.Data
                });
                sendData = result.data.Data;
            });
        }
    }
    
    updateForm(data) {
        sendData = data;
    }
    
    modifyData(key, value, errors) {
        let data = {
            ...this.state.data
        }
        data[key] = value;
        sendData = data;
    }
    
    render() {
        
        return this.state.loaded ? (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                flexShrink: 0,
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 0,
                    flexBasis: '50%',
                    padding: 30
                }}>
                <MediaForm data={this.state.data} key={this.state.data.id} onModifyData={this.modifyData} updateForm={this.updateForm} />
                </div>
                <div style={{
                    display: 'flex',
                    flexBasis: '50%',
                    flexGrow: 1,
                    flexAlign: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                    margin: 0,
                    background: 'black',
                    boxSizing: 'border-box',
                    padding: this.props.MediaType === 'Video' || this.props.MediaType === 'Document' ? 0 : 30
                }}>
                <MediaPreview data={this.state.data} MediaType={this.props.MediaType} />
                </div>
            </div>
        ) : <Loader.Big />
        
    }
    
}

export default connect((state, ownProps) => {
    if (state.navigation.pageOverlayOK) {
        const { module, action, data } = state.navigation.pageOverlayOK;
        if (['Photo', 'Video', 'Document', 'Scan'].indexOf(module) > -1 && action === 'Edit') {
            sendData['id'] = data;
            API.returnPost(`${module}/${action}FromJson?output=json`, sendData);
        }
    }
})(MediaEdit);