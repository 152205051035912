import List from './KeywordsList'
import Tags from './TagsList'
import Inline from './KeywordsInline';
import InlineTag from './TagsInline';
import Icons from './Icons';
import FilterForm from './KeywordsFilterForm';
import TagsFilterForm from './TagsFilterForm';

export default {
    List,
    Tags,
    Inline,
    Icons,
    FilterForm,
    TagsFilterForm,
    InlineTag
}