import React from 'react';

import Photo    from 'components/Photo'
import Scan     from 'components/Scan'
import Video    from 'components/Video'
import Document from 'components/Document'

export default class extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            data: props.data ? props.data : {},
            MediaType: props.MediaType ? props.MediaType : false
        }
        
        this.MediaPreview = this.MediaPreview.bind(this);
    }
    
    MediaPreview() {
        
        switch (this.state.MediaType) {
            
            case 'Photo':
                return <Photo.Preview data={this.state.data} />
                
            case 'Scan':
                return <Scan.Preview data={this.state.data} />
                
            case 'Document':
                return <Document.Preview data={this.state.data} />
                
            case 'Video':
                return <Video.Preview data={this.state.data} />

            default:
                return null;
            
        }
        
    }
    
    render() {
        
        if (this.state.MediaType === false) return null;
        else return this.MediaPreview();
        
    }
    
}