import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';

import Flag from "react-flags";

import API from 'actions/api';
import { mapStateToProps, connect } from 'containers/FormContainer';

import Navigation from 'components/Navigation';
import Country from 'components/Country'

class CountryList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Country',
                    onRender: (item) => {
                        let name = item.name;
                        let icon = '';
                        if (name.indexOf('&#160;') > -1) {
                            name = name.split('- ');
                            name = name[1];
                            icon = (<i className="fad fa-arrow-from-left" style={{marginLeft: 20}}></i>);
                        }
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{icon} {name}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                },
                {
                    key: 'iso',
                    name: 'ISO',
                    fieldName: 'code',
                },
                {
                    key: 'flag',
                    name: 'Flag',
                    onRender: (item) => {
                        return item.code.length ? (
                            <Flag
                                name={item.code}
                                format="png"
                                pngSize={32}
                                shiny={true}
                                alt={item.name}
                                basePath={'/img/'}
                            />
                        ) : '';
                    }
                },
                {
                    key: 'groups',
                    name: 'Groups',
                    fieldName: 'groups',
                    minWidth: 250
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    
    onClick(item, data={}) {
        let sitem = { ...item }
        let title = sitem.name.split('- ');
        if (title.length>1) title = title[1];
        else title = title[0];
        sitem.name = title;
        this.setState({
            editOpen: true,
            selectedItem: sitem,
            additionalData: data
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                name: ''
            }
        })
    }
    
    onFormSave(form) {
        this.onHide();
        this.props.dispatch(API.PostForm(`Country/edit/${this.state.selectedItem.id}`, form));
    }

    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Country/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide} onSave={this.onFormSave} initialData={this.state.selectedItem}>
                    <Country.Single data={this.state.selectedItem} countryList={this.state.additionalData} />
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(CountryList);