import React from 'react';
import { mapStateToProps, connect } from 'containers/DefaultContainer'

class RealtimeChat extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            userData: false,
            deepstream: false,
            connected: false,
            dsConnect: this.dsConnect.bind(this)
        }
    }
    
    dsConnect(user) {
        if (this.state.deepstream === false) {
            const ds = window.deepstream('wss://im.etleboro.org:6020/ws', {
    			reconnectIntervalIncrement: 1000,
    			maxReconnectInterval: 3,
    			maxReconnectAttempts: Infinity,
    			heartbeatInterval: 60000
    		});
    		this.setState({
    		    deepstream: ds
    		}, () => {
    		    const ds = this.state.deepstream;
    		    ds.on('error', function(error, event, topic) {
        		   console.group('DS ERROR');
        		   console.log('Error', error);
        		   console.log('Event', event);
        		   console.log('Topic', topic);
        		   console.groupEnd();
        		});
    		    ds.login({
            		username: user.chat.username,
            		password: user.chat.password
            	}, ( success, loginData ) => {
            	    
            	        if (!success) {
            	            
            	            console.group('DS LOGIN ERROR');
            	            console.log('Success', success);
            	            console.log('LoginData', loginData);
            	            console.groupEnd();
            	            
            	            return false;
            	        }
            	        
            	        this.props.dispatch({
            	            type: 'REALTIME_STATUS',
            	            payload: {
            	                status: 'online'
            	            }
            	        });
            	        
            		    console.group('DS LOGIN');
            		    console.log('Success', success);
            		    console.log('loginData', loginData);
            		    console.groupEnd();
            		    
            		    ds.event.subscribe('notification/public', (channel, data) => {
            		       
            		       console.group('DS EVENT PUBLIC');
            		       console.log('Channel', channel);
            		       console.log('Data', data);
            		       console.groupEnd();
            		       
            		    });
            		    
            		    ds.event.subscribe('notification/agenziam', (channel, data) => {
            		       
            		       console.group('DS EVENT AGENZIAM');
            		       console.log('Channel', channel);
            		       console.log('Data', data);
            		       console.groupEnd();
            		       
            		       try {
            		           let j = JSON.parse(channel);
            		           if (j.type === 'download_status') {
            		               this.props.dispatch({
            		                   type: 'UPLOAD_PROGRESS',
            		                   payload: {
            		                       id: j.id,
            		                       progress: Math.round(parseFloat(j.percent))
            		                   }
            		               })
            		           }
            		       } catch (e) {
            		           console.log('DS UNABLE TO PARSE JSON');
            		           console.log(e);
            		       }
            		       
            		    });
            		    
            		    ds.presence.subscribe(function(username, online) {
            		        
            		        console.group('DS PRESENCE');
            		        console.log('Username', username);
            		        console.log('Online', online);
            		        console.groupEnd();
            		        
            		    });
            		    
            		    ds.presence.getAll((clients, error) => {
            		        
            		        if ( !error ) {
            		            this.props.dispatch({
                    	            type: 'REALTIME_USERS',
                    	            payload: {
                    	                online: clients
                    	            }
                    	        });
            		        }
                            
                            console.group('DS PRESENCE GET ALL');
                            console.log('Error', error);
                            console.log('Clients', clients);
                            console.groupEnd();
                            
                        });
        		}); 
    		});
        }
    }
    
    static getDerivedStateFromProps = (props, state) => { 
        
        let r = {};
        
        if ( props.User.loggedIn === true ) {

            if ( props.User.userData ) {
                
                if ( state.userData === false ) r['userData'] = props.User.userData;
                
                if (state.deepstream === false) state.dsConnect(props.User.userData);
                
            }
        }
        
        return r;
        
    }
    
    render() {
        return null;
    }
    
}

export default connect(mapStateToProps)(RealtimeChat);