import React from 'react';
import { mapStateToProps, connect } from 'containers/CategoryContainer';
import { Category } from 'helpers/';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

class CategoryInline extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            categoryName: '',
            display: true
        }

        Category.Load(props.dispatch);
        
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderText = this.renderText.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            props.categoryName &&
            props.categoryName !== ''
        ) {
            return {
                categoryName: props.categoryName,
                display: (!props.displayCondition || props.displayCondition.length === 0 || props.categoryName.toLowerCase().indexOf(props.displayCondition.toLowerCase())>-1)
            }
        } 
        
        return null;
    }

    onCheckboxChange(event, isChecked) {
        return this.props.onCheckboxClick ? this.props.onCheckboxClick(event, isChecked, this.props.id, this.state.categoryName) : false;
    }
    
    renderCheckbox() {
        return (
            <Checkbox 
                styles={{
                    root: {
                        display: 'flex-inline'
                    }
                }} 
                onRenderLabel={this.renderText}
                onChange={this.onCheckboxChange}
                checked={this.props.checkboxChecked}
            />
        )
    }
    
    renderText() {
        return (
            <span title={this.state.categoryName}>{this.state.categoryName}</span>
        )
    }
    
    render() {
        return (
            this.state.categoryName !== '' && this.state.display === true ? 
            (
                <span style={this.props.styles ? this.props.styles.wrapper : {}}>
                    <div title={this.state.categoryName} style={{display: 'inline-flex', alignItems: 'center'}}>
                    {
                            this.props.checkbox && this.props.checkbox !== false ? this.renderCheckbox() : this.renderText()
                    }
                    </div>
                </span>
            ) : null
        )
    }
    
}

export default connect(mapStateToProps)(CategoryInline);