import React from 'react';

export default (props) => {
    
    const UAParser = require('ua-parser-js');
    const parsed = UAParser(props.agent);
    
    const deviceTitle = `${parsed.device.vendor} ${parsed.device.type} ${parsed.device.model}`;
    let deviceIcon = <i className='fas fa-laptop' title={deviceTitle} />;
    
    switch (parsed.device.type) {
        case 'console':
            deviceIcon = <i className='fas fa-terminal' title={deviceTitle} />;
            break;
            
        case 'mobile':
            deviceIcon = <i className='fas fa-mobile' title={deviceTitle} />;
            break;
            
        case 'tablet': 
            deviceIcon = <i className='fas fa-tablet' title={deviceTitle} />;
            break;
            
        case 'smarttv':
            deviceIcon = <i className='fas fa-tv' title={deviceTitle} />;
            break;
            
        case 'wearable':
            deviceIcon = <i className='fas fa-watch-fitness' title={deviceTitle} />;
            break;
            
        case 'embedded':
            deviceIcon = <i className='fas fa-anchor' title={deviceTitle} />;
            break;
            
        default:
            deviceIcon = <i className='fas fa-laptop' title={deviceTitle} />;
            break;
    }
    
    const osTitle = `${parsed.os.name} ${parsed.os.version}`;
    let osIcon = <i className='fas fa-laptop' title={osTitle} />;
    switch (parsed.os.name) {
        
        case 'Android':
            osIcon = <i className='fab fa-android' title={osTitle} />;
            break;
            
        case 'BlackBerry':
            osIcon = <i className='fab fa-blackberry' title={osTitle} />;
            break;
            
        case 'CentOS':
            osIcon = <i className='fab fa-centos' title={osTitle} />;
            break;
            
        case 'Chromium OS':
            osIcon = <i className='fab fa-chrome' title={osTitle} />;
            break;
            
        case 'Fedora':
            osIcon = <i className='fab fa-fedora' title={osTitle} />;
            break;
            
        case 'FreeBSD':
            osIcon = <i className='fab fa-freebsd' title={osTitle} />;
            break;
            
        case 'Debian':
        case 'Ubuntu':
            osIcon = <i className='fab fa-ubuntu' title={osTitle} />;
            break;
            
        case 'iOS':
        case 'Mac OS':
            osIcon = <i className='fab fa-apple' title={osTitle} />;
            break;
            
        case 'Linux':
        case 'Unix':
            osIcon = <i className='fab fa-linux' title={osTitle} />;
            break;

        case 'Playstation':
            osIcon = <i className='fab fa-playstation' title={osTitle} />;
            break;
        
        case 'Windows':
        case 'Windows Mobile':
        case 'Windows Phone':
            osIcon = <i className='fab fa-windows' title={osTitle} />;
            break;
            
        default:
            osIcon = <i className='fas fa-laptop' title={osTitle} />;
            break;
    }
    
    const browserTitle = `${parsed.browser.name} ${parsed.browser.version}`;
    let browserIcon = <i className='fas fa-window' title={browserTitle} />;
    switch (parsed.browser.name) {
        
        case 'Chrome': 
        case 'Chromium':
        case 'Chrome WebView':
        case 'Chrome Headless':
            browserIcon = <i className='fab fa-chrome' title={browserTitle} />;
            break;
            
        case 'Edge':
        case 'IE':
        case 'IEMobile':
            browserIcon = <i className='fab fa-internet-explorer' title={browserTitle} />;
            break;
            
        case 'Firebird':
        case 'Firefox':
            browserIcon = <i className='fab fa-firefox' title={browserTitle} />;
            break;
            
        case 'Safari':
            browserIcon = <i className='fab fa-safari' title={browserTitle} />;
            break;
        
        default:
            browserIcon = <i className='fas fa-window' title={browserTitle} />;
            break;
    }
    
    const device = deviceTitle.trim().length > 0 ? <span style={{marginRight: '10px', display: 'inline-block'}}>{deviceIcon}</span> : '';
    const os = osTitle.trim().length > 0 ? <span style={{marginRight: '10px', display: 'inline-block'}}>{osIcon}</span> : '';
    const browser = browserTitle.trim().length > 0 ? <span style={{marginRight: '10px', display: 'inline-block'}}>{browserIcon}</span> : '';
    
    return (
        <>
            {device}
            {os}
            {browser}
        </>
    )
    
}