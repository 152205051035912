import React from 'react';
import { Text } from 'office-ui-fabric-react/lib/Text';

import Table from 'components/Table';
import Navigation from 'components/Navigation';
import Nationality from 'components/Nationality'

import API from 'actions/api';
import { mapStateToProps, connect } from 'containers/FormContainer';

class NationalityList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Nationality',
                    onRender: (item) => {
                        let name = item.name;
                        let icon = '';
                        if (name.indexOf('&#160;') > -1) {
                            name = name.split('- ');
                            name = name[1];
                            icon = (<i className="fad fa-arrow-from-left" style={{marginLeft: 20}}></i>);
                        }
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{icon} {name}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                 
                    }
                },
                {
                    key: 'iso',
                    name: 'ISO',
                    fieldName: 'code',
                },
                {
                    key: 'groups',
                    name: 'Groups',
                    fieldName: 'groups',
                    minWidth: 250
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    
    onClick(item) {
        let title = item.name.split('- ');
        if (title.length>1) title = title[1];
        else title = title[0];
        item.name = title;
        
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                name: ''
            }
        })
    }
    
    onFormSave(form) {
        this.onHide();
        this.props.dispatch(API.PostForm(`Nationality/edit/${this.state.selectedItem.id}`, form));
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Nationality/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide} onSave={this.onFormSave} initialData={this.state.selectedItem}>
                    <Nationality.Single data={this.state.selectedItem} />
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(NationalityList);