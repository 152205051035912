import React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

class ProjectSingle extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            form: { ...props.data }
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    onChange(event) {
        let oldForm = this.state.form;
        oldForm[event.target.name] = event.target.value;
        this.setState({
            form: oldForm
        });
        this.props.setForm(this.state.form);
    }

    render() {
        return (
            <div>
                <h1 style={{display: 'flex', alignItems: 'center'}}>
                Edit project:
                &nbsp;
                {this.props.data.title}
                </h1>
                <Stack vertical tokens={{childrenGap: 10}}>
                    <Stack.Item grow={1}>
                        <TextField label={'Title'} name={'title'} defaultValue={this.props.data.title} onChange={this.onChange} />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <TextField label={'Description'} name={'description'} defaultValue={this.props.data.description} onChange={this.onChange} multiline autoAdjustHeight />
                    </Stack.Item>
                </Stack>
            </div>
        )
    }
    
}

export default ProjectSingle