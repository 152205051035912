import React from 'react';
import { mapStateToProps, connect } from 'containers/CountryContainer';
import { Country } from 'helpers/';
import CountryInline from './CountryInline';
import Loader from 'components/Loader';

class CountryFilterForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            countryList: [],
            loaded: false,
            selected: {}
        }

        Country.Load(this.props.dispatch);
        Country.Get(props.dispatch);
        
        this.onCheckboxClick = this.onCheckboxClick.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            ( 
                props.countryList &&
                props.countryList.length &&
                state.countryList.length === 0
            ) || props.getFilterData
        ) {
            let countryList = props.countryList.map((c) => ({...c, display: true}));
            return {
                countryList: props.countryList ? countryList : [],
                Filter: props.Filter ? props.Filter : state.Filter,
                loaded: props.countryList && props.countryList.length > 0,
                selected: props.getFilterData ? props.getFilterData('country') : state.selected
            }
        } 
        
        if (props.Filter !== state.Filter) return {
            Filter: props.Filter
        }
        
        return null;
    }
    
    onCheckboxClick(event, isChecked, id, title) {
        let selected = this.state.selected;
        if (!isChecked) delete selected[id];
        else selected[id] = title;
        this.setState({
            selected: selected
        })
        return this.props.onFilter ? this.props.onFilter('country', selected) : null;
    }
    
    render() {
        return this.state.loaded ? (<>
            {(
                this.state.countryList.map((c) => (
                    <CountryInline id={c.id} checkbox onCheckboxClick={this.onCheckboxClick} key={c.id} styles={{
                        wrapper: {
                            display: 'inline-block',
                            width: '33%',
                            paddingRight: 10,
                            paddingBottom: 10,
                            boxSizing: 'border-box',
                            overflow: 'hidden',        
                        }  
                    }} displayCondition={this.state.Filter} checkboxChecked={this.state.selected[c.id] ? true : false} />
                ))
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(CountryFilterForm);