import React from 'react'
import { Pivot, PivotItem, TextField, DefaultButton } from 'office-ui-fabric-react';

import { 
    Intelligence, 
    Photo,
    Scan,
    Video,
    Document,
    Media,
    Upload,
    Loader,
    Sources
} from 'components';
import { AssignmentInfo } from 'components/Assignments';

import API from 'actions/api';

import Navigation from 'actions/navigation';
import { connect } from 'react-redux';



import axios from 'axios';
import Config from 'config';

import CKEditor from 'helpers/CKEditor';

class AssignmentSources extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            data: props.sources ? props.sources : [],
            types: props.sources ? this.parseSources(props.sources) : [],
            loaded: props.sources ? true : false,
            selectedKey: this.props.hideInfo ? 1 : 'info',
            sources: {},
            sourcesToAdd: {},
            massAction: 'close'
        }
        
        this.renderSource = this.renderSource.bind(this);
        this.parseSources = this.parseSources.bind(this);
        this.getData = this.getData.bind(this);
        this.renderItemLink = this.renderItemLink.bind(this);
        this.ItemClick = this.ItemClick.bind(this);
        this.MenuClick = this.MenuClick.bind(this);
        this.LinkClick = this.LinkClick.bind(this);
        this.randomID = this.randomID.bind(this);
        this.updateSources = this.updateSources.bind(this);
        this.addSourcesToAssignment = this.addSourcesToAssignment.bind(this);
        this.clearSources = this.clearSources.bind(this);
        this.updateData = this.updateData.bind(this);
        this.addSingleSource = this.addSingleSource.bind(this);
        this.removeSingleSource = this.removeSingleSource.bind(this);
    }
    
    componentWillUnmount() {
        this.props.dispatch({
            type: 'UPLOAD_ACTION_BUTTON_HIDE'
        });
    }
    
    updateData(key, value) {
        if (this.props.updateAssignment) this.props.updateAssignment(key, value);
    }
    
    parseSources(sources) {
        let types = {};
        if (!sources) sources = [];
        const typeList = ['Intelligence', 'Photo', 'Scan', 'Document', 'Video'];
        sources.forEach((source) => {
            const x = source.source_id.split(':');
            if ( typeof(types[x[0]]) === 'undefined' ) types[x[0]] = [];
            source['real_id'] = x[1];
            source['type'] = x[0];
            types[x[0]].push(source);
        })
        let newTypes = {};
        typeList.forEach((type) => {
            if (typeof(types[type]) !== 'undefined') newTypes[type] = types[type];
        })
        return newTypes;
    }
    
    getData() {
        API.returnGet(`Assignments/edit/${this.state.id}?output=json`, true).then((result) => {
            const types = this.parseSources(result.data.Data.sources);
            this.setState({
                loaded: true,
                data: result.data.Data.sources,
                types: types
            });
            CKEditor.setSources(this.state.id, result.data.Data.sources);
            this.props.dispatch({
                type: 'UPLOAD_ACTION_BUTTON',
                payload: {
                    title: ' + Add to current assignment',
                    onClick: (data) => {
                        this.addFileID(data);
                    }
                }
            })
        });
    }
    
    componentDidMount() {
        if (!this.state.loaded) this.getData();
        else {
            this.props.dispatch({
                type: 'UPLOAD_ACTION_BUTTON',
                payload: {
                    title: ' + Add to current assignment',
                    onClick: (data) => {
                        this.addFileID(data);
                    }
                }
            })
        }
    }

    renderSource(type, source) {
        switch (type) {
            
            case 'Intelligence':
                return <Intelligence.ArticlePreview id={source.real_id} key={source.id} className={'PivotSource'} keywords />

            case 'Photo':
            case 'Scan':
            case 'Video':
            case 'Document':
                return <Media.Source id={source.real_id} type={source.type} key={source.id} className={'PivotSource'} />
            
            default:
                return <h3 key={source.id}>{type}: {source.id}</h3>
            
        }
    }
    
    ItemClick(e) {
        
    }
    
    randomID() {
        return new Date().getTime();
    }
    
    updateSources(sources) {
        this.setState({
            sourcesToAdd: sources
        })
    }
    
    addSourcesToAssignment() {
        let ss = {}
        this.state.sourcesToAdd.forEach((source) => {
            ss[source.MediaType + ':' + source.id] = source;
        })
        
        axios.post(Config.API_PATH + 'Assignments/AddSourcesFromJSON?output=json', {
            assignment_id: this.state.id,
            sources: ss
        }, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            this.setState({
                loaded: false
            }, () => {
                this.getData();    
                this.props.dispatch({
                    type: 'DRAFTS_SOURCE_CLEAR'
                });
            })
        });
    }
    
    clearSources() {
    }
    
    saveTitle(source, ev, value) {
        source.title_2 = value;
        if (this.props.onSaveSource) this.props.onSaveSource(source);
    }
    
    addFileID(data) {
        axios.post(Config.API_PATH + `Assignments/addSourceByFileID/${this.state.id}?output=json`, {
            file_id: data.id
        }, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            this.setState({
                loaded: false
            }, () => {
                this.getData();    
                this.props.dispatch({
                    type: 'UPLOAD_DELETE',
                    payload: {
                        id: data.id
                    }
                })
            })
        });
    }
    
    addSingleSource(source) {
        let ss = {};
        ss[source.MediaType + ':' + source.id] = source;
        axios.post(Config.API_PATH + 'Assignments/AddSourcesFromJSON?output=json', {
            assignment_id: this.state.id,
            sources: ss
        }, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            this.setState({
                loaded: false
            }, () => {
                this.getData(); 
                this.props.dispatch(Navigation.hidePagePopup());
                this.props.dispatch(Navigation.showConfirmation({
                    title: 'Add source',
                    text: 'Source added to assignment',
                    titleOK: 'OK',
                    hideCancel: true
                }));
            })
        });
    }
    
    removeSingleSource(data) {
        this.props.dispatch(Navigation.showConfirmation({
            title: 'Remove source',
            text: 'Remove source from assignment?',
            titleOK: 'OK',
            functionOK: () => {
                API.returnGet(`Assignments/removeSource/${data.id}`).then(() => {
                   this.setState({
                       loaded: false
                   }, () => {
                       this.getData();
                   }); 
                });
            },
            functionCancel: () => {}
        }));
    }
    
    MenuClick(e, item) {
        switch(item.key) {
            
            case 'addSourceIntelligence':
                this.props.dispatch(Navigation.showSlidePopup({
                    title: 'Add Intelligence',
                    children: <Sources.Add updateSources={this.updateSources} key={new Date().getTime()}><Intelligence.List /></Sources.Add>,
                    showSearch: false,
                    functionOK: this.addSourcesToAssignment,
                    functionCancel: this.clearSources,
                    titleOK: 'Add to assignment',
                    titleCancel: 'Close & Continue'
                }));
                break;
            case 'addSourcePhoto':
                this.props.dispatch(Navigation.showSlidePopup({
                    title: 'Add Photo',
                    children: <Sources.Add updateSources={this.updateSources}><Photo.List /></Sources.Add>,
                    showSearch: false,
                    functionOK: this.addSourcesToAssignment,
                    functionCancel: this.clearSources,
                    titleOK: 'Add to assignment',
                    titleCancel: 'Close & Continue'
                }));
                break;
                
            case 'addSourceScan':
                this.props.dispatch(Navigation.showSlidePopup({
                    title: 'Add Scan',
                    children: <Sources.Add updateSources={this.updateSources}><Scan.List /></Sources.Add>,
                    showSearch: false,
                    functionOK: this.addSourcesToAssignment,
                    functionCancel: this.clearSources,
                    titleOK: 'Add to assignment',
                    titleCancel: 'Close & Continue'
                }));
                break;
                
            case 'addSourceVideo':
                this.props.dispatch(Navigation.showSlidePopup({
                    title: 'Add Video',
                    children: <Sources.Add updateSources={this.updateSources}><Video.List /></Sources.Add>,
                    showSearch: false,
                    functionOK: this.addSourcesToAssignment,
                    functionCancel: this.clearSources,
                    titleOK: 'Add to assignment',
                    titleCancel: 'Close & Continue'
                }));
                break;
                
            case 'addSourceDocument':
                this.props.dispatch(Navigation.showSlidePopup({
                    title: 'Add Document',
                    children: <Sources.Add updateSources={this.updateSources}><Document.List /></Sources.Add>,
                    showSearch: false,
                    functionOK: this.addSourcesToAssignment,
                    functionCancel: this.clearSources,
                    titleOK: 'Add to assignment',
                    titleCancel: 'Close & Continue'
                }));
                break;
            
            case 'addExternal': 
                this.props.dispatch(Navigation.showPagePopup({
                    title: 'External Link',
                    children: <Upload.External onAdd={this.addSingleSource} onRemove={this.removeSingleSource} />,
                    showSearch: false,
                    titleOK: 'OK',
                    titleCancel: false
                }));
                break;
            
            default:
                break;
            
        }
    }

    renderItemLink(item, defaultRender) {
        const menuProps = this.props.hideEdit ? null : {
          items: [
            {
                key: 'header1',
                text: 'Add new source from database',
                itemType: 2,
                iconProps: {
                    className: 'fas fa-database fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addSourceIntelligence',
                text: 'Intelligence',
                iconProps: {
                    className: 'fal fa-newspaper fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addSourcePhoto',
                text: 'Photo',
                iconProps: {
                    className: 'fal fa-image fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addSourceScan',
                text: 'Scan',
                iconProps: {
                    className: 'fal fa-scanner-image fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addSourceVideo',
                text: 'Video',
                iconProps: {
                    className: 'fal fa-video fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addSourceDocument',
                text: 'Document',
                iconProps: {
                    className: 'fal fa-file fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'header2',
                text: 'Add other source',
                itemType: 2,
                iconProps: {
                    className: 'fas fa-globe-europe fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'addExternal',
                text: 'Add external',
                iconProps: {
                    className: 'fad fa-link fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            },
            {
                key: 'upload',
                text: 'Upload',
                iconProps: {
                    className: 'fad fa-upload fixedIconPadding',
                    styles: {
                        root: {
                            width: 20
                        }
                    }
                }
            }
          ],
          onItemClick: this.MenuClick
        };
        
        return this.props.hideEdit ? null : <DefaultButton primary iconProps={{className: 'fad fa-plus-circle'}} text="Add source" menuProps={menuProps} />
    }
    
    LinkClick(item) {
        let massAction = this.state.massAction;
        if (this.state.selectedKey === item.props.itemKey) {
            this.props.dispatch({
                type: 'SOURCES_MASS_ACTION',
                payload: {
                    action: massAction
                }
            });
        }
        this.setState({
            selectedKey: item.props.itemKey,
            massAction: massAction === 'open' ? 'close' : 'open'
        });
    }
    
    render() {
        return this.state.loaded ? (
            <>
                <div style={{display: 'flex', flexGrow: 0, width: '100%'}}>
                    <Pivot onLinkClick={this.LinkClick} headersOnly={true} className={'FullPivot'}>
                        { this.props.hideInfo ? null : (
                            <PivotItem headerText={'Info'} itemKey={'info'} itemIcon={'faInfo'} key={'info'} className={'PivotEntry'} /> ) }
                        {Object.keys(this.state.types).map((type, i) => (
                            <PivotItem headerText={`${type}`} itemKey={i+1} itemIcon={'faLink'} itemCount={this.state.types[type].length} key={type} className={`PivotEntry`} />
                        ))}
                        <PivotItem onRenderItemLink={this.renderItemLink} itemKey={'addSource'} key={'addSource'} />
                    </Pivot>
                </div>
                {( this.state.selectedKey === 'info' ? (
                    <AssignmentInfo assignment={this.props.assignment} updateData={this.updateData} />
                ) : null )}
                {Object.keys(this.state.types).map((type, i) => (
                    <div key={`List-${i}`} style={{flexGrow: 1, display: (i===this.state.selectedKey-1 ? 'flex' : 'none'), flexDirection: 'column', overflowY: 'auto', padding: 20, wordBreak: 'break-word'}}>    
                    {this.state.types[type].map((source) => (
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: 10}} key={`List-${i}-${source.id}`}>
                            {this.props.hideEdit ? (<h3>{type==='Intelligence' ? null : source.title}</h3>) : (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{display: 'flex', flexGrow: 1}}>
                                        <TextField defaultValue={source.title_2.length > 0 ? source.title_2 : source.title} onChange={this.saveTitle.bind(this, source)} />
                                    </div>
                                    <div style={{
                                        width: 50,
                                        display: 'flex',
                                        textAlign: 'center',
                                        justifyContent: 'center',
                                        color: 'red',
                                        cursor: 'pointer'
                                    }} onClick={this.removeSingleSource.bind(this, source)}>
                                        <i style={{display: 'block', margin: 'auto'}} className={'fas fa-lg fa-times-circle hoverIcon'} />
                                    </div>
                                </div>
                            )}
                            {this.renderSource(type, source)}
                        </div>
                    ))}
                    </div>
                ))}
            </>
        ) : <Loader.Big />
    }
    
}

export default connect()(AssignmentSources);