import React from 'react';
import Elements from 'elements';
import RobotLinks from 'components/RobotLinks';
import Loader from 'components/Loader';
import InlineKeywords from './IntelligenceInlineKeywords';
import API from 'actions/api';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

class IntelligenceArticlePreview extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            article: props.article ? ( props.article._source ? props.article._source : props.article ): {},
            loaded: props.article ? true : false,
            collapsed: this.props.collapsed ? this.props.collapsed : false,
            oldProps: props
        }
        
        this.loadArticle = this.loadArticle.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
    }
    
    loadArticle() {
        API.returnGet(`Intelligence/ID/${this.props.id}?output=json`, true).then((result) => {
            this.setState({
                loaded: true,
                article: result.data.Data
            });
        });
    }
    
    componentDidMount() {
        if (!this.state.loaded) this.loadArticle();
    }
    
    toggleCollapsed() {
        this.props.dispatch({
            type: 'SOURCES_TOGGLE_SINGLE',
            payload: {
                id: this.props.id,
                collapsed: !this.state.collapsed
            }
        })
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (typeof(props.collapsed) !== 'undefined') return { collapsed: props.collapsed }
    }
    
    render() {
        const { article } = this.state;
        return this.state.loaded ? (
            <div className={this.props.className ? this.props.className : ''}>
                <div style={this.props.className ? {} : {paddingBottom: '5px', position: 'sticky', top: 0, background: 'white'}}>
                    <div style={{boxSizing: 'border-box', paddingTop: '10px', paddingBottom: '10px', textAlign: 'right', display: 'flex'}}>
                        <div style={{display: 'flex', width: 'auto', flexGrow: 0}}>
                            <Elements.DateTime stamp={article.timestamp} />
                            { this.state.article.character_count ? ( <span style={{display: 'inline-block', marginLeft: 5}}>{' | '}<NumberFormat thousandSeparator={'.'} decimalSeparator={','} value={this.state.article.character_count} displayType={'text'} /> {'characters'}</span> ) : null }
                        </div>
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end'}}>
                            <RobotLinks.Inline id={article.link_real_id ? article.link_real_id : article.link_id} />
                        </div>
                    </div>
                    <h2 style={{marginTop: 0, marginBottom: 0, cursor: 'pointer'}} onClick={this.toggleCollapsed}>{article.title}</h2>
                    <a href={article.original_link} target={'blank'} style={{display: 'block', marginBottom: '10px', wordWrap: 'break-word'}} autoFocus={false} tabIndex={'-1'} rel={'nofollow noopener noreferrer'}>{decodeURIComponent(article.original_link)}</a>
                </div>
                { this.props.keywords ? (
                <div style={{boxSizing: 'border-box', paddingTop: '10px', paddingBottom: '10px', display: this.state.collapsed ? 'none' : 'flex'}}>
                    <InlineKeywords id={this.state.article.id} />
                </div>
                ): null}
                {(
                    article.image && article.image.length > 7 ?
                    <img src={article.image} style={{float: 'left', paddingRight: 10, paddingBottom: 10, width: this.props.smallImage ? 256 : '100%', display: (this.state.collapsed ? 'none' : (this.props.smallImage ? 'inline-block' : 'block'))}} alt={''} /> : ''
                )}
                <div className={'font-reading'} style={{whiteSpace: 'pre-wrap', margin: 0, textAlign: 'justify', display: (this.state.collapsed ? 'none' : 'block')}} dangerouslySetInnerHTML={{
                    __html: article.text && article.text.trim()
                }} >
                </div>
            </div>
        ) : <Loader.Big />;
    }
    
}


export default connect((state, ownProps) => {
    if (state.sources) {
        if (state.sources.massAction) {
            return {
                collapsed: state.sources.massAction==='close'
            }
        } 
        else if (state.sources.singleAction && state.sources.singleAction===ownProps.id) {
            return {
                collapsed: state.sources.collapsed
            }
        }
    } 
    
    return {}
})(IntelligenceArticlePreview);