import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    let result = {}
    
    if ( typeof(state.navigation) !== 'undefined' ) {
        result.navigation = state.navigation
    }
    
    return result;
};

export { mapStateToProps, connect }