import React from 'react';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';

export default class extends React.Component {

    render() {
        return (
            <CommandBarButton className={'marginRight22'}>
                <i className={'fas fa-envelope'} />
            </CommandBarButton>
        );
    }
    
}