import React from 'react'

import { connect, mapStateToProps } from 'containers/UploadContainer'
import { 
    Paste as UploadPaste, 
    SingleBig as UploadSingleBig 
} from 'components/Upload';

class UploadBigWindow extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            uploads: props.uploads ? props.uploads : {},
            progress: 0
        }
        
        this.updateProgress = this.updateProgress.bind(this);
        
        this.onPasteText = this.onPasteText.bind(this);
        this.onPasteLink = this.onPasteLink.bind(this);
        this.onPasteFiles = this.onPasteFiles.bind(this);
        
    }
    
    updateProgress(id, progress) {
        let totalProgress = 0;
        let uploads = this.state.uploads;
        const keys = Object.keys(uploads);
        const total = keys.length * 100;
        uploads[id].progress = progress;
        keys.forEach((key) => {
            totalProgress += uploads[key].progress 
        });
        this.setState({
            progress: (totalProgress / total) * 100,
            uploads: uploads
        })
    }
    
    static getDerivedStateFromProps = (props, state) => { 

        if ( props.uploads || props.totalProgress ) return {
            uploads: typeof(props.uploads) !== 'undefined' ? props.uploads : state.uploads,
            progress: typeof(props.totalProgress) !== 'undefined' ? props.totalProgress : state.progress
        }
        
    }
    
    onPasteText(text) {
        
    }
    
    onPasteLink(link) {
        
        this.props.dispatch({
            type: 'UPLOAD_PASTE_LINK',
            payload: {
                link: link
            }
        })
    }
    
    onPasteFiles(files) {
        
        this.props.dispatch({
            type: 'UPLOAD_PASTE_FILES',
            payload: {
                files: files
            }
        })
    }
    
    render() {
        return (
            <div style={{
                display: 'flex',
                flexGrow: 1,
                height: '100%',
                flexDirection: 'column'
            }}>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto'
                }}>
                    <UploadPaste 
                        onPasteText={this.onPasteText}
                        onPasteLink={this.onPasteLink}
                        onPasteFiles={this.onPasteFiles}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column'
                }}>
                    {Object.keys(this.state.uploads).map((uid) => (<UploadSingleBig upload={this.state.uploads[uid]} key={uid} />))}
                </div>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(UploadBigWindow)