import React from 'react';
import { mapStateToProps, connect } from 'containers/LanguageContainer';
import { Language } from 'helpers/';
import Loader from 'components/Loader';
import { Nav } from 'office-ui-fabric-react/lib/Nav';

class LanguageTree extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            languageList: [],
            Filter: '',
            loaded: false,
            selected: {}
        }

        Language.Load(this.props.dispatch);
        Language.Get(props.dispatch);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.languageList &&
            props.languageList.length &&
            state.languageList.length === 0
        ) {
            return {
                languageList: props.languageList ? props.languageList : [],
                loaded: true
            }
        } 
        

        return null;
    }

    render() {
        return this.state.loaded ? (<>
            {(
                <Nav
                  styles={{ root: { width: 300 } }}
                  groups={[
                    {
                        links: [
                            {
                                name: 'Language',
                                links: this.state.languageList.map((language) => ({
                                    name: language.name
                                }))
                            }
                        ]
                    }
                  ]}
                />
            )}
        </>) : <Loader.Inline />
    }
    
}

export default connect(mapStateToProps)(LanguageTree);