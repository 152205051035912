import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';
import Country from 'components/Country';
import User from 'components/User';
import Language from 'components/Language';
import Category from 'components/Category';
import Elements from 'elements';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import RobotLinksStats from './RobotLinksStats'

export default class extends React.Component {
    
    static priorities = {
        '0': 'None',
        '1': 'Low',
        '2': 'Medium',
        '3': 'Hight',
        '4': 'Extreme'
    }
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: null
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Link',
                    onRender: (item) => {
                        return (<Text variant={'medium'} nowrap block>{item.title}</Text>)                
                    },
                    minWidth: 250,
                    maxWidth: 350
                },
                {
                    key: 'media_type',
                    name: 'Type',
                    fieldName: 'media_type',
                    maxWidth: 100
                },
                {
                    key: 'country',
                    name: 'Country',
                    fieldName: 'country',
                    minWidth: 150,
                    onRender: (item) => <Country.Inline id={item.country} />
                },
                {
                    key: 'category',
                    name: 'Category',
                    fieldName: 'category',
                    minWidth: 100,
                    onRender: (item) => <Category.Inline id={item.category} />
                },
                {
                    key: 'language',
                    name: 'Language',
                    fieldName: 'language',
                    minWidth: 80,
                    onRender: (item) => <Language.Inline id={item.language} />
                },
                {
                    key: 'access',
                    name: 'Access',
                    onRender: (item) => (<div style={{textAlign: 'center'}}><Elements.DateTime stamp={item.access_time} /></div>),
                    maxWidth: 80
                },
                {
                    key: 'update',
                    name: 'Update',
                    onRender: (item) => (<div style={{textAlign: 'center'}}><Elements.DateTime stamp={item.update_time} /></div>),
                    maxWidth: 80
                },
                {
                    key: 'interval',
                    name: 'Interval',
                    onRender: (item) => (`${item.interval}m`),
                    maxWidth: 200
                },
                {
                    key: 'publish',
                    name: 'Publish',
                    onRender: (item) => (`${item.publish_interval}h`),
                    maxWidth: 80
                },
                {
                    key: 'modify',
                    name: 'Modify',
                    onRender: (item) => {
                        return (
                            <div className={'text-center'}>
                                <User.Inline id={item.last_modify_user} />
                                <div><Elements.DateTime stamp={item.last_modify_time} /></div>
                            </div>
                        )
                    },
                    maxWidth: 200
                },
                {
                    key: 'status',
                    name: 'Status',
                    fieldName: 'status',
                    maxWidth: 100
                }
            ]
        }
        
    }
    
    render() {
        return (
            <Stack vertical styles={{root:{height: '100%'}}}>
                <Stack.Item styles={{root:{height: '100px !important'}}} grow={0}>
                    <RobotLinksStats />
                </Stack.Item>
                <Stack.Item grow={1}>
                    <Table.List APIModule={'Links/index'} columns={this.state.columns} />
                </Stack.Item>
            </Stack>
        )
    }
    
}