import Big from './LoaderBig';
import Page from './LoaderPage';
import NoResults from './NoResults';
import Inline from './LoaderInline';
import Circle from './LoaderCircle';
import Retry from'./LoaderRetry';

export default { 
    Big,
    Page,
    NoResults,
    Inline,
    Circle,
    Retry
}

export {
    Big,
    Page,
    NoResults,
    Inline,
    Circle,
    Retry   
}