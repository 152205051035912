import React from 'react';

import Table from 'components/Table';
import Navigation from 'components/Navigation';

import { Text } from 'office-ui-fabric-react/lib/Text';

var moment = require('moment');

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'ID',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.title}</Text>
                                <pre style={{margin: 0}}>{item.id} - {item.note}</pre>
                            </div>
                        )                
                    }
                },
                {
                    key: 'type',
                    name: 'Type',
                    fieldName: 'type'
                },
                {
                    key: 'keywords',
                    name: 'Keywords',
                    onRender: (item) => (item.keywords ? item.keywords.length : 0),
                    className: 'text-center'
                },
                {
                    key: 'sent',
                    name: 'Sent',
                    onRender: (item) => (item.sent_stamp != null ? moment(item.sent_stamp).format('DD.MM.YYYY hh:mm') : ''),
                    maxWidth: 150
                },
                {
                    key: 'created',
                    name: 'Created',
                    onRender: (item) => (item.created_stamp != null ? moment(item.created_stamp).format('DD.MM.YYYY hh:mm') : ''),
                    maxWidth: 150
                },
                {
                    key: 'modified',
                    name: 'Modified',
                    onRender: (item) => (item.last_change_stamp != null ? moment(item.last_change_stamp).format('DD.MM.YYYY hh:mm') : ''),
                    maxWidth: 150
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: '',
                title: ''
            }
        })
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'ResearchKeywords/index'}
                    infinite={true}  
                    columns={this.state.columns}
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide}>
                    <h1>{this.state.selectedItem.title}</h1>
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}