import React from 'react';
import { mapStateToProps, connect } from 'containers/PopupContainer';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import Navigation from 'actions/navigation';

class PagePopup extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            show: props.children ? true : false,
            title: 'POPUP',
            functionOK: () => {
                
            },
            functionCancel: () => {
                
            },
            children: props.children ? props.children : '',
            Filter: ''
        }
        

        
        this.renderTitle = this.renderTitle.bind(this);
        this.functionOK = this.functionOK.bind(this);
        this.functionCancel = this.functionCancel.bind(this);
    }
    
    functionOK() {
        this.props.dispatch(Navigation.hidePagePopup());
        this.state.functionOK();
    }
    
    functionCancel() {
        this.setState({
            children: ''
        }, function() {
            window.location.hash='';
            this.props.dispatch(Navigation.hidePagePopup());
            this.state.functionCancel();    
        })
    }
    
    renderTitle() {
        return ( this.state.title && this.state.title.length ) || this.state.showFilter ? (
            <span style={{
                display: 'flex',
                width: '100%'
            }}>
                {this.state.title && this.state.title.length ? (<span style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                {this.state.title}
                </span>) : null}
                {this.state.showFilter ? (
                    <span style={{
                        display: 'flex',
                        width: 673,
                        flexGrow: 0,
                        flexShrink: 0,
                        textAlign: 'right'
                    }}>
                        <SearchBox
                            placeholder={'Filter'}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            value={this.state.Filter}
                            onChanged={this.filterFilter}
                            onSearch={this.filterFilter}
                            onClear={this.clearFilter}
                            onEscape={this.clearFilter}
                        />
                    </span>
                ) : null }
            </span>
        ) : null
    }
    
    static getDerivedStateFromProps = (props, state) => {
         if (
            typeof props.navigation !== 'undefined' &&
            typeof props.navigation.PagePopup !== 'undefined'
        ) {
            if ( props.navigation.PagePopup.show === false ) 
                return { 
                    show: false, 
                    Filter: {
                        SearchBox: ''
                    },
                    children: null
                }
            else return props.navigation.PagePopup;
        }
        
        return null;
    }
    
    render() {
        const titleOK = this.state.titleOK ? this.state.titleOK : 'OK';
        const titleCancel = this.state.titleCancel ? this.state.titleCancel : 'Cancel';
        
        return (
            <Dialog
                hidden={!this.state.show}
                shiftOnReduce={true}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: this.renderTitle(),
                  styles: {
                    title: {
                        paddingRight: 24,
                        paddingTop: 24,
                        paddingBottom: 0
                    }  
                  }
                }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  className: 'PagePopup',
                  styles: { 
                    main: { 
                        maxWidth: 'calc(100vw - 20px) !important',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    },
                    scrollableContent: {
                        maxWidth: 'calc(100vw - 20px)',
                        width: 'calc(100vw - 20px)',
                        height: 'calc(100vh - 20px)'
                    }
                }}}
            >
                {this.state.children}
                <DialogFooter>
                  <PrimaryButton onClick={this.functionOK} text={titleOK} />
                  <DefaultButton onClick={this.functionCancel} text={titleCancel} />
                </DialogFooter>
            </Dialog>
        )
    }
    
}

export default connect(mapStateToProps)(PagePopup);