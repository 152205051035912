import React from 'react';

import Table from 'components/Table';
import Navigation from 'components/Navigation';

import { Text } from 'office-ui-fabric-react/lib/Text';

var moment = require('moment');

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                name: ''
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Wiki',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.title}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                },
                {
                    key: 'type',
                    name: 'Type',
                    fieldName: 'type'
                },
                {
                    key: 'created',
                    name: 'Created',
                    onRender: (item) => (moment(item.created).format('DD.MM.YYYY hh:mm'))
                },
                {
                    key: 'modified',
                    name: 'modified',
                    onRender: (item) => (moment(item.modified).format('DD.MM.YYYY hh:mm'))
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                name: ''
            }
        })
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Wiki/index'}
                    infinite={true} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide}>
                    <h1>{this.state.selectedItem.type}: {this.state.selectedItem.title}</h1>
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}