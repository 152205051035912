import { 
    createStore, 
    applyMiddleware, 
    combineReducers, 
    compose 
} from 'redux';
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';

import apiReducer from './reducers/apiReducer';
import navigationReducer from './reducers/navigationReducer';
import searchReducer from './reducers/searchReducer';
import countryReducer from './reducers/countryReducer';
import usersReducer from './reducers/usersReducer';
import languageReducer from './reducers/languageReducer';
import categoryReducer from './reducers/categoryReducer';
import linksReducer from './reducers/linksReducer';
import draftsReducer from './reducers/draftsReducer';
import realtimeReducer from './reducers/realtimeReducer';
import uploadReducer from './reducers/uploadReducer';
import sourcesReducer from './reducers/sourcesReducer';

const enableReduxTools = false;

let enhancer = null

if (enableReduxTools) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(
        applyMiddleware(
            logger,
            promise
        )
    );
} else {
    enhancer = applyMiddleware(
        promise
    );
}

export default createStore(
    combineReducers({
        api: apiReducer,
        navigation: navigationReducer,
        search: searchReducer,
        drafts: draftsReducer,
        r_country: countryReducer,
        r_user: usersReducer,
        r_language: languageReducer,
        r_category: categoryReducer,
        r_links: linksReducer,
        realtime: realtimeReducer,
        upload: uploadReducer,
        sources: sourcesReducer
    }),
    enhancer
)