import React from 'react';

export default class extends React.Component {
  
    constructor(props) {

        super(props);

        this.state = {
            backgroundImage: 'none',
            backgroundPosition: '0% 0%'
        }
        
        this.handleMouseMove = this.handleMouseMove.bind(this);   
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleMouseMove(e) {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        this.setState({
            backgroundImage: `url(${this.props.data.fileData.location})`,
            backgroundPosition: `${x}% ${y}%` 
        })
    }
    
    handleBlur(e) {
        this.setState({
            backgroundImage: 'none'
        })
    }

    render() {
        
        return (

            <figure className={'zoomImage'} onMouseMove={this.handleMouseMove} onMouseLeave={this.handleBlur} style={this.state}>
                <img alt={''} src={this.props.data.fileData.location} />
            </figure>            
         
        )
        
    }

}