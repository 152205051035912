import React from 'react';
import { Formik, Form } from 'formik';
import { TextField } from 'office-ui-fabric-react';
import API from 'actions/api';
import * as Yup from 'yup';
import { Country } from 'components';

const moment = require('moment');

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            data: this.props.data ? this.props.data : {},
            countries: []
        }
    }
    
    componentDidMount() {
        if (this.state.countries.length === 0) {
            API.returnGet(`Country/index?output=json`, false).then((result) => {
                let ctr = result.data.Data.map((c) => ({
                    key: c.id,
                    text: c.name
                }))
                this.setState({
                    countries: ctr
                });
            });
        }
    }
    
    render() {
        
        const keys = [
            'title',
            'description',
            'content',
            'group',
            'country',
            'author',
            'date',
            'category',
            'page',
            'media',
            'intelligence_id',
            'original_link',
            'location'
        ];
        
        let data = {};
        keys.forEach((key) => {
            if ( this.state.data && this.state.data.fileMeta && this.state.data.fileMeta[key] ) data[key] = this.state.data.fileMeta[key];
            else if ( this.state.data[key] ) data[key] = this.state.data[key];
        });
        
        return (
            <Formik
                initialValues={{
                    title: data.title ? data.title : '',
                    description: data.description ? data.description : '',
                    content: data.content ? data.content : (data.meta && data.meta.content ? data.meta.content : ''),
                    group: data.group ? data.group : (data.meta && data.meta.group ? data.meta.group : ''),
                    country: data.country ? data.country : '',
                    author: data.author ? data.author : (data.meta && data.meta.author ? data.meta.author : ''),
                    date: data.date ? moment(data.date).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY'),
                    category: data.category ? data.category : (data.meta && data.meta.category ? data.meta.category : ''),
                    page: data.page ? data.page : (data.meta && data.meta.page ? data.meta.page : ''),
                    media: data.media ? data.media : (data.meta && data.meta.media ? data.meta.media : ''),
                    intelligence_id: data.intelligence_id ? data.intelligence_id : (data.meta && data.meta.intelligence_id ? data.meta.intelligence_id : ''),
                    original_link: data.original_link ? data.original_link : (data.meta && data.meta.link ? data.meta.link : ''),
                    location: data.location ? data.location : (data.meta && data.meta.location ? data.meta.location : '')
                }}
                validationSchema={
                    Yup.object().shape({
                    	title: Yup.string().min(5, 'Minimum 5 characters').required('Title is required'),
                    	country: Yup.string().required('Country is required'),
                    	date: Yup.string().required('Date is required')
                    })
                }
            >
                {({ values, errors, touched, handleChange, handleSubmit, validateForm, setFieldValue }) => {
                
                    if (this.props.updateForm) this.props.updateForm(values);
                
                    let setFieldValueDO = (field, value) => {
                        setFieldValue(field, value);
                        if (this.props.onModifyData) this.props.onModifyData(field, value, errors);             
                    }

                    let handleChangeDO = (target) => {
                        handleChange(target);
                        if (this.props.onModifyData)this.props.onModifyData(target.target.name, target.target.value, errors);             
                    }
                
                    return (
                        <Form style={{width: '100%'}}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                            
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    padding: 10,
                                    boxSizing: 'border-box'
                                }}>
                                    <TextField 
                                        label="Title" 
                                        name="title" 
                                        errorMessage={errors.title ? errors.title : null}
                                        onChange={handleChangeDO}
                                        value={values.title}
                                    />
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Description" 
                                            name="description" 
                                            errorMessage={errors.description ? errors.description : null}
                                            onChange={handleChangeDO}
                                            value={values.description}
                                            multiline
                                            rows={5}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Content" 
                                            name="content" 
                                            errorMessage={errors.content ? errors.content : null}
                                            onChange={handleChangeDO}
                                            value={values.content}
                                            multiline
                                            rows={5}
                                        />
                                    </div>
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box',
                                        flexBasis: '50%'
                                    }}>
                                        <TextField 
                                            label="Group" 
                                            name="group" 
                                            errorMessage={errors.group ? errors.group : null}
                                            onChange={handleChangeDO}
                                            value={values.group}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box',
                                        flexBasis: '50%'
                                    }}>
                                        <Country.Dropdown
                                            name={'country'}
                                            onChange={(e,o) => {setFieldValueDO('country', o.key)}}
                                            errorMessage={errors.country ? errors.country : null}
                                            selectedItem={values.country}
                                        />
                                    </div>
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Author" 
                                            name="author" 
                                            errorMessage={errors.author ? errors.author : null}
                                            onChange={handleChangeDO}
                                            value={values.author}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Date" 
                                            name="date" 
                                            errorMessage={errors.date ? errors.date : null}
                                            onChange={handleChangeDO}
                                            value={values.date}
                                        />
                                    </div>
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Category" 
                                            name="category" 
                                            errorMessage={errors.category ? errors.category : null}
                                            onChange={handleChangeDO}
                                            value={values.category}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Page" 
                                            name="page" 
                                            errorMessage={errors.page ? errors.page : null}
                                            onChange={handleChangeDO}
                                            value={values.page}
                                        />
                                    </div>
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Media" 
                                            name="media" 
                                            errorMessage={errors.media ? errors.media : null}
                                            onChange={handleChangeDO}
                                            value={values.media}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Intelligence article" 
                                            name="intelligence_id" 
                                            errorMessage={errors.intelligence_id ? errors.intelligence_id : null}
                                            onChange={handleChangeDO}
                                            value={values.intelligence_id}
                                        />
                                    </div>
                                </div>
                                
                                <div style={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Original link" 
                                            name="original_link" 
                                            errorMessage={errors.original_link ? errors.original_link : null}
                                            onChange={handleChangeDO}
                                            value={values.original_link}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        padding: 10,
                                        boxSizing: 'border-box'
                                    }}>
                                        <TextField 
                                            label="Location" 
                                            name="location" 
                                            errorMessage={errors.location ? errors.location : null}
                                            onChange={handleChangeDO}
                                            value={values.location}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        )
        
    }
    
}