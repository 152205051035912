import React from 'react';

import { mapStateToProps, connect } from 'containers/SearchContainer';

import { SearchBox, TooltipHost } from 'office-ui-fabric-react';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { registerIcons } from '@uifabric/styling';

import Search from 'actions/search';
import Navigation from 'actions/navigation';

import Filter from 'components/Filter';
import API from 'actions/api';

import { withRouter } from 'react-router-dom';

registerIcons({
    icons: {
        'icon-filter': <i className={'fad fa-filter'} />,
        'icon-filter-active': <i className={'fas fa-filter'} />
    }
});

class MainBarSearch extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            FilterData: {},
            savedSearches: {},
            buttonmenu: false
        }
        
        this.showFilter = this.showFilter.bind(this);
        this.button = this.button.bind(this);
        this.loadSavedSearches = this.loadSavedSearches.bind(this);
    }
    
    loadSavedSearches() {
        API.returnGet('Filter/getAllSavedSearches?output=json').then((result) => {
            if (result.data.Data && result.data.Data.length > 0) {
                const searches = result.data.Data;
                let types = [];
                searches.forEach((s) => {
                   if (types.indexOf(s.page) === -1) types.push(s.page); 
                });
                let menu = types.map((type) => ({
                    key: type,
                    text: type,
                    subMenuProps: {
                        items: searches.filter((s) => (s.page === type)).map((s) => ({
                            key: s.id,
                            text: s.title,
                            onClick: () => {
                                let filter = {};
                                let keys = Object.keys(s.filter);
                                keys.forEach((key) => {
                                    try {
                                        if (key !== 'q') {
                                            filter[key] = {};
                                            s.filter[key].forEach((id) => {
                                                filter[key][id] = id;
                                            })
                                        } else {
                                            filter['query'] = s.filter['q'];
                                        }
                                        filter['searchTitle'] = s.title;
                                    } catch (e) {
                                        //console.log('Unable to parse filter', key, s.filter[key]);
                                    }
                                });
                                this.props.history.push(`/${s.page}`);
                                setTimeout(() => {
                                    this.props.dispatch({
                                        type: 'SET_FILTER_DATA',
                                        payload: filter
                                    });    
                                }, 500);
                            }
                        }))
                    }
                }));
                this.setState({
                    savedSearches: searches,
                    buttonmenu: {
                        items: menu
                    }
                })     
            }
            
        });
    }
    
    componentDidMount() {
        if (this.state.buttonmenu === false) {
            this.loadSavedSearches();
        }
    }
    
    static getDerivedStateFromProps = (props, state) => {
        let r = {
            
        }
        
        try {
            if (props.navigation.Search.clearSearch === true) r['query'] = '';
        } catch (e) {

        }
        
        try {
            if (props.search.Search.clearSearch === true) r['query'] = '';
        } catch (e) {

        }
        
        try {
            if (props.navigation.Popup.FilterData) r['FilterData'] = props.navigation.Popup.FilterData;
        } catch (e) {
            r['FilterData'] = state.FilterData;
        }
        
        return r;
    }
    
    onSearch(e) {
        let filter = this.state.FilterData;
        filter['query'] = e;
        this.setState({
            FilterData: filter
        }, () => {
            this.props.dispatch(
                Search.doSearch(e)
            );
        });
    }
    
    showFilter() {
        this.props.dispatch(Navigation.showPopup({
            title: 'Filter',
            children: <Filter.Form />,
            showSearch: true
        }));
    }
    
    button() {
        const keys = Object.keys(this.state.FilterData);
        let count = 0;
        
        keys.forEach((key) => {
           const subKeys = Object.keys(this.state.FilterData[key]);
           if (subKeys.length > 0) count++;
        });
        
        if (count) return {
            text: `Filter (${count})`,
            className: 'btn-filterActive',
            iconClass: 'icon-filter-active'
        }
        else return {
            text: 'Filter',
            className: 'btn-filter',
            iconClass: 'icon-filter'
        }
    }
    
    render() {
        const button = this.button();
        return (
            <>
                <CommandBarButton 
                    styles={{
                        root: {
                            marginLeft: 300
                        }
                    }}
                    className={'ButtonTransparent'}
                >
                    <SearchBox 
                        styles={{
                            root: {
                                width: 450
                            }
                        }}
                        placeholder="Search" 
                        iconProps={{ iconName: 'Filter' }} 
                        onSearch={this.onSearch.bind(this)}
                        onClear={this.onSearch.bind(this, '')}
                        value={this.state.FilterData.query ? this.state.FilterData.query : this.state.query}
                    />  
                </CommandBarButton>
                <TooltipHost
                    content={ this.state.FilterData.searchTitle ? 'Saved Search: ' + this.state.FilterData.searchTitle : '' }
                >
                    <CommandBarButton
                        split
                        text={button.text}
                        onClick={this.showFilter}
                        iconProps={{ iconName: button.iconClass }}
                        className={button.className}
                        menuProps={this.state.buttonmenu}
                    />
                </TooltipHost>
            </>
        )
    }

}

export default connect(mapStateToProps)(withRouter(MainBarSearch));