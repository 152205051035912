import { connect } from 'react-redux';

const mapStateToProps = (APIModule) => ((state, ownProps) => {
    //Check for reload data
    if (
        state.navigation &&
        state.navigation.RELOAD_DATA &&
        state.navigation.RELOAD_DATA === true 
    ) return {
        RELOAD_DATA: true
    }
    
    let result = {
        FilterData: {}
    };
    //If APIModule is passed in props, use that
    if (
        typeof ownProps.APIModule !== 'undefined'
    ) APIModule = ownProps.APIModule;
    
    //Return fetched data, only if it is for the current module(s)
    if (
        typeof state.api !== 'undefined' &&
        typeof state.api.result !== 'undefined' &&
        typeof state.api.result.Module !== 'undefined' &&
        typeof state.api.result.Function !== 'undefined' &&
        (
            APIModule.indexOf( state.api.result.Module + '/' + state.api.result.Function ) > -1 ||
            state.api.result.Module + '/' + state.api.result.Function === APIModule
        )
    ) {
        result.data = {};
        result.data[state.api.result.Module + '/' + state.api.result.Function] = state.api.result.Data;
        result.MyData = state.api.result.Data;
        result.result = state.api.result;
    }
    
    //If page is being changed, empty the data
    if (
        typeof state.navigation.pageRefresh !== 'undefined' && 
        state.navigation.pageRefresh === true
    ) result.data = [];

    //If search has been executed, pass it back to the component
    if (
        typeof(state.search.Search) != undefined
    ) result.Search = state.search.Search;

    if (state.navigation && state.navigation.Popup && state.navigation.Popup.FilterData && Object.keys(state.navigation.Popup.FilterData).length > 0) {
        result.FilterData = state.navigation.Popup.FilterData
    }

    return result;
});

export { mapStateToProps, connect }