import API from '../actions/api';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    let User = {};
    let Realtime  = {};
    if (typeof state.api.User !== 'undefined') {
        User = state.api.User;
    }
    if (typeof state.realtime !== 'undefined') {
        Realtime = state.realtime
    }
    return {
        data: state,
        User: User,
        Realtime: Realtime
    }
};
    
const mapDispatchToProps = (dispatch) => {
    return {
        API: () => {
            dispatch(API.Get('Test'))
        },
        Login: () => {
            dispatch(API.Login())
        },
        Logout: () => {
            dispatch(API.Logout())
        }
    }
};

export { mapStateToProps, mapDispatchToProps, connect }