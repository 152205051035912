import React from 'react';
import Loader from 'components/Loader';
import API from 'actions/api';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Icon } from 'office-ui-fabric-react/lib/Icon';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            tag: null,
            loaded: false,
            id: props.id
        }
        
        if (props.data) {
            this.state = {
                ...this.state,
                tag: props.data,
                loaded: true
            }
        }
        
        this.getTags = this.getTags.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderText = this.renderText.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (props.data.id !== state.tag.id) {
            return {
                tag: props.data
            }
        }
        return null;
    }
    
    getTags() {
        API.returnGet(`ResearchKeywords/getTag/${this.state.id}?output=json`).then((result) => {
            this.setState({
                tag: result.data.Data,
                loaded: true
            })
        });
    }
    
    componentDidMount() {
        if (this.state.loaded === false) this.getTags();
    }
    
    renderCheckbox() {
        return (
            <Checkbox 
                styles={{
                    root: {
                        display: 'flex-inline'
                    }
                }} 
                onRenderLabel={this.renderText}
                onChange={this.onCheckboxChange}
                checked={this.props.checkboxChecked}
            />
        )
    }
    
    renderText() {
        const iconName = this.state.tag.calculated_type ? `icon-${this.state.tag.calculated_type.replace(/ /, '').toLowerCase()}` : 'icon-';
        return (
            <>
                {!this.props.hideIcon || this.props.hideIcon === false ? <Icon iconName={iconName} /> : ''}
                <span>{this.state.tag.title}</span>
            </>
        );
    }
    
    onCheckboxChange(event, isChecked) {
        return this.props.onCheckboxClick ? this.props.onCheckboxClick(event, isChecked, this.props.id, this.state.tag.title) : false;
    }
    
    render() {
        if (this.state.loaded === false) return <Loader.Inline />
        else return (
            <div title={`${this.state.tag.calculated_type} - ${this.state.tag.note}`} style={{display: 'inline-flex', alignItems: 'center', padding: 5}}>
                {
                    this.props.checkbox && this.props.checkbox !== false ? this.renderCheckbox() : this.renderText()
                }
            </div>
        )
    }
    
}