import React from 'react';

import { Loader, Assignments } from 'components';
import { List } from 'office-ui-fabric-react/lib/List';
import API from 'actions/api';
import ErrorHandler from 'components/Main/ErrorHandler';

class AssignmentsSmallList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            loaded: false,
            assignments: []
        }
        
        this.loadAssignments = this.loadAssignments.bind(this);
        this.onRenderRow = this.onRenderRow.bind(this);
        this.onClick = this.onClick.bind(this);
    }
    
    loadAssignments() {
        API.returnGet('Assignments/addSourcesToAssignment?output=json').then((result) => {
            this.setState({
                loaded: true,
                assignments: result.data.Data.Assignments
            }) 
        });
    }
    
    componentDidMount() {
        if (!this.state.loaded) this.loadAssignments();
    }
    
    onClick(assignment) {
        if (this.props.onClick) this.props.onClick(assignment);
    }
    
    onRenderRow(row) {
        return (
            <ErrorHandler>
                <Assignments.SingleAssignmentPreviewSmall assignment={row} onClick={this.onClick} />
            </ErrorHandler>
        )
    }
    
    render() {
        if (!this.state.loaded) return <Loader.Big />
        else {
            return (
                <List style={{width: '100%'}} items={this.state.assignments} onRenderCell={this.onRenderRow} />
            )
        }
    }
    
}

export default AssignmentsSmallList;