import React from 'react';
import DateRangePicker from 'react-daterange-picker';

export default class extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            states: null
        }
        
        this.handleSelect = this.handleSelect.bind(this);
    }
    
    handleSelect(range, states) {
        this.setState({
            value: range,
            states: states
        });
    }
    
    render() {
        return (
            <>
                <DateRangePicker
                    firstOfWeek={1}
                    numberOfCalendars={2}
                    selectionType='range'
                    value={this.state.value}
                    onSelect={this.handleSelect}
                />
            </>
        );
    }
    
}