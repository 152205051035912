import React from 'react';
import { mapStateToProps, connect } from 'containers/PopupContainer';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';

import Navigation from 'actions/navigation';

const debounce = require('debounce');

class Popup extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            show: false,
            title: 'POPUP',
            functionOK: () => {
                
            },
            functionCancel: () => {
                
            },
            children: '',
            Filter: ''
        }
        
        this.renderTitle = this.renderTitle.bind(this);
        this.functionOK = this.functionOK.bind(this);
        this.functionCancel = this.functionCancel.bind(this);
        this.filterFilter = debounce(this.filterFilter.bind(this), 500);
        this.clearFilter = this.clearFilter.bind(this);
        
        
    }
    
    functionOK() {
        const ret = this.state.functionOK();
        if ( typeof(ret) === 'undefined' || ret !== false ) this.props.dispatch(Navigation.hidePopup());
    }
    
    functionCancel() {
        const ret = this.state.functionCancel();
        if ( typeof(ret) === 'undefined' || ret !== false ) this.props.dispatch(Navigation.hidePopup());
    }
    
    filterFilter(t) {
        this.props.dispatch(Navigation.filterPopup(t));
    }
    
    clearFilter(t) {
        this.props.dispatch(Navigation.filterPopup(''));
    }
    
    renderTitle() {
        return (
            <span style={{
                display: 'flex',
                width: '100%'
            }}>
                <span style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                {this.state.title}
                </span>
                {this.state.showSearch ? (
                    <span style={{
                        display: 'flex',
                        width: 673,
                        flexGrow: 0,
                        flexShrink: 0,
                        textAlign: 'right'
                    }}>
                        <SearchBox
                            placeholder={'Filter'}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            value={this.state.Filter}
                            onChanged={this.filterFilter}
                            onSearch={this.filterFilter}
                            onClear={this.clearFilter}
                            onEscape={this.clearFilter}
                        />
                    </span>
                ) : null}
            </span>
        )
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            typeof props.navigation !== 'undefined' &&
            typeof props.navigation.Popup !== 'undefined'
        ) {
            if ( props.navigation.Popup.show === false ) 
                return { 
                    show: false, 
                    Filter: {
                        SearchBox: ''
                    }
                }
            else return props.navigation.Popup;
        }
        
        return null;
    }
    
    render() {
        const titleOK = this.state.titleOK ? this.state.titleOK : 'OK';
        const titleCancel = this.state.titleCancel ? this.state.titleCancel : 'Cancel';
        
        return (
            <Dialog
                hidden={!this.state.show}
                shiftOnReduce={true}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: this.renderTitle(),
                  styles: {
                    title: {
                        paddingRight: 24,
                        paddingTop: 24,
                        paddingBottom: 0
                    }  
                  }
                }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  styles: { 
                    main: { 
                        maxWidth: '930px !important',
                        width: 930 
                    },
                    scrollableContent: {
                        maxWidth: 930,
                        width: 930
                    }
                }}}
            >
                {this.state.children}
                { this.state.hideFooter ? null : (
                    <DialogFooter>
                        <PrimaryButton onClick={this.functionOK} text={titleOK} />
                        <DefaultButton onClick={this.functionCancel} text={titleCancel} />
                    </DialogFooter>
                )}
            </Dialog>
        )
    }
    
}

export default connect(mapStateToProps)(Popup);