import React from 'react';
import { mapStateToProps, connect } from 'containers/UserContainer';
import { Users } from 'helpers/';

class UserInline extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            userName: ''
        }

        Users.Load(props.dispatch);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            state.userName === '' &&
            props.userName &&
            props.userName !== ''
        ) {
            return {
                userName: props.userName
            }
        } 
        
        return null;
    }
    
    render() {
        return (
            this.state.userName !== '' ? 
            (<span title={this.state.userName}>{this.state.userName}</span>) :
            this.props.id
        )
    }
    
}

export default connect(mapStateToProps)(UserInline);