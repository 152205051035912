import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';

import Navigation from 'components/Navigation';

export default class extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: null
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'client',
                    name: 'Client',
                    fieldName: 'client_id',
                    maxWidth: 100
                },
                {
                    key: 'id',
                    name: 'Report',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.title}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: item
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                title: null
            }
        })
    }
    
    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Search/Reports'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide}>
                    <h1>{this.state.selectedItem.title}</h1>
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}