import React from 'react';
import { mapStateToProps, connect } from 'containers/UserContainer';
import { Users } from 'helpers/';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react';

class UsersDropdown extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            userList: [],
            Filter: '',
            loaded: false,
            selected: {},
            options: []
        }

        Users.Load(this.props.dispatch);
        Users.Get(props.dispatch);
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.userList &&
            props.userList.length &&
            state.userList.length === 0
        ) {
            let options = props.userList.filter((l) => (typeof(l.active) === 'undefined' || l.active === 1)).map((l) => ({
                key: l.id,
                text: l.name
            }))
            return {
                userList: props.userList ? props.userList : [],
                options: props.userList ? options : [],
                loaded: true
            }
        } 
        

        return null;
    }

    render() {
        return <Dropdown
                    label={"User"}
                    options={this.state.options}
                    ResponsiveMode={ResponsiveMode.Small}
                    name={this.props.name}
                    errorMessage={this.props.errorMessage}
                    onChange={this.props.onChange}
                    styles={{
                        root: {
                            flexGrow: 1
                        },
                        container: {
                            flexGrow: 1
                        },
                        dropdown: {
                            flexGrow: 1
                        }
                    }}
                />
    }
    
}

export default connect(mapStateToProps)(UsersDropdown);