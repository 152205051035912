import NavigationButton from './UploadNavigationButton';
import SingleSmall from './UploadSingleSmall';
import SingleBig from './UploadSingleBig';
import Paste from './UploadPaste'
import Controller from './UploadController'
import BigWindow from './UploadBigWindow'
import External from './UploadExternal';
import ExternalButton from './UploadExternalButton';

export {
    NavigationButton,
    SingleSmall,
    SingleBig,
    Paste,
    Controller,
    BigWindow,
    External,
    ExternalButton
}

export default {
    NavigationButton,
    SingleSmall,
    SingleBig,
    Paste,
    Controller,
    BigWindow,
    External,
    ExternalButton
}