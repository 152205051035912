module.exports = {
    init: function(initialData, modifyText) {
        window.$('#CKEditor').html('<textarea id="ckarea" style="width: 100%; height: 100%;"></textarea>');
        window.CKEDITOR.replace( 'ckarea' , {
        	on : {
        		// maximize the editor on startup
        		'instanceReady' : function( evt ) {
        		    evt.editor.setData(initialData);
        			evt.editor.resize("100%", window.$('#CKEditor').height());
        			window.$('.cke_button__maximize').parent().parent().css({
        				'float': 'right',
        				'width': '30px'
        			});
        			
        			evt.editor.document.on( 'mousedown', function() {
        				window.parent.down = true;
        			});
        			
        			evt.editor.document.on( 'mouseup', function() {
        				window.parent.down = false;
        			});
        		},
        		'change': function(evt) {
        		    try {
        		        modifyText(evt.editor.getData());
        		    } catch (e) {
        		        
        		    }
        		}
        	},
        	footnotesTitle: 'Sources',
        	forcePasteAsPlainText: true,
        	extraPlugins: 'find,wordcount,notification,resize,footnotes,removeformat,maximize',
        	wordcount: {
        		showParagraphs: true,
        		showWordCount: true,
        		showCharCount: true,
        		countSpacesAsChars: false,
        		countHTML: false,
        		maxWordCount: -1,
        		maxCharCount: -1
        	},
        	resize_enabled: true,
        	resize_dir: 'vertical',
        	disableNativeSpellChecker: false
        });
    },
    
    setSources: function(assignment_id, newSources) {
        if (!newSources) newSources = [];
        let footnotes = newSources.map((source) => ({
            id: source.source_id.replace(/:/, '-') + '-' + assignment_id + '-' + new Date().getTime(),
            title: source.title_2.length > 0 ? source.title_2 : source.title
        }));
        localStorage.setItem('assignment-footnotes', JSON.stringify(footnotes));
    }
};