import { connect } from 'react-redux';

const userName = (users, userID) => {
    try {
        const c = users[userID];
        let n = c.name;

        return n;
        
    } catch (e) {
        return '';
    }
}

const mapStateToProps = (state, ownProps) => {

    let ret = {};
    if ( 
        state.r_user &&
        state.r_user.users
    ) return {
        userName: userName(state.r_user.users, ownProps.id),
        userList: Object.values(state.r_user.users)
    }
    
    if (
        state.api.requestStatus === 'done' &&
        state.api.result.Module === 'Users' &&
        state.api.result.Function === 'List'
    ) {
        let x = {};
        state.api.result.Data.forEach((a) => {
            x[a.id] = a;
        })
        ret = {
            userName: userName(x, ownProps.id),
            userList: Object.values(x)
        };
    }

    return ret;
};

export { mapStateToProps, connect }