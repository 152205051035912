import * as React from 'react';
import { mapStateToProps, connect } from 'containers/MenuContainer';
import { withRouter } from 'react-router-dom';

import User from 'components/User';
import Search from 'components/Search';

import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import API from 'actions/api';
import Utilities from 'actions/utilities';
import Navigation from 'actions/navigation';
import ReloadButton from './ReloadButton';
import NotificationButton from './NotificationButton';
import MessagesButton from './MessagesButton';
import {
    NavigationButton as UploadNavigationButton,
    ExternalButton as UploadExternalButton
} from 'components/Upload';

class MenuMain extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            forceRender: 0,
            loading: false
        }
        
        this.getFarItems = this.getFarItems.bind(this);
    }

    render() {
        Utilities.Reflow();
        return this.props.User.loggedIn ?
            (
                <div>
                  <CommandBar 
                        items = {[
                            {
                                key: 'sidebar',
                                iconProps: {
                                    iconName: 'GlobalNavButton'
                                },
                                iconOnly: true,
                                onClick: () => { this.props.dispatch(Navigation.toggleLeftSidebar()) }
                            },
                            {
                                key: 'search',
                                commandBarButtonAs: () => <Search.MainBar />
                            }
                        ]}
                        farItems={this.getFarItems()} 
                        className={'MenuMain navigationBlue'}
                    />
                </div>
            ) : '';
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = {};
        
        if (typeof(state.User) === 'undefined' || props.User.loggedIn !== state.User.loggedIn) newState.User = props.User;

        return Object.keys(newState).length > 0 ? newState : null;

    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    getFarItems = () => {
        const UserData = this.state.User;
        return [
            {
                key: 'external',
                commandBarButtonAs: () => <UploadExternalButton />
            },
            {
                key: 'upload',
                commandBarButtonAs: () => <UploadNavigationButton />
            },
            {
                key: 'reload',
                commandBarButtonAs: () => <ReloadButton />
            },
            {
                key: 'notifications',
                commandBarButtonAs: () => <NotificationButton />
            },
            {
                key: 'messages',
                commandBarButtonAs: () => <MessagesButton />
            },
            {
                key: 'user',
                name: this.state.User.loggedIn ? 'Logout ' + this.state.User.userData.name : 'Do Login',
                iconProps: {
                    iconName: 'StatusErrorFull'
                },
                onClick: () => {
                    
                },
                commandBarButtonAs: () => (<User.MenuButton User={UserData} 
                    menu={{
                        items: [
                            {
                                key: 'userLogout',
                                text: 'Logout',
                                iconProps: { className: 'fad fa-sign-out' },
                                onClick: () => {
                                
                                    this.props.dispatch(Navigation.showConfirmation({
                                        title: 'Logout',
                                        text: 'Logout from application?',
                                        titleOK: 'Logout',
                                        titleCancel: 'Cancel',
                                        functionOK: () => { 
                                            this.props.dispatch(API.Logout());
                                        },
                                        functionCancel: () => { 
                                            
                                        }
                                    }));
                                }
                            }
                        ]
                    }}
                />)
            }
        ];
    };
}

export default connect(mapStateToProps)(withRouter(MenuMain));