import React from 'react';
import User from 'components/User';
import Elements from 'elements';
import Country from 'components/Country';
import Language from 'components/Language';

class SingleAssignmentPreviewSmall extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        if (this.props.onClick) this.props.onClick(this.props.assignment);
    }
    
    render() {
        return (
            <div 
                onClick={this.onClick} 
                className={`highlightRow priority${this.props.assignment.priority}`}
                style={{
                    padding: '10px', 
                    width: 'calc(100% - 20px)', 
                    cursor: 'pointer',
                    margin: '10px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{display: 'flex', flexGrow: 1}}>
                        <Country.Inline id={this.props.assignment.country} />
                        <Elements.DotSeparator />
                        <Language.Inline id={this.props.assignment.language} />
                        <Elements.DotSeparator />
                        {this.props.assignment.user_id==='0' ? 'Group' : <User.Inline id={this.props.assignment.user_id} />}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                        <div style={{display: 'flex', flexGrow: 1}}>
                            <h3 style={{margin: 0, padding: 5}}>
                                {this.props.assignment.priority === 1 ? <i className={'fad fa-exclamation'} style={{marginRight: 10}}></i> : null}
                                {this.props.assignment.name && this.props.assignment.name.length ? this.props.assignment.name : '<No name>'}
                            </h3>
                        </div>
                        <div style={{display: 'flex', width: 150, flexGrow: 0, textAlign: 'right'}}>
                            <span style={{marginLeft: 'auto'}}>
                                <Elements.DateTime stamp={this.props.assignment.last_change} />
                            </span>
                        </div>
                    </div>
            </div>
        )
    }
    
}

export default SingleAssignmentPreviewSmall;