import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    
    //Check for reload data
    if (
        state.navigation &&
        state.navigation.RELOAD_DATA &&
        state.navigation.RELOAD_DATA === true 
    ) return {
        RELOAD_DATA: true
    }
    
    let result = {}
    if (typeof state.api.result !== 'undefined' && ( state.api.result.Module === 'Photo' || state.api.result.Module === 'Video' || state.api.result.Module === 'Document' || state.api.result.Module === 'Scan')) {
        if (state.api.result.Function==='index') {
            result.media = state.api.result.Data;
        }
    }
    
    if (typeof state.navigation.pageRefresh !== 'undefined' && state.navigation.pageRefresh === true) {
        result.media = []
    }

    if (typeof(state.search.Search) != undefined) {
        result.Search = state.search.Search;
    }
    
    return result;
};
    
export { mapStateToProps, connect }