import Utilities from 'helpers/Utilities';

const Menu = function(permissions, defaultOnClick) {
    
    const MainOptions = {
        'public.Desk': {},
        'protected.News': {
            'protected.Intelligence': {},
            'protected.Agenzia': {},
            'protected.PressClipping': {},
            'protected.Published': {},
            'protected.Archive': {},
            'protected.Groups': {},
            'protected.OSINT': {}
        },
        'public.Upload': {},
        'protected.Assignments': {},
        'protected.Monitor': {},
        'protected.Research': {
            'protected.Keywords': {},
            'protected.Tags': {},
            'protected.Saved Searches': {},
            'protected.Statistics': {},
            'protected.Incoming': {},
            'protected.Report': {}
        },
        'protected.Media': {
            'protected.Photo': {},
            'protected.Video': {},
            'protected.Scan': {},
            'protected.Document': {}
        },
        'protected.Organization': {
            'protected.Category': {},
            'protected.Country': {},
            'protected.Nationality': {},
            'protected.External Links': {},
            'protected.Language': {},
            'protected.Project': {},
            'protected.Translate': {},
            'protected.Users': {},
            'protected.Robot Links': {},
            'protected.Clients': {},
            'protected.Sites': {}
        },
        'protected.Logs': {
            'protected.Search Log': {},
            'protected.User Log': {}
        },
        'protected.Wiki': {}
    };
    return generateOptions(MainOptions, permissions, defaultOnClick);
}

const ModuleName = (title) => {
    let nt = title.split('.');
    if (nt.length > 1) nt = nt[1];
    else nt = nt[0];
    return nt.replace(/ /g, '');
}

const generateOptions = (array, permissions, defaultOnClick) => {
    let options = [];
    let keys = Object.keys(array);
    for (let i=0; i<keys.length; i++) {
        let name = keys[i].split('.');
        let subkeys = Object.keys(array[keys[i]]);
        if (permissions.modules.indexOf(name[1]) === -1 && subkeys.length === 0 && name[0] === 'private') continue;
        let option = {
            key: name[1].toLowerCase(),
            name: name[1]
        };
        if (subkeys.length === 0) {
            option.href = `/${ModuleName(name[1])}`;
            option.onClick = (event) => { 
                Utilities.Unfocus();
                event.preventDefault();
                setTimeout(() => {
                    defaultOnClick(ModuleName(name[1])) 
                });
            }
        }
        else {
            let suboptions = generateOptions(array[keys[i]], permissions, defaultOnClick);
            if (permissions.modules.indexOf(name[1]) === -1 && suboptions.length === 0 && name[0] === 'private') continue;
            option.subMenuProps = {
                className: 'navigationBlue',
                items: suboptions
            }
        }
        options.push(option);
    }
    return options;
}

export default Menu;