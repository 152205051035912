const defaultState = {
    Search: {
        text: '',
        didSearch: false,
        clearSearch: false
    }
}

function searchReducer(state=defaultState, action) {
    switch (action.type) {
        
        case 'MAINSEARCH_DO':
            return {
                ...state, 
                Search: {
                    ...action.payload.Search,
                    didSearch: true
                }
            }
            
        case 'MAINSEARCH_CLEAR':
            return {
                ...state,
                Search: {
                    didSearch: false,
                    clearSearch: true,
                    text: ''
                }
            }

        default:
            let x = {
                ...state
            }
            x.Search.didSearch = false;
            x.Search.clearSearch = false;
            
            return x;

    }
    
}

export default searchReducer;