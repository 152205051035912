import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    if ( 
        typeof(state.navigation) !== 'undefined' &&
        ( typeof(state.navigation.Popup) !== 'undefined' || typeof(state.navigation.PagePopup) !== 'undefined' )
    ) return {
        navigation: state.navigation
    }
    
    return {}
};

export { mapStateToProps, connect }