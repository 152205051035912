const defaultState = {
    sendingRequest: false,
    requestStatus: 'idle',
    data: {},
    result: {},
    User: {
        loggedIn: false,
        userData: {}
    }
}

function apiReducer(state=defaultState, action) {
    switch (action.type) {
        
        case 'FETCH_PENDING':
            return {
                ...state, 
                sendingRequest: true,
                requestStatus: 'pending',
                result: action
            }
        case 'FETCH_FULFILLED':
            return {
                ...state,
                sendingRequest: false,
                requestStatus: 'done',
                result: action.payload.data
            }
        case 'FETCH_FAILED':
            return {
                ...state,
                sendingRequest: false,
                requestStatus: 'failed',
                result: action.payload
            }
            
        case 'FORM_POST_PENDING':
            return {
                ...state, 
                sendingRequest: true,
                requestStatus: 'pending',
                result: action
            }
        case 'FORM_POST_FULFILLED':
            return {
                ...state,
                sendingRequest: false,
                requestStatus: 'done',
                result: action.payload.data
            }
        case 'FORM_POST_FAILED':
            return {
                ...state,
                sendingRequest: false,
                requestStatus: 'failed',
                result: action.payload
            }
        
        case 'LOGIN_PENDING':
            return {
                ...state, 
                sendingRequest: true,
                requestStatus: 'pending',
                result: null
            }
        case 'LOGIN_FULFILLED':
            let User = {
                loggedIn: false,
                userData: {}
            };
            if (typeof(action.payload.data.id) != 'undefined') {
                User = {
                    loggedIn: true,
                    userData: action.payload.data
                }
                localStorage.setItem('AGENZIA-API-KEY', User.userData.hash);
            }
            else if (typeof(action.payload.data.error) !== 'undefined') {
                User = {
                    loggedIn: false,
                    userData: {}
                }
                localStorage.removeItem('AGENZIA-API-KEY');
            }
            let ret = {
                ...state,
                sendingRequest: false,
                requestStatus: 'done',
                result: action.payload.data,
                User: User
            }
            if (typeof(action.payload.data.error) !== 'undefined') ret.error = action.payload.data.error;
            return ret;
        case 'LOGIN_FAILED':
            return {
                ...state,
                sendingRequest: false,
                requestStatus: 'failed',
                result: action.payload
            }    
        
        case 'LOGIN': 
            return {
                ...state,
                User: {
                    loggedIn: true,
                    userData: {
                        id: 'test',
                        name: 'Test User'
                    }
                }
            }
        case 'LOGOUT':
            localStorage.setItem('AGENZIA-API-KEY', 'Logout');
            localStorage.removeItem('AGENZIA-API-KEY');
            return {
                ...state,
                User: {
                    loggedIn: false,
                    userData: {}
                }
            }
        
        default:
            return {
                ...state,
                data: {},
                result: {}
            }

    }
    
}

export default apiReducer;