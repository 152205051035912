import API from './api';

export default {
    
    List: (dispatch, data) => {
        let { q, start, type } = data;
        if (!start) start = 0;
        if (!q) q = '';
        delete data.type;
        dispatch(API.Get(`${type}`, { ...data, output: 'json'}));
    }
    
}