import React from 'react';

class UploadPaste extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            text: 'Right click here to paste',
            lastPaste: 0
        }
        
        this.onPaste = this.onPaste.bind(this);
    }
    
    onPaste(e) {
        e.preventDefault();
        let text = '';
        try {
			text = e.clipboardData.getData('Text');

            if (this.props.keepValue) {
                this.setState({
                    text: text
                });
            }

            if (text.length > 0) {			
			    if ( text.substring(0,8) === 'https://' || text.substring(0,7) === 'http://' ) {
			        if (this.props.onPasteLink) this.props.onPasteLink(text);
			        else if (this.props.onPasteText) this.props.onPasteText(text);
			    } else if (this.props.onPasteText) this.props.onPasteText(text);
            }
			
		} catch (e) {
			if (this.props.onPasteFiles) this.props.onPasteFiles(e.clipboardData.items);
		}
		
		if (text.length === 0) {
		    if (this.props.onPasteFiles) this.props.onPasteFiles(e.clipboardData.items);
		}
        
        return false;
    }
    
    render() {
        return (
            <div 
                contentEditable={true}
                suppressContentEditableWarning={true}
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    textAlign: 'center',
                    padding: 10,
                    border: '1px solid var(--gray-light)'
                }}
                onKeyUp={() => (false)}
                onKeyDown={() => (false)}
                onPaste={this.onPaste}
            >{this.state.text}</div>
        )
    }
    
}
export default UploadPaste;