/* global localStorage */

import axios from 'axios';
import Config from '../config.js';
import Cache from 'helpers/Cache';

const md5 = require('md5');

let API = {
    
    Get: (path, params = {}) => ({
        type: 'FETCH',
        payload: axios.get(Config.API_PATH + path, {
            params: params,
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }) 
    }),
    
    PostJSON: (path, json) => ({
        type: 'LOGIN',
        payload: axios.post(Config.API_PATH + path, json)
    }),
    
    PostForm: (path, data) => {
        var body = new FormData();
        for (let a in data) body.set(a, data[a]);
        
        return {
            type: 'FORM_POST',
            payload: axios({
                method: 'post',
                url: Config.API_PATH + path + '?output=json',
                data: body,
                headers: {
                    'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY'),
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    },
    
    Logout: () => ({
        type: 'LOGOUT'
    }),
    
    returnGet: (path, cache=false) => (new Promise(async (resolve, reject) => {
        const cacheItem = 'API_GET_' + md5(path);
        let resolved = false;
        
        if ( cache === true ) {
            
            let cachedData = await Cache.GetIfValid(cacheItem).catch((e) => (false));
            if (cachedData && cachedData.data && cachedData.data.data && cachedData.data.data.Data && cachedData.data.data.Data.length) {
                resolved = true;
                resolve(cachedData.data);
            }

        }
        
        if (resolved === false) {
            axios.get(Config.API_PATH + path, {
                headers: {
                    'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
                }
            }).then((result) => {
                if ( cache === true ) Cache.Save(cacheItem, result, 300 * 1000);
                resolve(result);
            }).catch((e) => {
                reject(e);
            })
        }
    })),
    
    returnPost: (path, json={}) => (new Promise(async (resolve, reject) => {
        axios({
            method: 'post',
            url: Config.API_PATH + path + '?output=json',
            data: json,
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            resolve(result);
        }).catch((e) => {
            reject(e);
        })
    }))
    
}

export default API;