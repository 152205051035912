import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    let result = {}
    if (typeof state.api.result !== 'undefined' && state.api.result.Module==='Assignments') {
        if (state.api.result.Function==='index') {
            result.assignments = state.api.result.Data.Assignments;
        }
    }
    
    if (typeof(state.search.Search) != undefined) {
        result.Search = state.search.Search;
    }
    
    return result;
};
    
export { mapStateToProps, connect }