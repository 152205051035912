import React from 'react';

import Moment from 'react-moment';
import moment from 'moment';

import { InView } from 'react-intersection-observer'

import { connect, mapStateToProps } from 'containers/DraftsContainer';

import MediaEdit from './MediaEdit'

import { withRouter } from "react-router";

import {
  DocumentCard,
  DocumentCardActivity,
  DocumentCardTitle,
  DocumentCardActions
} from 'office-ui-fabric-react/lib/DocumentCard';

class MediaItem extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            inSources: false,
            MediaType: props.MediaType,
            id: props.item.id
        }
        
        this.onEdit = this.onEdit.bind(this);
    }

    static getDerivedStateFromProps = (props, state) => {
        
        if (props.sources && props.sources[`${state.MediaType}:${state.id}`]) return {
            inSources: true
        } 
        else return {
            inSources: false
        }
        
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.inSources !== this.state.insSources
    }

    onEdit(item) {
        if ( typeof(this.props.addSlide) === 'function' ) {
            this.props.addSlide(`Edit ${this.props.MediaType}`, <MediaEdit MediaType={this.props.MediaType} id={item.id} />);
        } else {
            this.props.history.push(`/${this.props.MediaType}/Edit/${item.id}`);
        }
        return false;
    }

    render() {
        const item = this.props.item;
        if (!item) return false;
        
        const MediaType = this.props.MediaType;
        const Click = this.props.onClick;
        const Edit = this.props.onEdit;
        
        let date = false;
        
        let thumbnail = '';
        try {
            thumbnail = item.thumbnail.thumbnail
        } catch(e) {
            thumbnail = '';
        }
        
        const key = window.localStorage.getItem('AGENZIA-API-KEY');
        
        let people = [];
        if (typeof item.assignments !== 'undefined' && item.assignments !== false && item.assignments.length > 0) {
            item.assignments.map((a) => {
                people.push({
                    name: a.user.name,
                    profileImageSrc: `https://bl.m.etleboro.org/APP/img/UserIcon.php?uid=${a.user.id}&X_API_KEY=${key}&p=1`
                });
                date = a.insert_datetime;
                return true;
            })
        }
        if (people.length === 0) people = [{
            name: '',
            allowPhoneInitials: false,
            initials: '',
            initialsColor: 'transparent'
        }];
        
        let fullDate = moment(date).format('DD.MM.YYYY hh:mm');
        let ago = date===false ? '' : <div title={fullDate}><Moment fromNow ago>{date}</Moment></div>;
        
        item.MediaType = MediaType;
        
        return (
            <div className="gridBox" key={item.id}>
                <DocumentCard>
                        <div className="coverImage">
                            <InView>
                                {({ inView, ref, entry }) => (
                                    <>
                                        <img src={inView && thumbnail!==false ? thumbnail : ''} ref={ref} alt={''} />
                                        { inView && item.connected && item.connected > 0 ? (<div className={'floatCounter'}>{item.connected + 1}</div>) : null }
                                    </>
                                )}
                                
                            </InView>
                        </div>
                        <div>
                            <DocumentCardTitle title={item.name} shouldTruncate />
                        </div>
                        <DocumentCardActivity activity={ago} people={people} />
                        <DocumentCardActions
                          actions={[
                            {
                              iconProps: { iconName: this.state.inSources ? 'Remove' : 'Add' },
                              className: this.state.inSources ? 'btnRed' : 'btnGreen',
                              onClick: () => {
                                    return Click ? Click(MediaType, item, this.state.inSources) : false
                              }
                            },
                            {
                              iconProps: { iconName: 'PageEdit' },
                              onClick: () => {
                                    return Edit ? Edit(MediaType, item) : this.onEdit(item)
                              }
                            }
                          ]}
                          views={11}
                        />
                </DocumentCard>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(withRouter(MediaItem));