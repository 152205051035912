import { connect } from 'react-redux';

    
const categoryName = (categories, categoryID) => {
    try {
        const c = categories[categoryID];
        let n = c.name;

        return n;
        
    } catch (e) {
        return '';
    }
}

const mapStateToProps = (state, ownProps) => {
    let ret = {};
    if ( 
        state.r_category &&
        state.r_category.categories
    ) return {
        categoryName: categoryName(state.r_category.categories, ownProps.id),
        categoryList: Object.values(state.r_category.categories),
        Filter: state.navigation.Popup.Filter ? state.navigation.Popup.Filter : ''
    }
    
    if (
        state.api.requestStatus === 'done' &&
        state.api.result.Module === 'Category' &&
        state.api.result.Function === 'index'
    ) {
        let x = {};
        state.api.result.Data.forEach((a) => {
            x[a.id] = a;
        })
        ret = {
            categoryName: categoryName(x, ownProps.id),
            categoryList: state.api.result.Data,
            Filter: state.navigation.Popup.Filter ? state.navigation.Popup.Filter : ''
        };
    }

    return ret;
};

export { mapStateToProps, connect }