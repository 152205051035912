import React from 'react';

const DateTime = (props) => {
    
    if (
        !props.stamp ||
        !props.stamp.length
    ) return '';
    
    try {
        const moment = require('moment');
        const stamp = props.stamp.replace(/T/, ' ');
        const date = moment(stamp);
        const seconds = props.seconds && props.seconds !== false;
        let ret = date.format('DD.MM.YYYY HH:mm');
        
        if ( 
            props.ago &&
            props.ago !== false
        ) ret = date.fromNow();
        
        else if (
            props.full &&
            props.full !== false
        ) ret = date.format('DD.MM.YYYY HH:mm' + (seconds ? ':ss' : ''));
        
        else {
            const today = moment();
        
            if (date.isSame(today, 'day')) ret = date.format('HH:mm' + (seconds ? ':ss' : ''));
            else if (date.isSame(today, 'year')) ret = date.format('DD.MM HH:mm' + (seconds ? ':ss' : ''));
        }
        
        return <span title={date.format('DD.MM.YYYY HH:mm:ss')} data-original={props.stamp}>{ret}</span>
    } catch (e) {
        return <span title={props.stamp} data-original={props.stamp}>{props.stamp}</span>
    }
    
    
}

export default DateTime;