import * as React from 'react';
import { mapStateToProps, connect } from 'containers/NavigationContainer';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

import Navigation from 'actions/navigation';

class ConfirmationBox extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            show: false,
            title: '',
            text: '',
            hideCancel: false,
            functionOK: () => {
                
            },
            functionCancel: () => {
                
            }
        }
    }
    
    functionOK() {
        this.props.dispatch(Navigation.hideConfirmation());
        this.state.functionOK();
    }
    
    functionCancel() {
        this.props.dispatch(Navigation.hideConfirmation());
        this.state.functionCancel();
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if (
            typeof props.navigation !== 'undefined' &&
            typeof props.navigation.confirmationBox !== 'undefined'
        ) {
            if ( props.navigation.confirmationBox.show === false ) 
                return { show: props.navigation.confirmationBox.show }
            else return props.navigation.confirmationBox;
        }
        return null;
    }
    
    render() {
        const titleOK = this.state.titleOK ? this.state.titleOK : 'OK';
        const titleCancel = this.state.titleCancel ? this.state.titleCancel : 'Cancel';
        
        return (
            <Dialog
                hidden={!this.state.show}
                shiftOnReduce={true}
                dialogContentProps={{
                  type: DialogType.normal,
                  title: this.state.title,
                  subText: this.state.text
                }}
                modalProps={{
                  isBlocking: true,
                  isDarkOverlay: true,
                  styles: { main: { maxWidth: 450 } }
                }}
              >
                <DialogFooter>
                  <PrimaryButton onClick={this.functionOK.bind(this)} text={titleOK} />
                  { this.state.hideCancel ? null : (
                  <DefaultButton onClick={this.functionCancel.bind(this)} text={titleCancel} />
                  )}
                </DialogFooter>
              </Dialog>
        )
    }
    
}

export default connect(mapStateToProps)(ConfirmationBox);