import { Cache } from 'helpers';

let defaultState = {
    languages: {}
}
try {
    const data = Cache.Get('R_FILL_LANGUAGES');
    if (data.data) defaultState = {
        languages: data.data
    }
} catch (e) {
    defaultState = {
        languages: {}
    }    
}

function languageReducer(state=defaultState, action) {
    
    switch (action.type) {
        
        case 'R_GET_LANGUAGES':
            return state;
        
        case 'R_FILL_LANGUAGES_PENDING':
            return state;
        
        case 'R_FILL_LANGUAGES_FAILED':
            return state;
        
        case 'R_FILL_LANGUAGES_FULFILLED':
            let x = {};
            action.payload.data.Data.forEach((c) => {
                x[c.shortcode] = c;
            })
            Cache.Save('R_FILL_LANGUAGES', x, 300 * 1000);
            return {
                languages: x
            }
            
        default:
            return state;
        
    }
    
}

export default languageReducer;