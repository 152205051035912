import React from 'react';
import { connect, mapStateToProps } from 'containers/UploadContainer';
import { Loader, Intelligence } from 'components';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Paste as UploadPaste } from 'components/Upload';

class UploadExternal extends React.Component {
    
    constructor(props) {
        
        super(props);
        
        this.state = {
            existing: [],
            result: {},
            url: null,
            fetching: false
        }
        
        this.externalForm = this.externalForm.bind(this);
        this.resultForm = this.resultForm.bind(this);
        this.renderExisting = this.renderExisting.bind(this);
        this.inputForm = this.inputForm.bind(this);
        this.onPasteLink = this.onPasteLink.bind(this);
    }
    
    static getDerivedStateFromProps = (props, state) => { 
        let ret = {};

        if ( props.existing || props.result ) {
            ret = {
                existing: typeof(props.existing) !== 'undefined'  ? props.existing : state.existing,
                result: typeof(props.result) !== 'undefined' ? props.result : state.result,
                fetching: false
            }
        }
        return ret;
    }
    
    resultForm() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 0,
                boxSizing: 'border-box',
                padding: 20,
                overflow: 'hidden',
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    height: 40
                }}>
                    <TextField name={'title'} value={this.state.result.title} />
                </div>
                <div style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'row'
                }}>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: '60%',
                        boxSizing: 'border-box',
                        padding: 20,
                        overflowX: 'hidden',
                        overflowY: 'auto'
                    }}>
                        <pre style={{
                            width: '100%',
                            height: '100%',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all'
                        }}>{this.state.result.text}</pre>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: '40%'
                    }}>
                        <img src={this.state.result.image.src} alt={''} style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%'
                        }} />
                    </div>
                </div>
            </div>
        )
    }
    
    renderExisting() {
        return <Intelligence.ArticleRow article={this.state.existing} onAdd={this.props.onAdd} onRemove={this.props.onRemove} />
    }

    onPasteLink(link) {
        this.setState({
            fetching: true
        },() => { 
                this.props.dispatch({
                type: 'UPLOAD_PASTE_EXTERNAL',
                payload: {
                    link: link
                }
            })    
        });
    }
    
    inputForm() {
        return (
            <UploadPaste 
                onPasteLink={this.onPasteLink}
                keepValue
            />
        )
        
    }
    
    externalForm() {
        if (this.state.result && Object.keys(this.state.result).length) return this.resultForm();
        else if (this.state.existing.id) return this.renderExisting();
        else return null;
    }
    
    render() {
        return (
            <div style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column'
            }}>
                <div style={{
                    display: 'flex',
                    height: 40
                }}>
                {this.inputForm()}
                </div>
                <div style={{
                    display: 'flex',
                    flexGrow: 1
                }}>
                {(this.state.fetching ? <Loader.Big /> : this.externalForm())}
                </div>
            </div>
        );
        
    }
    
}

export default connect(mapStateToProps)(UploadExternal);