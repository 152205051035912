import React from 'react';
import { mapStateToProps, connect } from 'containers/APIContainer';
import Data from 'actions/data';

import { 
    Intelligence, 
    Loader, 
    Keywords,
    Filter
} from 'components';

import { List } from 'office-ui-fabric-react/lib/List';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';

import InfiniteScroll from 'react-infinite-scroll-component';
import aNavigation from 'actions/navigation';

var _ = require('lodash');

Keywords.Icons.Register('fal');

class IntelligenceList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            previewOpen: false,
            article: null,
            data: [],
            filter: '',
            loading: true,
            FilterData: {},
            getData: this.getData.bind(this)
        }
        
        this.onRenderRow = this.onRenderRow.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.getData = this.getData.bind(this);
        this.state.getData = this.getData.bind(this);
        this.onHide = this.onHide.bind(this);
        this.refresh = this.refresh.bind(this);
        this.addSource = this.addSource.bind(this);
        this.removeSource = this.removeSource.bind(this);
        this.onRowHover = this.onRowHover.bind(this);

    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.data.length !== this.state.data.length ||
            nextState.previewOpen !== this.state.previewOpen ||
            nextState.filter !== this.state.filter ||
            nextState.loading !== this.state.loading ||
            nextState.article !== this.state.article ||
            nextState.FilterData !== this.state.FilterData ||
            (
                this.state.article !== null &&
                this.state.article.id &&
                nextState.article.id && 
                nextState.article.id !== this.state.article.id
            )
    }
    
    componentDidMount() {
        this.getData();
    }
    
    static getDerivedStateFromProps = (props, state) => {
        let ret = {}
        if (
            props.MyData &&
            props.MyData.hits
        ) ret = {
            data: state.data.concat(props.MyData.hits),
            FilterData: props.FilterData ? props.FilterData : {},
            loading: false
        }
        
        if (props.FilterData && !_.isEqual(props.FilterData, state.FilterData)) {
            ret.data = [];
            ret.article = null;
            ret.FilterData = props.FilterData;
        }

        if ( state.loading === false && ret.data && ret.data.length === 0 && ( !ret.loading ) ) {
            if ( state.loading === false ) ret.loading = true;
            setTimeout(() => {
                state.getData();
            }, 500);
        }
        
        return ret;
    }
    
    getData() {
        let params = {
            type: 'Intelligence/index',
            start: this.state.data.length,
            q: this.state.filter
        };
        const filter = this.state.FilterData;
        const keys = Object.keys(filter);
        if (keys.length) {
            
            keys.forEach((key) => {
                
                if (typeof(filter[key]) === 'string') {
                    if (key==='query') params['q'] = filter[key];
                    else params[key] = filter[key];
                } else {
                    let temp = Object.keys(filter[key]);
                    if (temp.length) params[key] = temp;
                }
                
            })
            
        }
        Data.List(this.props.dispatch, params);
    }
    
    addSource(data) {
        this.props.dispatch({
            type: 'DRAFTS_SOURCE_ADD',
            payload: {
                type: 'Intelligence',
                source: data
            }
        })
    }
    
    removeSource(source) {
        this.props.dispatch(aNavigation.showConfirmation({
            title: 'Delete source',
            text: 'Delete this source?',
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                this.props.dispatch({
                    type: 'DRAFTS_SOURCE_DELETE',
                    payload: {
                        type: source.MediaType,
                        source: source
                    }
                })    
            },
            functionCancel: () => { 
            }
        }));
    }
    
    onRenderRow(row) {
        const noExpand = this.props.noExpand ? this.props.noExpand : false;
        return (
            <Intelligence.ArticleRow 
                key={row.id} 
                article={row} 
                onClick={this.onRowClick} 
                onMouseOver={this.onRowHover}
                onAdd={this.addSource} 
                onRemove={this.removeSource} 
                activeArticle={this.state.article} 
                noKeywords 
                noCountry 
                noMedia 
                noExpand={noExpand} 
            />
        )
    }
    
    onRowClick(row) {
        if (this.props.onClick) this.props.onClick('Intelligence', row);
        this.setState({
            article: row
        })
    }
    
    onRowHover(row) {
        if (this.props.onHover) this.props.onHover('Intelligence', row);
        this.setState({
            article: row
        })
    }
    
    onHide() {
        this.setState({
            article: null,
            previewOpen: false
        })
    }
    
    refresh() {
        this.setState({
            data: []
        });
        this.getData();
    }
    
    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexBasis: '70%'
                }}>
                    { this.state.loading ? <Loader.Big /> : (
                    <div className={'TableDetailsListScroll noPadding'} id={'TableDetailsListScroll'} data-is-scrollable={'true'}>
                        <InfiniteScroll
                            dataLength={this.state.data.length}
                            next={this.getData}
                            hasMore={this.state.data.length > 0}
                            loader={<Spinner label="Loading..." ariaLive="assertive" labelPosition="right" />}
                            scrollableTarget={'TableDetailsListScroll'}
                            className={'HideScroll'}
                            scrollThreshold={'300px'}
                            refreshFunction={this.refresh}
                            pullDownToRefresh
                        >
                            <List 
                                onShouldVirtualize={() => (false)}
                                style={{width: '100%'}} 
                                items={this.state.data}
                                onRenderCell={this.onRenderRow} 
                                activeArticle={this.state.article} 
                                usePageCache={true}
                            />
                        </InfiniteScroll>
                    </div>
                    )}
                </div>
                <div style={{
                    display: 'flex',
                    flex: 1,
                    boxSizing: 'border-box',
                    padding: 10,
                    overflow: 'auto',
                    flexBasis: '30%'
                }}>
                {
                    this.state.article ? <Intelligence.ArticlePreview key={this.state.article.id} article={this.state.article} keywords /> : ''
                }
                </div>
            </div>
        )
    }
    
}

export default connect(mapStateToProps('Intelligence/index'))(IntelligenceList);