import API from 'actions/api';
import { Cache } from 'helpers';
const debounce = require('debounce');

const Category = {
    
    Load: debounce((dispatch) => {
        Cache.GetIfValid('R_FILL_CATEGORIES')
        .then((cacheData) => {
            dispatch({
               type: 'R_FILL_CATEGORIES_FULFILLED',
               payload: {
                   data: {
                       Data: Object.values(cacheData.data)
                   }
               }
            });    
        })
        .catch((e) => {
            dispatch({
                type: 'R_FILL_CATEGORIES',
                payload: API.returnGet('Category/index?output=json', false)
            })
        })
    }, 60000, true),
    
    Get: (dispatch) => {
        dispatch({
            type: 'R_GET_CATEGORIES'
        })
    }
    
}

export default Category;