import React from 'react';
import { CommandBarButton, DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import SourcesItem from './SourcesItem';
import { mapStateToProps, connect } from 'containers/DraftsContainer'
import { New as AssignmentForm, SmallList as AssignmentsSmallList } from 'components/Assignments';
import Navigation from 'actions/navigation';
import { Cache } from 'helpers';
import axios from 'axios';
import Config from 'config.js';

class DraftsMenuButton extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            panelSize: PanelType.medium,
            sources: {},
            isOpen: false
        }
        
        this.formValues = {}
        this.formState = 'error';
        this.submitButtonText = '';
        this.displayPanel = '';
        
        this.onClick = this.onClick.bind(this);
        this.hidePanel = this.hidePanel.bind(this);
        this.contractPanel = this.contractPanel.bind(this);
        this.expandPanel = this.expandPanel.bind(this);
        this.expandPanelAdd = this.expandPanelAdd.bind(this);
        this.removeSource = this.removeSource.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.sendCreateAssignment = this.sendCreateAssignment.bind(this);
        this.selectAssignment = this.selectAssignment.bind(this);
        this.addToSelectedAssignment = this.addToSelectedAssignment.bind(this);
        
    }
    
    componentDidMount() {
        Cache.Get('DRAFTS_SOURCES').then((data) => {
            if (data.data) {
                this.props.dispatch({
                    type: 'DRAFTS_FILL_SOURCES',
                    payload: {
                        sources: data.data
                    }
                })
            }
        })
    }
    
    contractPanel() {
        this.setState({
            panelSize: PanelType.medium
        })
    }
    
    expandPanel() {
        this.submitButtonText = 'Create assignment';
        this.displayPanel='newAssignment';
        this.setState({
            panelSize: PanelType.large
        })
    }
    
    expandPanelAdd() {
        this.submitButtonText = 'Add to assignment';
        this.displayPanel='addToAssignment';
        this.setState({
            panelSize: PanelType.large
        })
    }
    
    onClick() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    
    hidePanel() {
        this.setState({
            isOpen: false
        })
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        return nextState.isOpen !== this.state.isOpen || nextState.panelSize !== this.state.panelSize || Object.keys(this.state.sources).length !== Object.keys(nextProps.sources).length
    }
    
    static getDerivedStateFromProps = (props, state) => {
        
        if (props.sources) return {
            sources: props.sources
        }
        
    }
    
    updateForm(field, value, errors) {
        this.formValues[field] = value;
        if (Object.keys(errors).length === 0) this.formState = 'ok';
        else this.formState = 'error';
    }
    
    removeSource(source) {
        this.props.dispatch(Navigation.showConfirmation({
            title: 'Delete source',
            text: 'Delete this source?',
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                this.props.dispatch({
                    type: 'DRAFTS_SOURCE_DELETE',
                    payload: {
                        type: source.MediaType,
                        source: source
                    }
                })    
            },
            functionCancel: () => { 
            }
        }));
    }
    
    sendCreateAssignment() {
        if (this.formState === 'ok' || 1) {
            this.setState({
                isOpen: false
            });

            axios.post(Config.API_PATH + 'Assignments/CreateFromJSON?output=json', {
                assignment: this.formValues,
                sources: this.state.sources
            }, {
                headers: {
                    'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
                }
            }).then((result) => {
                
                this.props.dispatch({
                    type: 'DRAFTS_SOURCE_CLEAR'
                });
                
                this.props.dispatch(Navigation.showConfirmation({
                    title: 'Assignment',
                    text: 'Assignment created',
                    titleOK: 'OK',
                    hideCancel: true
                }));
                
            });
            
        } else {
            //console.log('Form is not ok');
        }
    }
    
    selectAssignment(assignment) {
        this.props.dispatch(Navigation.showConfirmation({
            title: 'Add sources to assignment',
            text: 'Add sources to: ' + assignment.name,
            titleOK: 'OK',
            titleCancel: 'Cancel',
            functionOK: () => { 
                this.addToSelectedAssignment(assignment.id);
            },
            functionCancel: () => { 
            }
        }));
    }
    
    addToSelectedAssignment(assignment_id) {
        axios.post(Config.API_PATH + 'Assignments/AddSourcesFromJSON?output=json', {
            assignment_id: assignment_id,
            sources: this.state.sources
        }, {
            headers: {
                'X-API-KEY': localStorage.getItem('AGENZIA-API-KEY')
            }
        }).then((result) => {
            
            this.props.dispatch({
                type: 'DRAFTS_SOURCE_CLEAR'
            });
            
            this.props.dispatch(Navigation.showConfirmation({
                title: 'Assignment',
                text: 'Sources added to selected assignment',
                titleOK: 'OK',
                hideCancel: true,
                functionOK:() => {
                    
                }
            }));
            
        });
    }
    
    render() {
        const source_ids = Object.keys(this.state.sources);
        return (
            <>
                <CommandBarButton ref={this.state._button} onClick={this.onClick} text={'Drafts'} styles={
                    {
                        root: {
                            marginTop: 10, 
                            marginRight: 5,
                            borderBottom: '3px solid ' + ( source_ids.length > 0 ? 'var(--etleboro-orange)' : 'transparent' )
                        },
                        label: {
                            color: source_ids.length > 0 ? 'var(--etleboro-orange)' : 'white'
                        }
                    }
                }>
                    <span className={'fa-layers fa-fw fa-lg'}>
                        <i className={source_ids.length > 0 ? 'fad fa-inbox' : 'fas fa-inbox'}></i>
                        <span className={'fa-layers-counter'} style={{
                            background: source_ids.length > 0 ? 'Tomato' : 'transparent',
                            fontSize: '2.5rem',
                            marginTop: '-9px'
                        }}>{source_ids.length > 0 ? source_ids.length : ''}</span>
                    </span>
                </CommandBarButton>
                <Panel
                    isLightDismiss 
                    type={this.state.panelSize}
                    isOpen={this.state.isOpen}
                    onDismiss={this.hidePanel}
                    isFooterAtBottom={true}
                    onRenderNavigationContent={(props, defaultRender) => (
                        <>
                            <h2 style={{
                                display: 'flex',
                                flexGrow: 1,
                                margin: '.5rem'
                            }}>{'Drafts'}</h2>
                            {defaultRender(props)}
                        </>
                    )}
                    onRenderFooterContent={() => (
                        <div style={{
                            textAlign: 'right',
                            background: 'red',
                            display: 'flex',
                            flexGrow: 1
                        }}>
                            {(
                                this.state.panelSize === PanelType.large ? (
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        background: 'white'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            margin: 'auto'
                                        }}>
                                            <PrimaryButton onClick={this.sendCreateAssignment}>{this.submitButtonText}</PrimaryButton>
                                            &nbsp;
                                            <DefaultButton onClick={this.contractPanel}>Cancel</DefaultButton>
                                        </div>
                                    </div>
                                ) : null
                            )}
                            <div style={{
                                width: 644,
                                background: 'white'
                            }}>
                                {(
                                    this.state.panelSize !== PanelType.large ? (
                                        <>
                                            <PrimaryButton onClick={this.expandPanel}>New assignment</PrimaryButton>
                                            &nbsp;
                                            <DefaultButton onClick={this.expandPanelAdd}>Add to assignment</DefaultButton>
                                        </>
                                    ) : null
                                )}
                            </div>
                        </div>   
                    )}
                    onRenderBody={() => (
                        <div style={{
                            display: 'flex',
                            flexGrow: 1,
                            flexDirection: 'row',
                            height: '100%',
                            overflow: 'hidden'
                        }}>
                        
                            {(
                                this.state.panelSize === PanelType.large ? (
                                    <div style={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        flexDirection: 'column',
                                        boxSizing: 'border-box',
                                        paddingLeft: 20,
                                        paddingRight: 40,
                                        overflowY: 'auto'
                                    }}>
                                        { this.displayPanel === 'newAssignment' ? (
                                            <AssignmentForm updateForm={this.updateForm} />
                                        ) : (
                                            <AssignmentsSmallList onClick={this.selectAssignment} />
                                        )
                                        }
                                    </div>  
                                ) : null
                            )}
                        
                            <div style={{
                                width: 644,
                                boxSizing: 'border-box',
                                paddingLeft: 5,
                                paddingRight: 5,
                                paddingTop: 0,
                                height: '100%',
                                overflowY: 'auto'
                                
                            }}>
                                {source_ids.map((draft_id) => (<SourcesItem key={draft_id} draft={{ id: draft_id, ...this.state.sources[draft_id]}} removeSource={this.removeSource} />))}
                            </div>
                        
                        </div>
                        
                    )}
                />
            </>
        )
        
    }
    
}

export default connect(mapStateToProps)(DraftsMenuButton);