import React from 'react';
import Table from 'components/Table';
import { Text } from 'office-ui-fabric-react/lib/Text';
import CategorySingle from './CategorySingle';

import API from 'actions/api';
import { mapStateToProps, connect } from 'containers/FormContainer';

import Navigation from 'components/Navigation';

class CategoryList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            selectedItem: {
                id: null,
                title: null
            },
            confirmDialog: false,
            columns: [
                {
                    key: 'id',
                    name: 'Category',
                    onRender: (item) => {
                        return (
                            <div>
                                <Text variant={'xLarge'} nowrap block>{item.name}</Text>
                                <span className={'id'}>ID: {item.id}</span>
                            </div>
                        )                
                    }
                }
            ]
        }
        
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    
    onClick(item) {
        this.setState({
            editOpen: true,
            selectedItem: {
                id: item.id,
                title: item.name
            }
        });
    }
    
    onHide() {
        this.setState({
            editOpen: false,
            confirmDialog: false,
            selectedItem: {
                id: null,
                title: null
            }
        })
    }
    
    onFormSave(form) {
        this.onHide();
        this.props.dispatch(API.PostForm(`Category/edit/${this.state.selectedItem.id}`, form));
    }

    render() {
        return (
            <div>
                <Table.List
                    APIModule={'Category/index'}
                    infinite={false} 
                    columns={this.state.columns} 
                    onClick={this.onClick}
                />
                <Navigation.SidebarRight open={this.state.editOpen} onHide={this.onHide} onSave={this.onFormSave} initialData={this.state.selectedItem} >
                    <CategorySingle data={this.state.selectedItem} />
                </Navigation.SidebarRight>
            </div>
        )
    }
    
}

export default connect(mapStateToProps)(CategoryList);