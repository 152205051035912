import API from '../actions/api';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    let result = {}
    
    let User = {};
    if (typeof state.api.User != 'undefined') {
        User = state.api.User;
    }
    result.User = User;

    if (typeof state.api.result.permissions != 'undefined') {
        result.permissions = state.api.result.permissions;
    }
    
    if (typeof state.api.sendingRequest) result.loading = state.api.sendingRequest;
    
    return result;
};
    
const mapDispatchToProps = (dispatch) => {
    return {
        API: () => {
            dispatch(API.Get('Test'))
        },
        Login: () => {
            dispatch(API.Login())
        },
        Logout: () => {
            dispatch(API.Logout())
        }
    }
};

export { mapStateToProps, mapDispatchToProps, connect }