import React from 'react';
import { mapStateToProps, connect } from 'containers/CountryContainer';
import { Country } from 'helpers/';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react';

class CountryDropdown extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            countryList: [],
            loaded: false,
            selected: {},
            options: [],
            selectedItem: false
        }

        this.onChange = this.onChange.bind(this);

        Country.Load(this.props.dispatch);
        Country.Get(props.dispatch);
    }
    
    onChange(ev, item) {
        if (this.props.onChange) this.props.onChange(ev, item);
        this.setState({
            selectedItem: item.key
        });
    }
    
    static getDerivedStateFromProps = (props, state) => {
        if ( 
            props.countryList &&
            props.countryList.length &&
            state.countryList.length === 0
        ) {
            let countryList = props.countryList.map((c) => ({...c, display: true}));
            let options = props.countryList.map((c) => ({
                key: c.id,
                text: c.name.replace(/&#160;/g, ' ')
            }));
            return {
                countryList: props.countryList ? countryList : [],
                options: props.countryList ? options : [],
                loaded: true
            }
        } 

        if (props.selectedItem && state.selectedItem === false) {
            return {
                selectedItem: props.selectedItem
            }
        }

        return null;
    }
    
    render() {
        return <Dropdown
                    label={"Country"}
                    options={this.state.options}
                    ResponsiveMode={ResponsiveMode.Small}
                    name={this.props.name}
                    errorMessage={this.props.errorMessage}
                    onChange={this.onChange}
                    selectedKey={this.state.selectedItem ? this.state.selectedItem : undefined}
                    styles={{
                        root: {
                            flexGrow: 1
                        },
                        container: {
                            flexGrow: 1
                        },
                        dropdown: {
                            flexGrow: 1
                        }
                    }}
                />
    }
    
}

export default connect(mapStateToProps)(CountryDropdown);