const defaultState = {
    uploads: {},
    callout: false,
    totalProgress: 0,
    uploadFunction: false,
    existing: {},
    result: {}
}

function getTotalProgress(uploads) {
    let totalProgress = 0;
    Object.keys(uploads).forEach((key) => {
        totalProgress += uploads[key].progress
    });
    totalProgress = ( totalProgress / ( Object.keys(uploads).length * 100 ) ) * 100;
    return totalProgress;
}

function uploadReducer(state=defaultState, action) {
 
    let uploads = { ...state.uploads }
    state.uploadFunction = false;
    state.existing = {};
    state.result = {};
    
    switch (action.type) {
     
        case 'UPLOAD_ADD':
            if (action.payload.upload.replace_id) delete uploads[action.payload.upload.replace_id];
            uploads[action.payload.upload.id] = action.payload.upload;
            return {
                ...state,
                uploads: uploads,
                totalProgress: getTotalProgress(uploads)
            }
        
        case 'UPLOAD_DELETE':
            delete uploads[action.payload.id];
            return {
                ...state,
                uploads: uploads,
                totalProgress: getTotalProgress(uploads)
            }
            
        case 'UPLOAD_PROGRESS':
            if (uploads[action.payload.id]) uploads[action.payload.id].progress = action.payload.progress;
            
            return {
                ...state,
                uploads: uploads,
                totalProgress: getTotalProgress(uploads)
            }
            
        case 'UPLOAD_CALLOUT':
            return {
                ...state,
                callout: action.payload.show
            }
        
        case 'UPLOAD_DATA':
            uploads[action.payload.id] = {
                ...uploads[action.payload.id],
                ...action.payload.data
            }
            return {
                ...state,
                uploads: uploads,
                totalProgress: getTotalProgress(uploads)
            }
        
        case 'UPLOAD_PASTE_LINK':
            return {
                ...state,
                uploadFunction: {
                    f: 'onPasteLink',
                    data: action.payload.link
                }
            }
            
        case 'UPLOAD_PASTE_FILES':
            return {
                ...state,
                uploadFunction: {
                    f: 'onPasteFiles',
                    data: action.payload.files
                }
            }
            
        case 'UPLOAD_PASTE_EXTERNAL':
            return {
                ...state,
                uploadFunction: {
                    f: 'fetchExternal',
                    data: action.payload.link
                }
            }
            
        case 'UPLOAD_DISPLAY_EXISTING':
            return {
                ...state,
                existing: action.payload.data
            }
            
        case 'UPLOAD_EXTERNAL_NEW_ARTICLE':
            return {
                ...state,
                result: action.payload.data
            }
            
        case 'UPLOAD_ACTION_BUTTON':
            return {
                ...state,
                smallActionButton: action.payload
            }
            
        case 'UPLOAD_ACTION_BUTTON_HIDE':
            return {
                ...state,
                smallActionButton: null
            }
        
        case 'UPLOAD_ALL':
            return state;
        
        default:
            return state            

    }
    
}

export default uploadReducer;