import * as React from 'react';
import { mapStateToProps, connect } from 'containers/MenuContainer';

import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import API from 'actions/api';
import Utilities from 'actions/utilities';

class MenuBottom extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            logoutDialog: false,
            forceRender: 0
        }

    }

    render() {
        Utilities.Reflow();
        return this.props.User.loggedIn ?
            (
                <div>
                  <CommandBar farItems={this.getFarItems()} styles={{root:{marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0}}} />
                  <Dialog
                    hidden={!this.state.logoutDialog}
                    shiftOnReduce={true}
                    dialogContentProps={{
                      type: DialogType.normal,
                      title: 'Logout?',
                      subText: 'Logout from application?'
                    }}
                    modalProps={{
                      isBlocking: true,
                      isDarkOverlay: true,
                      styles: { main: { maxWidth: 450 } }
                    }}
                  >
                    <DialogFooter>
                      <PrimaryButton onClick={() => {this.setState({logoutDialog: false}); this.props.dispatch(API.Logout());}} text="Logout" />
                      <DefaultButton onClick={() => {this.setState({logoutDialog: false, lastRender: new Date().getTime()})}} text="Cancel" />
                    </DialogFooter>
                  </Dialog>
                </div>
            ) : '';
    }

    static getDerivedStateFromProps = (props, state) => {
        let newState = { ...state, mainMenu: [] }
        
        if (typeof(state.User)=='undefined' || props.User.loggedIn !== state.User.loggedIn) newState.User = props.User;
        
        return newState;

    }

    getFarItems = () => {
        return [{
            key: 'user',
            name: this.state.User.loggedIn ? 'Logout ' + this.state.User.userData.name : 'Do Login',
            iconProps: {
                iconName: 'StatusErrorFull'
            },
            onClick: () => {
                this.setState({
                    logoutDialog: true,
                    lastRender: new Date().getTime()
                });
            }
        }];
    };
}

export default connect(mapStateToProps)(MenuBottom);